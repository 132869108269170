var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", [
      _vm._v(_vm._s(_vm.$t("MemberProfile.form.labels.uploadPortrait")))
    ]),
    _c("div", { staticClass: "form-item-input" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.memberProfilePortrait || _vm.portraitChanged,
              expression: "memberProfilePortrait || portraitChanged"
            }
          ],
          staticClass: "portrait-container"
        },
        [
          _c("img", {
            attrs: {
              id: "portrait-thumbnail",
              src: _vm.memberProfilePortrait,
              alt: _vm.$t("Movie.form.edit.movieImages.alt")
            }
          }),
          _vm.imageUploaded
            ? _c(
                "button",
                {
                  staticClass: "button",
                  attrs: { type: "button" },
                  on: { click: _vm.removePortrait }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "Movie.form.edit.movieImages.buttons.removeImage"
                        )
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _c("input", {
        attrs: { type: "file", name: "uploadPortrait", accept: "image/*" },
        on: { change: _vm.submitPortrait }
      }),
      _c("p", { staticClass: "tip text-small" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("MemberProfile.form.helpTexts.portrait")) + " "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }