var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-item-input flex--row s-mrgt-03 align-center" },
    [
      _c("label", { attrs: { for: "showAddress" } }, [
        _vm._v(_vm._s(_vm.$t("MemberProfile.form.labels.showAddress")))
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.memberProfileShowAddress,
            expression: "memberProfileShowAddress"
          }
        ],
        attrs: { id: "showAddress", type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.memberProfileShowAddress)
            ? _vm._i(_vm.memberProfileShowAddress, null) > -1
            : _vm.memberProfileShowAddress
        },
        on: {
          change: function($event) {
            var $$a = _vm.memberProfileShowAddress,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.memberProfileShowAddress = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.memberProfileShowAddress = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.memberProfileShowAddress = $$c
            }
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }