import { axiosBaseService } from "./axiosBase.service";
import { Festival } from "../models/Festival.model";

const getFestivalList = async () => {
  return getFestivalListBySearch("");
};

const getFestivalListBySearch = async query => {
  try {
    const url = `/moviesapi/festivals/?search=${query}`;
    const response = await axiosBaseService.get(url);
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.results.map(festival => {
      return new Festival(festival);
    });
  } catch (error) {
    throw error;
  }
};

export { getFestivalList, getFestivalListBySearch };
