var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c("div", [
        _c("header", { staticClass: "header s-pdgv-05" }, [
          _c(
            "div",
            {
              staticClass:
                "container-fluid flex justify-between align-center w-100"
            },
            [
              _vm.mandate
                ? _c("a", { attrs: { href: _vm.mandate.homepage } }, [
                    _c("img", {
                      staticClass: "header__logo",
                      attrs: {
                        src: _vm.mandate.logo,
                        alt: "Logo",
                        title: "Home"
                      },
                      on: { error: _vm.usePlaceholder }
                    })
                  ])
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "header__menu-toggle",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.toggleMenu.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("svg-icon", {
                    staticClass: "icon icon--big",
                    attrs: { name: "bars" }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "header__menu",
                  class: { "is-open": _vm.isMenuOpen }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "menu__close align-center",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.toggleMenu.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "icon icon--big",
                        attrs: { name: "times" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "ul",
                    { staticClass: "list-style--none list" },
                    [
                      _vm._l(_vm.mandate.mandateLinks.slice(0, 5), function(
                        link,
                        index
                      ) {
                        return _c(
                          "li",
                          {
                            key: link.name,
                            class: [0, 3, 4].includes(index)
                              ? "header__separator--mb"
                              : "links-b"
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dp-block link s-pdgv-02",
                                attrs: { href: link.link }
                              },
                              [
                                _c("span", [
                                  _vm._v(" " + _vm._s(link.name) + " ")
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      _c(
                        "li",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.toggleMenu.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "dp-block link s-pdgv-02",
                              attrs: {
                                to: {
                                  name: "MoviesListView",
                                  params: {
                                    lang: _vm.locale
                                  }
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Movie.toMoviesOverview")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        { on: { click: _vm.toggleMenu } },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "dp-block link s-pdgv-02",
                              attrs: {
                                to: {
                                  name: "MemberProfileListView",
                                  params: {
                                    lang: _vm.locale
                                  }
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("NavBar.members")) + " ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        { on: { click: _vm.toggleMenu } },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dp-block link s-pdgv-03 header__separator--mb",
                              attrs: {
                                to: {
                                  name: "Companies",
                                  params: {
                                    lang: _vm.locale
                                  }
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("NavBar.companies")) + " ")]
                          )
                        ],
                        1
                      ),
                      _vm.isLoggedIn && _vm.hasMovieCreatePermission
                        ? _c(
                            "li",
                            { on: { click: _vm.toggleMenu } },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dp-block link s-pdgv-02",
                                  attrs: {
                                    to: {
                                      name: "MovieCreateView",
                                      params: {
                                        lang: _vm.locale
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Movie.submitMovie")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isLoggedIn && _vm.hasMovieCreatePermission
                        ? _c(
                            "li",
                            { on: { click: _vm.toggleMenu } },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dp-block link s-pdgv-02",
                                  attrs: {
                                    to: {
                                      name: "UserMovieListView",
                                      params: {
                                        lang: _vm.locale
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Movie.userMovieList")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isLoggedIn && _vm.authUser && _vm.authUser.isMember
                        ? _c(
                            "li",
                            { on: { click: _vm.toggleMenu } },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "dp-block link s-pdgv-02 header__separator--mb",
                                  attrs: { to: _vm.userProfileLink }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.userProfileTitle) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoggedIn
                        ? _c(
                            "li",
                            { on: { click: _vm.toggleMenu } },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dp-block link s-pdgv-02",
                                  attrs: {
                                    to: {
                                      name: "Login"
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("Login.login")))]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isLoggedIn
                        ? _c(
                            "li",
                            { on: { click: _vm.toggleMenu } },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dp-block link s-pdgv-02",
                                  attrs: {
                                    to: {
                                      name: "Logout",
                                      params: {
                                        lang: _vm.locale
                                      }
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("Login.logout")))]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.mandate.mandateLinks.slice(5, 7), function(
                        link
                      ) {
                        return _c(
                          "li",
                          {
                            key: link.name,
                            staticClass: "header__separator--mb"
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dp-block link s-pdgv-02",
                                attrs: { href: link.link }
                              },
                              [
                                _c("span", [
                                  _vm._v(" " + _vm._s(link.name) + " ")
                                ])
                              ]
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ]
              ),
              _c("div", { staticClass: "header__menu-overlay" })
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }