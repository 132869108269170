<template>
  <div class="form-item">
    <p class="s-mrgb-02">
      {{ $t("Movie.form.labels.worldSalesLink.worldSalesLink") }}:
    </p>

    <div class="form-item-input">
      <div class="s-mrgb-04">
        <label for="world-sales-title"
          >{{ $t("Movie.form.labels.worldSalesLink.title") }}:</label
        >
        <input
          type="text"
          v-model="movieWorldSalesLink.title"
          id="world-sales-title"
        />
      </div>

      <div>
        <label for="world-sales-url"
          >{{ $t("Movie.form.labels.worldSalesLink.url") }}:</label
        >
        <input
          type="url"
          v-model="movieWorldSalesLink.url"
          id="world-sales-url"
        />
        <p class="tip text-small">
          {{ $t("Movie.form.helpText.correctFormat") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovieWorldSalesLink",
  data() {
    return {
      defaultWorldSalesLink: {
        title: "",
        url: ""
      }
    };
  },
  props: {
    value: null
  },
  computed: {
    movieWorldSalesLink: {
      get() {
        if (this.value) {
          return this.value;
        }
        return this.defaultWorldSalesLink;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
