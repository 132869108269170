<template>
  <div class="form-item">
    <label>{{ $t("MemberProfile.form.labels.uploadPortrait") }}</label>
    <div class="form-item-input">
      <div
        v-show="memberProfilePortrait || portraitChanged"
        class="portrait-container"
      >
        <img
          id="portrait-thumbnail"
          :src="memberProfilePortrait"
          :alt="$t('Movie.form.edit.movieImages.alt')"
        />

        <button
          v-if="imageUploaded"
          type="button"
          class="button"
          @click="removePortrait"
        >
          {{ $t("Movie.form.edit.movieImages.buttons.removeImage") }}
        </button>
      </div>
      <input
        type="file"
        name="uploadPortrait"
        @change="submitPortrait"
        accept="image/*"
      />
      <p class="tip text-small">
        {{ $t("MemberProfile.form.helpTexts.portrait") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberProfileUploadPortrait",
  data() {
    return {
      image: undefined
    };
  },
  props: {
    value: null,
    portraitChanged: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    memberProfilePortrait() {
      return this.value;
    },
    imageUploaded() {
      return this.portraitChanged ? this.image : this.value;
    }
  },
  methods: {
    submitPortrait(event) {
      const fd = new FormData();
      fd.append("portrait", event.target.files[0]);
      this.image = fd;
      this.updatePortraitThumbnail(event.target.files[0]);
    },
    updatePortraitThumbnail(file) {
      const fileReader = new FileReader();
      const imageElement = document.getElementById("portrait-thumbnail");
      imageElement.title = file.name;
      fileReader.onload = function(event) {
        imageElement.src = event.target.result;
      };
      fileReader.readAsDataURL(file);
    },
    removePortrait() {
      this.image = null;
      document.getElementById("portrait-thumbnail").src = null;
    }
  },
  watch: {
    image: {
      handler(value) {
        this.$emit("setPortrait", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.portrait-container {
  max-width: 250px;

  .button {
    width: 100%;
    margin-bottom: 1rem;
  }
}
</style>
