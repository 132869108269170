class MemberCredit {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.title = payload.title || "";
    this.year = payload.year || "";
    this.direction = payload.direction || "";
    this.production = payload.production || "";
    this.memberFormat = payload.memberFormat || null;
    this.memberType = payload.memberType || null;
    this.memberStyles = payload.memberStyles || null;
    this.credit = payload.credits || null;
    this.member = payload.member || null;
    this.company = payload.company || null;
  }
}

export { MemberCredit };
