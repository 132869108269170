<template>
  <div class="s-mrgb-06">
    <h2 class="h1 s-mrgr-02">{{ $t("Movie.detail.credits.title") }}</h2>
    <div v-for="group in occupationGroups" :key="group.groupName">
      <div
        class="row s-mrgb-04"
        v-for="(credit, key) in group.credits"
        :key="key"
      >
        <div class="s-mrgr-02 col-xs-12 col-t-md-4">
          <p>{{ key }}</p>
        </div>
        <div class="s-mrgr-02 col-xs-12 col-t-md-6">
          <p v-for="occupation in credit" :key="occupation.name">
            <router-link v-if="occupation.to" :to="occupation.to">
              <svg-icon name="arrow-long-right" class="icon icon--small" />
              {{ occupation.name }}
            </router-link>
            <span v-else>{{ occupation.name }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

export default {
  name: "MovieCredits",
  props: {
    creditList: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      groupedCreditList: [],
      groupsList: []
    };
  },
  methods: {
    ...mapActions("OccupationStore", ["setGroupList"]),
    getFullName(user) {
      return `${user.firstName} ${user.lastName}`;
    },
    isCurrentMandate(credit) {
      const mandates = credit.user.mandates;
      let isCurrentMandate = false;
      mandates.forEach(mandate => {
        if (mandate.name === this.mandate.name) {
          isCurrentMandate = true;
        }
      });
      return isCurrentMandate;
    },
    isMemberCredit(credit) {
      if (credit.user.id) return true;
      else return false;
    }
  },
  computed: {
    ...mapState("OccupationStore", ["groupList"]),
    ...mapState("MandateStore", ["mandate"]),
    occupationGroups() {
      // Desired order of groups as unlocalized english names
      const groupOrder = [
        "direction-script",
        "casting",
        "camera",
        "art-department",
        "light-grip",
        "costume",
        "masks",
        "sound",
        "stunt",
        "animation",
        "image-postproduction",
        "sound-postproduction",
        "catering",
        "production",
        "cast"
      ];

      // Map of localized to english group names. Record<LocalizedName, UnlocalizedName>
      const groupNames = groupOrder.reduce((names, key) => {
        return {
          ...names,
          [this.$t(`Movie.detail.occupationGroups.${key}`)]: key
        };
      }, {});

      // Groups which shouldn't be shown
      const groupMask = ["production", "cast"];

      // Map of occupation group names to credits. <LocalizedName, Credit[]>
      const groupedCredits = groupBy(this.creditList, "occupation.group.name");
      // Entries. [LocalizedName, Credit[]]
      const occupationGroups = Object.entries(groupedCredits);

      // Entries, sorted according to groupOrder. [LocalizedName, Credit[]]
      const creditsByGroupOrder = sortBy(occupationGroups, [
        ([name]) => {
          const indexOfGroup = groupOrder.indexOf(groupNames[name]);
          return indexOfGroup >= 0 ? indexOfGroup : occupationGroups.length - 1;
        }
      ]);

      const creditsByOccupations = creditsByGroupOrder.map(
        ([_groupName, credits]) => {
          const users = credits.map(credit => {
            const isUser = credit.user.fullName;
            const user = isUser ? credit.user : credit.company;
            const toUser = user?.memberProfile
              ? {
                  name: "MemberProfileDetailView",
                  params: { MpId: user?.memberProfile }
                }
              : null;
            const toCompany = user?.isActive
              ? {
                  name: "Company",
                  params: { Cid: user.id }
                }
              : null;

            const name = isUser ? user.fullName : user.name;

            const occupationGroup = credit.occupation.description;
            return {
              occupationGroup,
              name,
              to: isUser ? toUser : toCompany
            };
          });

          return {
            credits: groupBy(users, "occupationGroup")
          };
        }
      );

      // Remove undesired groups
      const allowedGroups = creditsByOccupations.filter(
        group => !groupMask.includes(groupNames[group.groupName])
      );

      return allowedGroups;
    }
  }
};
</script>
