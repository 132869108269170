<template>
  <div class="movies__item s-mrgb-08">
    <div class="row">
      <div class="col-xs-12 col-t-md-4">
        <div class="movie__poster s-mrgb-04 t-md-s-mrgb-00">
          <a @click="goToMovieLink(movieListItem)">
            <img
              v-if="hasMoviePoster"
              :src="movieListItem.poster"
              :alt="`${movieListItem.title} Plakat`"
              @error="usePlaceholder"
            />
            <img
              v-else-if="hasMovieImages"
              :src="movieListItem.movieImages[0].image"
              :alt="`${movieListItem.title} Plakat`"
              @error="usePlaceholder"
            />
            <img v-else :src="placeholderPoster">
          </a>
        </div>
      </div>
      <div class="col-xs-12 col-t-md-8">
        <div class="movie__info">
          <h2 class="h1 s-mrgb-05">
            <a @click="goToMovieLink(movieListItem)">
              {{ translateOrFallback(movieListItem, "title") }}
            </a>
          </h2>
          <div class="info__details flex align-baseline s-mrgb-05">
            <svg-icon name="regie_path" class="icon icon--small" />

            <ul class="list-style--none s-mrgl-04">
              <span v-if="movieDirectorsString">
                {{ movieDirectorsString }}
              </span>

              <!-- categories-->
              <li class="details__category">
                <!-- Movie type -->
                <span
                  v-if="movieListItem.movieType"
                  class="list-item--separator"
                >
                  {{ translateOrFallback(movieListItem.movieType, "name") }}
                </span>
              </li>
            </ul>
          </div>

          <div class="info__format">
            <ul class="list-style--none">
              <!-- vod-->
              <li class="flex align-baseline" v-if="movieListItem.vodLink">
                <svg-icon
                  name="yes-symbol"
                  class="icon icon--small s-mrgr-04"
                />
                <a :href="`${movieListItem.vodLink}`" target="_blank">{{
                  $t("Movie.detail.links.VOD")
                }}</a>
              </li>

              <!-- dvd_bluray_link-->
              <li
                class="flex align-baseline"
                v-if="movieListItem.dvdBlurayLink"
              >
                <svg-icon
                  name="yes-symbol"
                  class="icon icon--small s-mrgr-04"
                />
                <a :href="`${movieListItem.dvdBlurayLink}`" target="_blank">
                  {{ $t("Movie.detail.links.bluray") }}</a
                >
              </li>
              <!-- trailer-->
              <li class="flex align-center" v-if="movieListItem.trailer">
                <svg-icon
                  name="arrow-long-right"
                  class="icon icon--small s-mrgr-04"
                />
                <a :href="`${movieListItem.trailer}`" target="_blank">
                  {{ $t("Movie.detail.trailer") }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoviesListItem",
  data() {
    return {
      placeholderPoster: "https://via.placeholder.com/200x300/808080/FFFFFF/?text=Movie+Poster+Placeholder"
    };
  },
  props: {
    movieListItem: {
      required: true,
      type: Object
    }
  },
  computed: {
    hasMoviePoster() {
      return !!this.movieListItem.poster;
    },
    hasMovieImages() {
      return this.movieListItem.movieImages.length > 0;
    },
    movieDirectorsString() {
      return this.movieListItem.movieCredits
        .filter(credit => credit.occupation.credit === "Regie")
        .map(director => director.user.fullName)
        .join(", ");
    }
  },
  methods: {
    /**
     * Returns the field with the current lang
     * Or falls back to the default langauge (DE)
     * example: movie[title_fr] || movie[title]
     */
    translateOrFallback: function(model, field) {
      return model[field + `_${this.$store.state.locale}`] || model[field];
    },
    usePlaceholder(e) {
      e.target.src = this.placeholderPoster;
    },
    goToMovieLink(movie) {
      if (movie.published || movie.draft) {
        this.$router.push({
          name: "MovieDetail",
          params: { Mid: movie.id }
        });
      } else {
        this.$toaster.warning(this.$i18n.t("Toaster.movie.linkWarning"));
      }
    }
  }
};
</script>
