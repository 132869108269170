var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "s-mrgb-10" },
    [
      _vm.movie.movieImages.length > 0
        ? _c("img", {
            staticClass: "s-mrgb-06",
            attrs: { src: _vm.movie.movieImages[0].image, alt: "Filmposter" },
            on: { error: _vm.usePlaceholder }
          })
        : _vm._e(),
      _c("h2", { staticClass: "h1" }, [_vm._v(_vm._s(_vm.movie.title))]),
      _c("div", [
        _vm.movieDirectorsString
          ? _c("span", [_vm._v(_vm._s(_vm.movieDirectorsString) + " – ")])
          : _vm._e(),
        _c("span", [
          _vm._v(
            _vm._s(
              [
                _vm.movie.publishedYear,
                _vm.movie.duration
                  ? `${_vm.movie.duration} ${_vm.$t(
                      "Movie.list.duration.minutes"
                    )}`
                  : null,
                _vm.movie.movieType.name,
                _vm.completionStatus
              ]
                .filter(t => !!t)
                .join(", ")
            ) + " "
          )
        ])
      ]),
      _vm.movie.trailer
        ? _c("div", { staticClass: "flex align-center s-mrgb-06" }, [
            _c(
              "a",
              { attrs: { href: "#trailer" } },
              [
                _c("svg-icon", {
                  staticClass: "icon icon--small",
                  attrs: { name: "long-arrow-down" }
                }),
                _vm._v(" " + _vm._s(_vm.$t("Movie.detail.trailer")) + " ")
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "text-large s-mrgb-06" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("Movie.form.labels.Synopsis")) + ":")]),
        _c("p", [_vm._v(_vm._s(_vm.translateObj(_vm.movie, "synopsis")))])
      ]),
      _c("video-embed", { attrs: { id: "trailer", src: _vm.movie.trailer } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }