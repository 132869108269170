var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "detail-layout" }, [
    !_vm.isLoading
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12 col-t-md-4" }, [
            _c("h1", { staticClass: "s-mrgb-06" }, [
              _vm._v(_vm._s(_vm.$t("MemberProfile.detail.title")))
            ])
          ]),
          _c(
            "div",
            { staticClass: "col-xs-12 col-t-md-8 flex t-md-justify-end" },
            [
              _c(
                "router-link",
                {
                  staticClass: "h1 s-mrgb-05",
                  attrs: {
                    to: {
                      name: "MemberProfileListView"
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Movie.detail.toOverview")) + " ")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "col-xs-12 col-t-md-8" }, [
            _c("h2", { staticClass: "h1" }, [
              _vm._v(_vm._s(_vm.memberProfile.fullname))
            ]),
            _c("div", { staticClass: "row col-xs-12" }, [
              _vm.memberProfile.memberOccupations.length
                ? _c("div", { staticClass: "s-mrgt-02" }, [
                    _c("p", { staticClass: "s-mrgr-02" }, [
                      _vm._v(" " + _vm._s(_vm.occupationNameList) + " ")
                    ])
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "text-large s-mrgt-04 s-mrgb-06" }, [
              _c("p", [_vm._v(_vm._s(_vm.memberProfile.text))])
            ]),
            _c("div", [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("MemberProfile.detail.contact")))
              ]),
              _vm.memberProfile.showAddress
                ? _c("p", [_vm._v(_vm._s(_vm.memberProfile.address))])
                : _vm._e(),
              _vm.memberProfile.website !== ""
                ? _c(
                    "div",
                    { staticClass: "flex align-center" },
                    [
                      _c("svg-icon", {
                        staticClass: "icon icon--small",
                        attrs: { name: "arrow-long-right" }
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "s-mrgl-03",
                          attrs: {
                            href: _vm.memberProfile.website,
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("MemberProfile.detail.website")))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex align-center" },
                [
                  _c("svg-icon", {
                    staticClass: "icon icon--small",
                    attrs: { name: "arrow-long-right" }
                  }),
                  _c(
                    "a",
                    {
                      staticClass: "s-mrgl-03",
                      attrs: { href: `mailto:${_vm.memberProfile.user.email}` }
                    },
                    [_vm._v(_vm._s(_vm.$t("MemberProfile.detail.email")))]
                  )
                ],
                1
              ),
              _vm.memberProfile.showPhone
                ? _c("p", { staticClass: "s-mrgb-04" }, [
                    _vm._v(" " + _vm._s(_vm.memberProfile.phone) + " ")
                  ])
                : _vm._e(),
              _vm.memberProfile.pdf
                ? _c(
                    "div",
                    { staticClass: "flex align-center s-mrgb-06" },
                    [
                      _c("svg-icon", {
                        staticClass: "icon icon--small",
                        attrs: { name: "long-arrow-down" }
                      }),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.memberProfile.pdf,
                            download: _vm.memberProfile.pdfFilename,
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(_vm.memberProfile.pdfFilename))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm.memberProfile.user.company
              ? _c("div", [
                  _c("h2", { staticClass: "s-mrgt-05" }, [
                    _vm._v(_vm._s(_vm.$t("Company.title")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex align-center" },
                    [
                      _c("svg-icon", {
                        staticClass: "icon icon--small",
                        attrs: { name: "arrow-long-right" }
                      }),
                      _c(
                        "router-link",
                        {
                          staticClass: "s-mrgl-03",
                          attrs: {
                            to: {
                              name: "Company",
                              params: { Cid: _vm.memberProfile.user.company.id }
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.memberProfile.user.company.name) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _vm.memberProfile.portrait
            ? _c("div", { staticClass: "col-xs-12 col-t-md-4" }, [
                _c("img", {
                  attrs: { src: _vm.memberProfile.portrait, alt: "Portrait" },
                  on: { error: _vm.usePlaceholder }
                })
              ])
            : _vm._e(),
          _vm.isOwnerOfProfile && _vm.isLoggedIn
            ? _c(
                "div",
                { staticClass: "col-xs-12" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "MemberProfileEditView",
                          params: { MpId: this.$route.params.MpId }
                        }
                      }
                    },
                    [
                      _c(
                        "button",
                        { staticClass: "button", attrs: { type: "button" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "MemberProfile.detail.buttons.editProfile"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "col-xs-12" },
            [
              _c(
                "div",
                { staticClass: "col-t-md-8" },
                [
                  _c("Projects", {
                    attrs: {
                      movieProjects: _vm.memberProfile.movies,
                      profile: _vm.memberProfile,
                      "profile-type": "member",
                      "member-credit-list": _vm.memberProfile.profileCredits
                    }
                  })
                ],
                1
              ),
              _c("ProductionCredits", {
                attrs: {
                  "production-credit-list": _vm.memberProfile.productionCredits
                }
              })
            ],
            1
          )
        ])
      : _c("div", [
          _vm._v(_vm._s(_vm.$t("MemberProfile.form.helpTexts.fetchingData")))
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }