var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", [
      _vm._v(_vm._s(_vm.$t("MemberProfile.form.labels.uploadPDF")))
    ]),
    _c("div", { staticClass: "form-item-input" }, [
      _vm.memberProfilePdf
        ? _c("div", { staticClass: "s-mrgb-03 s-mrgt-02" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("MemberProfile.form.labels.filename")) + ": "
              )
            ]),
            _c(
              "a",
              { attrs: { href: _vm.memberProfilePdf, target: "_blank" } },
              [_vm._v(_vm._s(_vm.filename))]
            )
          ])
        : _vm._e(),
      _c("input", {
        attrs: {
          type: "file",
          name: "uploadPortrait",
          accept: "application/pdf"
        },
        on: { change: _vm.submitPDF }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }