import { refreshToken } from "../../services/refreshToken.service";

export default {
  namespaced: true,
  state: {
    accessToken: sessionStorage.getItem("access_token") || null,
    refreshToken: sessionStorage.getItem("refresh_token") || null,
    loginError: false,
    loginCompleted: false,
    isLoggedIn: sessionStorage.getItem("isLoggedIn") || false
  },
  mutations: {
    loginSuccess(state) {
      state.loginCompleted = true;
      state.loginError = false;
    },
    loginFailure(state, data) {
      state.loginError = data;
    },
    updateSessionStorage(state, data) {
      sessionStorage.setItem("access_token", data.access);
      if (data.refresh) {
        sessionStorage.setItem("refresh_token", data.refresh);
      }
    },
    clearStorage() {
      sessionStorage.clear();
    },
    emptyTokenState(state) {
      state.accessToken = null;
      state.refreshToken = null;
    },
    updateTokenState(state, data) {
      state.accessToken = data.access;
      if (data.refresh) {
        state.refreshToken = data.refresh;
      }
    },
    updateRegisteredState(state) {
      state.registered = !state.registered;
    },
    clearLoginStatus(state) {
      state.loginCompleted = false;
      state.loginError = false;
    },
    updateIsLoggedIn(state, data) {
      sessionStorage.setItem("isLoggedIn", data);
      state.isLoggedIn = data;
    }
  },
  actions: {
    setSessionStorage({ commit }, data) {
      commit("updateSessionStorage", data);
    },
    setTokenState({ commit }, data) {
      commit("updateTokenState", data);
    },
    setLoginSuccess({ commit }) {
      commit("loginSuccess");
    },
    setLoginFailure({ commit }, data) {
      commit("loginFailure", data);
    },
    destroyTokenStateAndStorage({ commit }) {
      commit("emptyTokenState");
      commit("clearStorage");
      commit("updateIsLoggedIn", false);
    },
    clearLoginStatus({ commit }) {
      commit("clearLoginStatus");
    },
    async submitRefreshToken({ commit }) {
      await refreshToken().then(response => {
        commit("updateSessionStorage", response.data);
        commit("updateTokenState", response.data);
      });
    },
    setIsLoggedIn({ commit }, data) {
      commit("updateIsLoggedIn", data);
    }
  }
};
