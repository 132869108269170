var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", { staticClass: "s-mrgb-05" }, [
        _vm._v(_vm._s(_vm.$t("MemberProfile.list.title")))
      ]),
      _c("search-input", { on: { submitted: _vm.getInpit } }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-t-md-4" },
          [
            _c("Filters", {
              staticClass: "filter",
              attrs: { "group-occupation-list": _vm.groupOccupationList },
              on: {
                setSelectedFilters: _vm.setSelectedFilterList,
                resetSearch: _vm.resetSearch
              }
            }),
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "s-mrgt-05 col-t-md-9 s-pdgl-00 s-mrgb-05 text-small"
                },
                [
                  _c("i18n", {
                    attrs: { path: "MemberProfile.list.searchHelp.text" },
                    scopedSlots: _vm._u([
                      {
                        key: "mandate",
                        fn: function() {
                          return [
                            _c("span", [_vm._v(_vm._s(_vm.mandate.name))])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "registrationLink",
                        fn: function() {
                          return [
                            _c(
                              "a",
                              { attrs: { href: _vm.mandate.registrationLink } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "MemberProfile.list.searchHelp.linkText"
                                    )
                                  )
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ]),
            _c("Support")
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-t-md-8" },
          [
            !_vm.loading
              ? _c("MemberProfilesList", {
                  attrs: { "members-list": _vm.memberListForPage }
                })
              : _c("loader"),
            _vm.isMemberListPaged && _vm.isPagedListLength
              ? _c(
                  "div",
                  { staticClass: "col-xs-12" },
                  [
                    _c("Pagination", {
                      attrs: {
                        "current-page": _vm.page,
                        "page-count": _vm.pagedMemberList.length
                      },
                      on: { setCurrentPage: _vm.setCurrentPage }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }