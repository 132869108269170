var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("p", { staticClass: "s-mrgb-02" }, [
      _vm._v(
        " " + _vm._s(_vm.$t("Movie.form.labels.rentalLink.rentalLink")) + ": "
      )
    ]),
    _c("div", { staticClass: "form-item-input" }, [
      _c("div", { staticClass: "s-mrgb-04" }, [
        _c("label", { attrs: { for: "rental-title" } }, [
          _vm._v(_vm._s(_vm.$t("Movie.form.labels.rentalLink.title")) + ":")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.movieRentalLink.title,
              expression: "movieRentalLink.title"
            }
          ],
          attrs: { type: "text", id: "rental-title" },
          domProps: { value: _vm.movieRentalLink.title },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.movieRentalLink, "title", $event.target.value)
            }
          }
        })
      ]),
      _c("div", [
        _c("label", { attrs: { for: "rental-url" } }, [
          _vm._v(_vm._s(_vm.$t("Movie.form.labels.rentalLink.url")) + ":")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.movieRentalLink.url,
              expression: "movieRentalLink.url"
            }
          ],
          attrs: { type: "url", id: "rental-url" },
          domProps: { value: _vm.movieRentalLink.url },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.movieRentalLink, "url", $event.target.value)
            }
          }
        }),
        _c("p", { staticClass: "tip text-small" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("Movie.form.helpText.correctFormat")) + " "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }