var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loader_wrapper" }, [
    _c("div", { staticClass: "loader" }, [
      _c("div", { staticClass: "loader__filmstrip" }),
      _c("div", [
        _c("p", { staticClass: "loader__text" }, [
          _vm._v(" " + _vm._s(_vm.$t("Loader.loading")) + " ")
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }