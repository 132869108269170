var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "s-mrgb-05 s-mrgt-08" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("MemberProfile.form.create.title")))])
      ]),
      _c("MemberProfileEditForm", {
        on: { postMemberProfile: _vm.postMemberProfile, cancel: _vm.cancel }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }