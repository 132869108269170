import { axiosBaseService } from "./axiosBase.service";
import { Language } from "../models/Language.model";

const getLanguageList = async () => {
  return getLanguageListBySearch("");
};

const getLanguageListBySearch = async (query = "") => {
  try {
    const url = `/moviesapi/languages/?search=${query}`;
    const response = await axiosBaseService.get(url);
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.map(language => {
      return new Language(language);
    });
  } catch (error) {
    throw error;
  }
};

export { getLanguageList, getLanguageListBySearch };
