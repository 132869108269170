<template>
  <div class="form-item">
    <label>{{ $t("Movie.form.labels.imageUpload.poster") }}</label>
    <div class="form-item-input">
      <input
        type="file"
        name="imageUpload"
        @change="submitImage"
        accept="image/*"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateMoviePoster",
  data() {
    return {
      image: null
    };
  },
  methods: {
    submitImage(event) {
      this.image = event.target.files[0];
    }
  },
  watch: {
    image: {
      handler(value) {
        const fd = new FormData();
        fd.append("poster", value);
        this.$emit("setPoster", fd);
      }
    }
  }
};
</script>
