<template>
  <div class="form-item">
    <label for="vod-link">{{ $t("Movie.form.labels.vodLink") }}:</label>
    <div class="form-item-input flex--col">
      <input type="url" v-model="movieVODLink" id="vod-link" />
      <p class="tip text-small">
        {{ $t("Movie.form.helpText.correctFormat") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovieVODLink",
  props: {
    value: {
      type: String,
      default: "",
      required: true
    }
  },
  computed: {
    movieVODLink: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
