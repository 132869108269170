<template>
  <div class="s-mrgb-06">
    <h2 class="h2">{{ $t("Movie.detail.credits.title") }}</h2>
    <div v-for="{ label, values } in creditEntries" :key="label">
      {{ label }}: <CreditEntry :label="label" :values="values" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CreditEntry from "./CreditEntry.vue";

export default Vue.extend({
  name: "SidebarCredits",
  props: {
    credits: {
      type: Array,
      required: true
    },
    productionLinks: {
      type: Array,
      required: true
    }
  },
  methods: {
    getCastMemberByKey(key) {
      return this.credits.filter(credit => credit.occupation.credit == key);
    }
  },
  computed: {
    creditEntries() {
      return [
        { values: this.direction, label: "Regie" },
        { values: this.script, label: "Drehbuch" },
        { values: this.production, label: "Produktion" },
        { values: this.producers, label: "ProduzentInnen" },
        { values: this.camera, label: "Kamera" },
        { values: this.editor, label: "Schnitt" },
        { values: this.sound, label: "Ton" },
        { values: this.soundDesign, label: "Sound" },
        { values: this.music, label: "Musik" },
        { values: this.scenery, label: "Ausstattung" },
        { values: this.lights, label: "Licht" },
        { values: this.costume, label: "Kostüm" },
        { values: this.masks, label: "Maske" },
        { values: this.cast, label: "Cast" }
      ]
        .filter(entry => entry.values.length)
        .map(({ values, label }) => {
          return {
            label,
            values: values.map(value => {
              const isCompany = !!value.name;
              const name = isCompany ? value.name : value.user.fullName;

              const toCompany = value.isActive
                ? {
                    name: "Company",
                    params: { Cid: value.id }
                  }
                : null;

              const toUser = value.user?.memberProfile
                ? {
                    name: "MemberProfileDetailView",
                    params: { MpId: value.user?.memberProfile }
                  }
                : null;

              return { name, to: isCompany ? toCompany : toUser };
            })
          };
        });
    },
    direction() {
      return this.getCastMemberByKey(
        this.$t("Movie.detail.occupations.direction")
      );
    },
    script() {
      return this.getCastMemberByKey(
        this.$t("Movie.detail.occupations.script")
      );
    },
    production() {
      return this.productionLinks;
    },
    producers() {
      const producers = this.getCastMemberByKey(
        this.$t("Movie.detail.occupations.producers")
      );
      const coProducers = this.getCastMemberByKey(
        this.$t("Movie.detail.occupations.coProducers")
      );
      return [...producers, ...coProducers];
    },
    camera() {
      return this.getCastMemberByKey(
        this.$t("Movie.detail.occupations.camera")
      );
    },
    editor() {
      return this.getCastMemberByKey(
        this.$t("Movie.detail.occupations.editor")
      );
    },
    sound() {
      return this.getCastMemberByKey(this.$t("Movie.detail.occupations.sound"));
    },
    soundDesign() {
      return this.getCastMemberByKey(
        this.$t("Movie.detail.occupations.soundDesign")
      );
    },
    music() {
      return this.getCastMemberByKey(this.$t("Movie.detail.occupations.music"));
    },
    scenery() {
      return this.getCastMemberByKey(
        this.$t("Movie.detail.occupations.scenery")
      );
    },
    lights() {
      return this.getCastMemberByKey(
        this.$t("Movie.detail.occupations.lights")
      );
    },
    costume() {
      return this.getCastMemberByKey(
        this.$t("Movie.detail.occupations.costume")
      );
    },
    masks() {
      return this.getCastMemberByKey(this.$t("Movie.detail.occupations.masks"));
    },
    cast() {
      return [
        ...this.getCastMemberByKey(
          this.$t("Movie.detail.occupations.femaleCast")
        ),
        ...this.getCastMemberByKey(this.$t("Movie.detail.occupations.maleCast"))
      ];
    }
  },
  components: { CreditEntry }
});
</script>

<style lang="scss" scoped></style>
