var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex--column align-center" }, [
    _c("div", { staticClass: "forgot-password-form" }, [
      _c("h2", { staticClass: "h2" }, [
        _vm._v(_vm._s(_vm.$t("ResetPassword.forgotPassword")))
      ]),
      _c("p", { staticClass: "s-mrgt-05" }, [
        _vm._v(_vm._s(_vm.$t("ResetPassword.helpText")))
      ]),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitResetEmail.apply(null, arguments)
            }
          }
        },
        [
          _c("div", { staticClass: "s-mrgt-08 form-item" }, [
            _c("label", { attrs: { for: "email" } }, [
              _vm._v(_vm._s(_vm.$t("ResetPassword.email")) + " *")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }
              ],
              staticClass: "s-mrgt-05",
              attrs: {
                id: "email",
                type: "email",
                name: "email",
                required: ""
              },
              domProps: { value: _vm.email },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                  _vm.validate
                ]
              }
            })
          ])
        ]
      ),
      _vm.emailError
        ? _c("p", {}, [_vm._v(_vm._s(_vm.$t("ResetPassword.emailError")))])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex--row between-xs s-mrgt-05" },
        [
          _c(
            "button",
            {
              staticClass: "button",
              attrs: { disabled: _vm.isDisabled },
              on: { click: _vm.submitResetEmail }
            },
            [_vm._v(" " + _vm._s(_vm.$t("ResetPassword.sendButton")) + " ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "dp-block",
              attrs: {
                to: {
                  name: "Login"
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("ResetPassword.backToLogin")))]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }