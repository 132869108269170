var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ul", { staticClass: "links s-mrgb-06" }, [
    _vm.hasCredits
      ? _c("li", [
          _c(
            "a",
            { attrs: { href: "#allCredits" } },
            [
              _c("svg-icon", {
                staticClass: "icon icon--small",
                attrs: { name: "long-arrow-down" }
              }),
              _vm._v(_vm._s(_vm.$t("Movie.detail.credits.allCredits")))
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.hasFestivals
      ? _c("li", [
          _c(
            "a",
            { attrs: { href: "#festivals" } },
            [
              _c("svg-icon", {
                staticClass: "icon icon--small",
                attrs: { name: "long-arrow-down" }
              }),
              _vm._v(_vm._s(_vm.$t("Movie.detail.festivals")))
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.hasAwards
      ? _c("li", [
          _c(
            "a",
            { attrs: { href: "#awards" } },
            [
              _c("svg-icon", {
                staticClass: "icon icon--small",
                attrs: { name: "long-arrow-down" }
              }),
              _vm._v(_vm._s(_vm.$t("Movie.detail.awards")))
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.hasInfo
      ? _c("li", [
          _c(
            "a",
            { attrs: { href: "#furtherInfo" } },
            [
              _c("svg-icon", {
                staticClass: "icon icon--small",
                attrs: { name: "long-arrow-down" }
              }),
              _vm._v(_vm._s(_vm.$t("Movie.detail.credits.furtherInfo")))
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }