<template>
  <div class="t-md-flex between-t-md">
    <h1 class="movie-detail__title">{{ $t("Movie.detail.title") }}</h1>
    <router-link
      class="h1"
      :to="{
        name: 'MoviesListView'
      }"
      >{{ $t("Movie.detail.toOverview") }}
    </router-link>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "MovieDetailHeader"
});
</script>

<style lang="scss" scoped>
.movie-detail__title {
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    margin-bottom: 0rem;
  }
}
</style>
