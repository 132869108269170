<template>
  <div class="detail-layout">
    <div class="row" v-if="!isLoading">
      <div class="col-xs-12 col-t-md-4">
        <h1 class="s-mrgb-06">{{ $t("MemberProfile.detail.title") }}</h1>
      </div>
      <div class="col-xs-12 col-t-md-8 flex t-md-justify-end">
        <router-link
          class="h1 s-mrgb-05"
          :to="{
            name: 'MemberProfileListView'
          }"
          >{{ $t("Movie.detail.toOverview") }}
        </router-link>
      </div>
      <div class="col-xs-12 col-t-md-8">
        <h2 class="h1">{{ memberProfile.fullname }}</h2>
        <div class="row col-xs-12">
          <!-- Services -->
          <div v-if="memberProfile.memberOccupations.length" class="s-mrgt-02">
            <p class="s-mrgr-02">
              {{ occupationNameList }}
            </p>
          </div>
        </div>
        <div class="text-large s-mrgt-04 s-mrgb-06">
          <p>{{ memberProfile.text }}</p>
        </div>
        <div>
          <h2>{{ $t("MemberProfile.detail.contact") }}</h2>
          <p v-if="memberProfile.showAddress">{{ memberProfile.address }}</p>
          <div v-if="memberProfile.website !== ''" class="flex align-center">
            <svg-icon name="arrow-long-right" class="icon icon--small" />
            <a
              class="s-mrgl-03"
              :href="memberProfile.website"
              target="_blank"
              >{{ $t("MemberProfile.detail.website") }}</a
            >
          </div>
          <div class="flex align-center">
            <svg-icon name="arrow-long-right" class="icon icon--small" />
            <a class="s-mrgl-03" :href="`mailto:${memberProfile.user.email}`">{{
              $t("MemberProfile.detail.email")
            }}</a>
          </div>
          <p class="s-mrgb-04" v-if="memberProfile.showPhone">
            {{ memberProfile.phone }}
          </p>
          <div v-if="memberProfile.pdf" class="flex align-center s-mrgb-06">
            <svg-icon name="long-arrow-down" class="icon icon--small" />
            <a
              :href="memberProfile.pdf"
              :download="memberProfile.pdfFilename"
              target="_blank"
              >{{ memberProfile.pdfFilename }}</a
            >
          </div>
        </div>
        <div v-if="memberProfile.user.company">
          <h2 class="s-mrgt-05">{{ $t("Company.title") }}</h2>
          <div class="flex align-center">
            <svg-icon name="arrow-long-right" class="icon icon--small" />
            <router-link
              class="s-mrgl-03"
              :to="{
                name: 'Company',
                params: { Cid: memberProfile.user.company.id }
              }"
            >
              {{ memberProfile.user.company.name }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-t-md-4" v-if="memberProfile.portrait">
        <img
          @error="usePlaceholder"
          :src="memberProfile.portrait"
          alt="Portrait"
        />
      </div>
      <div v-if="isOwnerOfProfile && isLoggedIn" class="col-xs-12">
        <router-link
          :to="{
            name: 'MemberProfileEditView',
            params: { MpId: this.$route.params.MpId }
          }"
        >
          <button type="button" class="button">
            {{ $t("MemberProfile.detail.buttons.editProfile") }}
          </button>
        </router-link>
      </div>

      <!-- Projects -->
      <div class="col-xs-12">
        <div class="col-t-md-8">
          <Projects
            :movieProjects="memberProfile.movies"
            :profile="memberProfile"
            :profile-type="'member'"
            :member-credit-list="memberProfile.profileCredits"
          />
        </div>
        <ProductionCredits
          :production-credit-list="memberProfile.productionCredits"
        />
      </div>
    </div>
    <div v-else>{{ $t("MemberProfile.form.helpTexts.fetchingData") }}</div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Projects from "@/components/MemberProfiles/Projects";
import ProductionCredits from "@/components/MemberProfiles/ProductionCredits";

export default {
  name: "MemberProfileDetailView",
  components: {
    Projects,
    ProductionCredits
  },
  data() {
    return {
      isLoading: true,
      placeholderProfile:
        "https://via.placeholder.com/200x300/808080/FFFFFF/?text=User+Profile+Placeholder"
    };
  },
  computed: {
    ...mapState("UserStore", ["authUser"]),
    ...mapState("MemberProfileStore", ["memberProfile"]),
    ...mapState("AuthStore", ["isLoggedIn"]),
    isOwnerOfProfile() {
      if (this.authUser) {
        return this.memberProfile.user.id === this.authUser.id;
      }
      return false;
    },
    occupationNameList() {
      return this.memberProfile.memberOccupations
        .filter(occupation => occupation.isUnlocked)
        .map(occupation => occupation.occupation.description)
        .join(", ");
    }
  },
  methods: {
    ...mapActions("MemberProfileStore", ["setMemberProfileDetail"]),
    usePlaceholder(e) {
      e.target.src = this.placeholderProfile;
    }
  },
  watch: {
    "$route.params": {
      handler() {
        this.setMemberProfileDetail(this.$route.params.MpId);
      },
      immediate: true
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.setMemberProfileDetail(this.$route.params.MpId);
    this.isLoading = false;
  }
};
</script>
<style lang="scss">
.occupations:not(:last-of-type) {
  .credit:after {
    content: ",";
  }
}
</style>
