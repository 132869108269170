<template>
  <div>
    <div v-if="!isLoading">
      <form>
        <div v-if="!isUpdate">
          <div class="s-mrgb-05">
            <i18n
              class="tip"
              path="Movie.form.create.agb.text"
              tag="p"
              for="link"
            >
              <a
                ><p class="tip text-small">
                  {{ $t("Movie.form.create.agb.link") }}
                </p></a
              >
            </i18n>
          </div>

          <!--      notification-->
          <p v-if="!isMovieFormValid" class="text-bold s-mrgb-05">
            {{ $t("Movie.form.helpText.pleaseFill") }}
          </p>
        </div>
        <MovieTitle v-model="form.title" />
        <MovieAlternativeTitle v-model="form.alternativeTitles" />
        <MovieSynopsisDE v-model="form.synopsis" />
        <MovieSynopsisFR v-model="form.synopsisFr" />
        <MovieCompletionStatus v-model="form.completionStatus" />
        <MoviePoster
          v-if="isUpdate"
          v-model="form.poster"
          @setPoster="setPoster"
        />
        <CreateMoviePoster v-else @setPoster="setPoster" />
        <MovieImages
          v-model="form.movieImages"
          @setMovieImages="setMovieImages"
        />
        <MovieType v-model="form.movieType" @setMovieType="setMovieType" />
        <MovieFormat
          v-if="form.movieType.name !== 'Medienkunst'"
          v-model="form.movieFormat"
          :form-object="this.form"
        />
        <MovieStyles
          v-model="form.styles"
          v-if="form.movieType.name !== 'Medienkunst'"
          :movie-style-list="movieStyleList"
          :form-object="this.form"
        />
        <MovieDuration v-model="form.duration" />
        <MoviePublishedYear v-model="form.publishedYear" />
        <MovieMediaArtProjects v-model="form.mediaArtFestivals" />
        <MovieAdditionalFestivals v-model="form.additionalFestivals" />
        <MovieAwards v-model="form.awards" />
        <div class="form-item">
          <div class="form-item-input">
            <div class="s-mrgb-04">
              <label for="isan">ISAN:</label>
              <input type="text" v-model="form.isan" id="isan" />
            </div>
          </div>
        </div>
        <MovieRecordingFormat v-model="form.recordingFormat" />
        <MovieScreeningFormat v-model="form.screeningFormat" />
        <MovieImageFormat v-model="form.imageFormat" />
        <MovieAudioFormat v-model="form.audioFormat" />
        <MovieOriginalLanguage
          v-model="form.originalLang"
          :language-list="languageList"
        />
        <MovieSubtitleLanguage
          v-model="form.subtitle"
          :language-list="languageList"
        />
        <MovieDubbedLanguage
          v-model="form.dubbedLang"
          :language-list="languageList"
        />
        <MovieWebsite v-model="form.website" />
        <MovieProductionLinks
          v-model="form.productionLinks"
          @setProductionLinks="setProductionLinks"
        />
        <MovieCoProductionLinks
          v-model="form.coProductionLinks"
          @setCoProductionLinks="setCoProductionLinks"
        />
        <MovieRentalLink v-model="form.rentalLink" />
        <MovieWorldSalesLink v-model="form.worldSalesLink" />
        <MovieVODLink v-model="form.vodLink" />
        <MovieDVDLink v-model="form.dvdBlurayLink" />
        <MovieInCinema v-model="form.inCinema" />
        <MovieSupporters
          v-model="form.supporters"
          :supporter-list="supporterList"
        />
        <MovieTrailer v-model="form.trailer" />
        <div class="form-item">
          <label>
            <span class="text-bold">
              {{ $t("Movie.form.labels.credits") }}:
            </span>
          </label>
        </div>

        <MovieCredits
          v-model="form.movieCredits"
          :movie-type="form.movieType"
          :companies="companies"
          :form="form"
        />
        <div class="flex justify-between s-mrgt-09">
          <button
            type="button"
            class="button s-mrgr-05"
            @click.prevent="cancel"
          >
            {{ $t("Movie.form.buttons.cancelMovie") }}
          </button>
          <div>
            <button
              class="button s-mrgr-10"
              type="button"
              :title="$t('Movie.form.buttons.saveMovieTooltip')"
              @click="openModal()"
              :disabled="
                !isMovieDraftFormValid ||
                  !isCreditFormValid ||
                  errorSynopsisToLong
              "
            >
              {{ $t("Movie.form.buttons.saveMovie") }}
            </button>
            <button
              class="button"
              type="button"
              @click.prevent="updateOrCreateMovie(false)"
              :title="$t('Movie.form.buttons.addMovieTooltip')"
              :disabled="
                !isMovieFormValid || !isCreditFormValid || errorSynopsisToLong
              "
            >
              {{ $t("Movie.form.buttons.addMovie") }}
            </button>
          </div>
        </div>
        <Modal :is-open="isModalOpen" @closeModal="closeModal()">
          <h1 slot="header">{{ $t("Modal.movie.header") }}</h1>
          <div>
            <p>{{ $t("Modal.movie.content") }}</p>
          </div>
          <div slot="footer" class="flex justify-between">
            <button class="button" type="button" @click.prevent="closeModal()">
              {{ $t("Modal.movie.footer.cancel") }}
            </button>

            <button class="button" type="button" @click.prevent="cacheMovie()">
              {{ $t("Modal.movie.footer.save") }}
            </button>
          </div>
        </Modal>
      </form>
      <AddingSpinner v-if="adding" />
    </div>
    <loader v-else></loader>
  </div>
</template>

<script>
import MovieTitle from "../MovieEditComponents/Titles/MovieTitle";
import MovieAlternativeTitle from "../MovieEditComponents/Titles/MovieAlternativeTitle";
import MovieSynopsisDE from "../MovieEditComponents/Synopsis/MovieSynopsisDE";
import MovieSynopsisFR from "../MovieEditComponents/Synopsis/MovieSynopsisFR";
import MovieCompletionStatus from "../MovieEditComponents/CompletionStatus/MovieCompletionStatus";
import MoviePoster from "../MovieEditComponents/Images/MoviePoster";
import MovieImages from "../MovieEditComponents/Images/MovieImages";
import MovieType from "../MovieEditComponents/Categories/MovieType";
import MovieFormat from "../MovieEditComponents/Categories/MovieFormat";
import MovieStyles from "../MovieEditComponents/Categories/MovieStyles";
import MovieDuration from "../MovieEditComponents/MovieDuration";
import MoviePublishedYear from "../MovieEditComponents/MoviePublishedYear";
import MovieMediaArtProjects from "../MovieEditComponents/Festivals/MovieMediaArtProjects";
import MovieAwards from "../MovieEditComponents/MovieAwards";
import MovieAdditionalFestivals from "../MovieEditComponents/Festivals/MovieAdditionalFestivals";
import MovieRecordingFormat from "../MovieEditComponents/Formats/MovieRecordingFormat";
import MovieScreeningFormat from "../MovieEditComponents/Formats/MovieScreeningFormat";
import MovieImageFormat from "../MovieEditComponents/Formats/MovieImageFormat";
import MovieAudioFormat from "../MovieEditComponents/Formats/MovieAudioFormat";
import MovieOriginalLanguage from "../MovieEditComponents/Languages/MovieOriginalLanguage";
import MovieSubtitleLanguage from "../MovieEditComponents/Languages/MovieSubtitleLanguage";
import MovieDubbedLanguage from "../MovieEditComponents/Languages/MovieDubbedLanguage";
import MovieWebsite from "../MovieEditComponents/Links/MovieWebsite";
import MovieProductionLinks from "../MovieEditComponents/Links/MovieProductionLinks";
import MovieCoProductionLinks from "../MovieEditComponents/Links/MovieCoProductionLinks";
import MovieRentalLink from "../MovieEditComponents/Links/MovieRentalLink";
import MovieWorldSalesLink from "../MovieEditComponents/Links/MovieWorldSalesLink";
import MovieVODLink from "../MovieEditComponents/Links/MovieVODLink";
import MovieDVDLink from "../MovieEditComponents/Links/MovieDVDLink";
import MovieInCinema from "../MovieEditComponents/Links/MovieInCinema";
import MovieSupporters from "../MovieEditComponents/MovieSupporters";
import MovieTrailer from "../MovieEditComponents/Links/MovieTrailer";
import MovieCredits from "../MovieEditComponents/MovieCredits";
import CreateMoviePoster from "../MovieEditComponents/Images/CreateMoviePoster";
import loader from "../../../components/loader";
import Modal from "../../Modal";
import { mapGetters, mapState, mapActions } from "vuex";
import { Movie } from "@/models/Movie.model";
import { getCompanies } from "@/services/Company.service";
import AddingSpinner from "../../AddingSpinner";

export default {
  name: "MovieEditForm",
  components: {
    AddingSpinner,
    MovieMediaArtProjects,
    MovieDuration,
    MovieSynopsisDE,
    MovieTitle,
    MovieAlternativeTitle,
    MovieSynopsisFR,
    MovieCompletionStatus,
    MoviePoster,
    MovieImages,
    MovieType,
    MovieFormat,
    MovieStyles,
    MoviePublishedYear,
    MovieAdditionalFestivals,
    MovieAwards,
    MovieRecordingFormat,
    MovieScreeningFormat,
    MovieImageFormat,
    MovieAudioFormat,
    MovieOriginalLanguage,
    MovieSubtitleLanguage,
    MovieDubbedLanguage,
    MovieWebsite,
    MovieProductionLinks,
    MovieCoProductionLinks,
    MovieRentalLink,
    MovieWorldSalesLink,
    MovieVODLink,
    MovieDVDLink,
    MovieInCinema,
    MovieSupporters,
    MovieTrailer,
    MovieCredits,
    CreateMoviePoster,
    Modal,
    loader
  },
  data() {
    return {
      form: {},
      poster: null,
      isLoading: true,
      movieImages: new FormData(),
      isMovieImageSelected: false,
      isModalOpen: false,
      companies: []
    };
  },
  props: {
    movieId: {
      default: null,
      required: false
    }
  },
  computed: {
    ...mapState("MoviesStore", ["selectedMovie"]),
    ...mapState("FestivalStore", ["festivalList", "mediaArtFestivalList"]),
    ...mapState("LanguageStore", ["languageList"]),
    ...mapState("CategoryStore", ["movieStyleList"]),
    ...mapState("SupporterStore", ["supporterList"]),
    ...mapGetters("Adding", ["adding"]),
    isUpdate() {
      return this.movieId;
    },
    isMovieFormValid() {
      return !(
        !this.form.title ||
        !this.form.synopsis ||
        !this.form.originalLang.length ||
        !this.form.completionStatus ||
        !this.form.movieType ||
        !this.isMovieImageValid()
      );
    },
    isMovieDraftFormValid() {
      return !!this.form.title;
    },
    isCreditFormValid() {
      for (let i = 0; i < this.form.movieCredits.length; i++) {
        let credit = this.form.movieCredits[i];
        if (
          !credit.occupation.credit ||
          (credit.user.id === undefined && credit.company.id === undefined)
        ) {
          return false;
        }
      }
      return true;
    },
    errorSynopsisToLong() {
      return this.form.synopsis.length > 2000;
    }
  },
  methods: {
    ...mapActions("MoviesStore", ["setSelectedMovie"]),
    ...mapActions("CategoryStore", ["setCategories"]),
    ...mapActions("SupporterStore", ["setSupporterList"]),
    ...mapActions("UserStore", ["setUserList"]),
    ...mapActions("OccupationStore", [
      "setOccupationList",
      "setGroupList",
      "setMediaArtGroup"
    ]),
    ...mapActions("FestivalStore", ["setFestivalList"]),
    ...mapActions("LanguageStore", ["setLanguageList"]),
    updateOrCreateMovie(draft) {
      if (this.form.movieType.name === "Medienkunst") {
        this.form.movieCredits = this.form.movieCredits.concat(
          this.cleanMediaArtCredits()
        );
        // No media art movie has a format
        this.form.movieFormat = null;
      }
      this.form.draft = draft;
      if (this.isUpdate) {
        this.$emit("patchMovie", this.form, this.poster, this.movieImages);
      } else {
        this.$emit("postMovie", this.form, this.poster, this.movieImages);
      }
      // clear form data for images after upload
      this.movieImages = new FormData();
    },
    openModal() {
      this.isModalOpen = true;
    },
    cancel() {
      this.$emit("cancel", this.form);
    },
    setPoster(poster) {
      this.poster = poster;
      this.form.poster = poster;
    },
    async setMovieImages(file) {
      this.movieImages.append("screenshots", file);
    },
    isMovieImageValid() {
      return !!this.form.movieImages.find(movieImage => movieImage.image);
    },
    setProductionLinks(productionLinks) {
      this.form.productionLinks = productionLinks;
    },
    setCoProductionLinks(coProductionLinks) {
      this.form.coProductionLinks = coProductionLinks;
    },
    async setMovieType(type) {
      this.form.movieType = type;
      if (type.name === "Medienkunst") {
        this.form.movieCredits = [];
        this.setMediaArtGroup();
      } else {
        this.form.movieCredits = [];
        await this.setGroupList();
      }
    },
    cleanMediaArtCredits() {
      let cleanedCredits = [];

      let credits = this.form.movieCredits.filter(
        credit => credit.user.length > 1
      );

      credits.forEach(credit => {
        credit.user.forEach(user => {
          credit = {
            id: null,
            occupation: credit.occupation,
            user: user
          };
          cleanedCredits.push(credit);
        });
      });

      credits.forEach(credit => {
        if (this.form.movieCredits.includes(credit)) {
          let creditIndex = this.form.movieCredits.indexOf(credit);
          this.form.movieCredits.splice(creditIndex, 1);
        }
      });

      return cleanedCredits;
    },
    closeModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    cacheMovie() {
      this.updateOrCreateMovie(true);
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.setCategories(true);
    await this.setSupporterList();
    await this.setUserList();
    await this.setOccupationList();
    await this.setGroupList();
    await this.setFestivalList();
    await this.setLanguageList();
    this.companies = await getCompanies();
    if (this.isUpdate) {
      await this.setSelectedMovie(this.$route.params.Mid);
      this.form = this.selectedMovie;
      this.poster = this.selectedMovie.poster;
      if (this.form.movieType.name === "Medienkunst") {
        this.setMediaArtGroup();
      }
    } else {
      this.form = new Movie({});
    }
    this.isLoading = false;
  }
};
</script>

<style lang="scss" scoped>
#content input[disabled] {
  color: #dcdad1;
  padding: 2px;
  margin: 0 0 0 0;
  background-image: none;
}
</style>
