var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.searchQuery,
          expression: "searchQuery"
        }
      ],
      staticClass: "search_box",
      attrs: {
        type: "text",
        placeholder: _vm.$t("Movie.list.search.searchTip")
      },
      domProps: { value: _vm.searchQuery },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          $event.preventDefault()
          return _vm.submitSearch.apply(null, arguments)
        },
        search: _vm.submitSearch,
        input: function($event) {
          if ($event.target.composing) return
          _vm.searchQuery = $event.target.value
        }
      }
    }),
    _vm.searchQuery
      ? _c(
          "button",
          {
            staticClass: "button button--border-left",
            attrs: { type: "button" },
            on: { click: _vm.resetSearch }
          },
          [_vm._v(" X ")]
        )
      : _vm._e(),
    _c(
      "button",
      {
        staticClass: "button button--border-left",
        attrs: { type: "button" },
        on: { click: _vm.submitSearch }
      },
      [_vm._v(" OK ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }