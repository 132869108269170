<template>
  <div class="form-item">
    <label>{{ $t("MemberProfile.form.labels.uploadPDF") }}</label>
    <div class="form-item-input">
      <div v-if="memberProfilePdf" class="s-mrgb-03 s-mrgt-02">
        <span>{{ $t("MemberProfile.form.labels.filename") }}: </span>
        <a :href="memberProfilePdf" target="_blank">{{ filename }}</a>
      </div>
      <input
        type="file"
        name="uploadPortrait"
        @change="submitPDF"
        accept="application/pdf"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberProfileUploadPdf",
  data() {
    return {
      pdf: null
    };
  },
  props: {
    value: null,
    filename: null
  },
  computed: {
    memberProfilePdf() {
      return this.value;
    }
  },
  methods: {
    submitPDF(event) {
      this.pdf = event.target.files[0];
    }
  },
  watch: {
    pdf: {
      handler(value) {
        this.$emit("setPDF", value);
      }
    }
  }
};
</script>
