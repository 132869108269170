<template>
  <div class="form-item">
    <label class="typo__label" for="movieStyles"
      >{{ $t("Movie.form.labels.categories.style") }}:
    </label>
    <div class="multiselect-wrapper">
      <multiselect
        v-model="movieStyles"
        id="movieStyles"
        label="name"
        track-by="id"
        :placeholder="$t('Movie.form.labels.categories.placeholder')"
        open-direction="bottom"
        :options="movieStylesMultiSelect.styles"
        :multiple="true"
        :searchable="false"
        :loading="movieStylesMultiSelect.isLoading"
        :clear-on-select="false"
        :close-on-select="false"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        :disabled="!isEditable"
      >
        <template slot="tag" slot-scope="{ option, remove }"
          ><span class="custom__tag"
            ><span>{{ option.name }}</span
            ><span class="custom__remove" @click="remove(option)"
              ><svg-icon name="times" class="icon icon--small"/></span></span
        ></template>
        <span v-if="!movieStylesMultiSelect.styles.length" slot="noResult">{{
          $t("Movie.form.errors.elementNotFound")
        }}</span>
      </multiselect>
      <p class="tip text-small">
        {{ $t("Movie.form.labels.search.liveSearch") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovieStyles",
  data() {
    return {
      movieStylesMultiSelect: {
        styles: this.movieStyleList,
        isLoading: false
      }
    };
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    movieStyleList: {
      type: Array,
      required: true
    },
    formObject: {
      type: Object,
      required: true
    }
  },
  computed: {
    movieStyles: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    },
    isEditable() {
      if (this.formObject.movieType.name === "Medienkunst") {
        this.clearMovieStyles();
        return false;
      }
      return true;
    }
  },
  methods: {
    clearMovieStyles() {
      this.movieStyles = null;
    }
  }
};
</script>
