var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", { attrs: { for: "subtitle-language" } }, [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.dubbedLang")) + ":")
    ]),
    _c(
      "div",
      { staticClass: "multiselect-wrapper" },
      [
        _c(
          "multiselect",
          {
            attrs: {
              id: "subtitle-language",
              label: "language",
              "track-by": "id",
              placeholder: _vm.$t("Movie.form.labels.search.typeToSearch"),
              "open-direction": "bottom",
              options: _vm.dubbedLangMultiSelect.languageList,
              multiple: true,
              searchable: true,
              loading: _vm.dubbedLangMultiSelect.isLoading,
              "internal-search": false,
              "clear-on-select": false,
              "close-on-select": false,
              "options-limit": 300,
              "max-height": 600,
              "show-no-results": false,
              "hide-selected": true
            },
            on: { "search-change": _vm.searchLanguageList },
            scopedSlots: _vm._u([
              {
                key: "tag",
                fn: function({ option, remove }) {
                  return [
                    _c("span", { staticClass: "custom__tag" }, [
                      _c("span", [_vm._v(_vm._s(option.language))]),
                      _c(
                        "span",
                        {
                          staticClass: "custom__remove",
                          on: {
                            click: function($event) {
                              return remove(option)
                            }
                          }
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "icon icon--small",
                            attrs: { name: "times" }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.movieDubbedLanguages,
              callback: function($$v) {
                _vm.movieDubbedLanguages = $$v
              },
              expression: "movieDubbedLanguages"
            }
          },
          [
            _vm.dubbedLangMultiSelect.languageList
              ? _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                  _vm._v(_vm._s(_vm.$t("Movie.form.errors.elementNotFound")))
                ])
              : _vm._e()
          ]
        ),
        _c("p", { staticClass: "tip text-small" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("Movie.form.labels.search.liveSearch")) + " "
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }