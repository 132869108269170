<template>
  <div class="reset-password-confirm-view flex flex--column align-center">
    <div v-if="!resetCompleted" class="reset-form">
      <h2>{{ $t("ResetPassword.resetPasswordConfirmTitle") }}</h2>
      <p class="s-mrgb-03 s-mrgt-05 text-small">
        {{ $t("ResetPassword.rules.info") }}
      </p>
      <p v-if="!isPasswordValid" class="s-mrgb-03 s-mrgt-05 password-invalid">
        {{ $t("ResetPassword.rules.error") }}
      </p>

      <p class="error s-mrgt-05 password-invalid" v-show="resetMatchError">
        {{ $t("ResetPassword.noMatch") }}
      </p>

      <p class="error s-mrgt-05 password-invalid" v-show="resetError">
        {{ $t("ResetPassword.errorOccurred") }}
      </p>

      <form class="form-item s-mrgt-05">
        <label for="password"> {{ $t("ResetPassword.password") }}: </label>
        <input
          v-model="inputs.password"
          @keyup="passwordValid"
          type="password"
          id="password"
        />

        <label for="password-confirm" class="s-mrgt-05">
          {{ $t("ResetPassword.passwordConfirm") }}:
        </label>
        <input
          @keyup="validate"
          v-model="inputs.passwordConfirm"
          type="password"
          id="password-confirm"
          @keyup.enter="submitResetPassword"
        />
      </form>
      <button
        @click="submitResetPassword"
        class="button"
        :disabled="isDisabled"
      >
        {{ $t("ResetPassword.reset") }}
      </button>
    </div>

    <div v-else>
      <p class="success">{{ $t("ResetPassword.yourPassSetSuccess") }}</p>
      <p>
        {{ $t("ResetPassword.pleaseGo") }}
        <router-link
          :to="{
            name: 'Login'
          }"
          @click.native="clearResetStatus"
          >{{ $t("ResetPassword.login") }}</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ResetPassword } from "../services/ResetPassword.service.js";

export default {
  name: "PasswordResetConfirm",
  data() {
    return {
      inputs: {
        password: "",
        passwordConfirm: "",
        token: this.$route.params.token
      },
      btnDisabled: false,
      isPasswordValid: true
    };
  },
  computed: {
    ...mapState("PasswordStore", [
      "resetCompleted",
      "resetError",
      "resetMatchError"
    ]),
    isDisabled: function() {
      return !this.btnDisabled;
    }
  },
  methods: {
    ...mapActions("PasswordStore", [
      "setPasswordResetSuccess",
      "setPasswordResetFailure",
      "setPasswordResetMatchFailure",
      "clearResetStatus"
    ]),
    /**
     * Submits the new password to auth-rest
     */
    submitResetPassword: async function() {
      // call the api to reset the password
      await ResetPassword(this.inputs)
        .then(() => {
          this.setPasswordResetSuccess();
        })
        .catch(() => {
          this.setPasswordResetFailure();
        });
    },
    validate: function() {
      if (this.isPasswordValid) {
        if (this.inputs.password === this.inputs.passwordConfirm) {
          this.clearResetStatus();
          this.setPasswordResetMatchFailure(false); // remove error, put resetMatchError=false
          this.btnDisabled = true;
        } else {
          this.btnDisabled = false;
          this.setPasswordResetMatchFailure(true); // there is an error, resetMatchError=false
        }
      }
    },
    passwordValid() {
      this.isPasswordValid =
        this.inputs.password.length >= 8 && isNaN(this.inputs.password);
    }
  },
  mounted() {
    this.clearResetStatus();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base.scss";

.reset-form {
  max-width: 600px;
  width: 100%;
}
.password-invalid {
  color: red;
}
</style>
