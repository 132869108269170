<template>
  <div class="form-item">
    <label for="dvd-bluray-link"
      >{{ $t("Movie.form.labels.dvdBlurayLink") }}:</label
    >
    <div class="form-item-input flex--col">
      <input type="url" v-model="movieDVDLink" id="dvd-bluray-link" />
      <p class="tip text-small">
        {{ $t("Movie.form.helpText.correctFormat") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovieDVDLink",
  props: {
    value: {
      type: String,
      default: "",
      required: true
    }
  },
  computed: {
    movieDVDLink: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
