import { axiosBaseService, getAPI } from "./axiosBase.service";
import { MemberProfile } from "../models/MemberProfile.models";
import store from "../store/index.js";

const getMemberProfileDetail = async id => {
  try {
    const response = await axiosBaseService.get(
      `/usersapi/member_profiles/${id}/`
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    return new MemberProfile(response.data);
  } catch (error) {
    throw error;
  }
};

const postMemberProfile = async payload => {
  try {
    const response = await getAPI.post(
      `${store.state.locale}/usersapi/member_profiles/`,
      payload
    );
    if (response.status !== 200 && response.status !== 201) {
      return response.statusText;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const patchMemberProfile = async (id, payload) => {
  try {
    const response = await getAPI.put(
      `${store.state.locale}/usersapi/member_profiles/${id}/`,
      payload
    );
    if (response.status !== 200 && response.status !== 201) {
      return response.statusText;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const uploadMemberProfileMedia = async (id, payload) => {
  try {
    const response = await getAPI.patch(
      `${store.state.locale}/usersapi/member_profiles/${id}/upload_media/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const removeMemberProfilePortrait = async (id) => {
  const response = await getAPI.delete(
    `${store.state.locale}/usersapi/member_profiles/${id}/delete_portrait/`
  );
  return response;
};

const getFilteredMemberProfileList = async (searchTerm, filters, mandate) => {
  try {
    const response = await axiosBaseService.get(
      `${store.state.locale}/usersapi/member_profiles/`,
      {
        params: {
          occupations: filters.map(filter => filter.id).join(","),
          search: searchTerm,
          mandates: mandate ? mandate.id : ""
        }
      }
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.map(memberProfile => {
      return new MemberProfile(memberProfile);
    });
  } catch (error) {
    throw error;
  }
};

const getMemberProfileMovies = async () => {
  try {
    const response = await getAPI.get(
      `${store.state.locale}/usersapi/member_profiles/credits/`
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  getMemberProfileDetail,
  postMemberProfile,
  uploadMemberProfileMedia,
  removeMemberProfilePortrait,
  patchMemberProfile,
  getFilteredMemberProfileList,
  getMemberProfileMovies
};
