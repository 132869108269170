var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.isLoading
      ? _c("div", [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitMemberProfile.apply(null, arguments)
                },
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                }
              }
            },
            [
              _c("div", { staticClass: "form-item" }, [
                _c("label", { attrs: { for: "firstName" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("MemberProfile.form.labels.firstName")) + ": "
                  ),
                  _c("span", { staticClass: "error" }, [_vm._v("*")])
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.firstName,
                      expression: "form.firstName"
                    }
                  ],
                  attrs: { type: "text", id: "firstName" },
                  domProps: { value: _vm.form.firstName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "firstName", $event.target.value)
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "form-item" }, [
                _c("label", { attrs: { for: "lastName" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("MemberProfile.form.labels.lastName")) + ": "
                  ),
                  _c("span", { staticClass: "error" }, [_vm._v("*")])
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.lastName,
                      expression: "form.lastName"
                    }
                  ],
                  attrs: { type: "text", id: "lastName" },
                  domProps: { value: _vm.form.lastName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "lastName", $event.target.value)
                    }
                  }
                })
              ]),
              _c("MemberProfileAddress", {
                attrs: { "show-address": _vm.form.showAddress },
                on: {
                  setMemberProfileShowAddress: _vm.setMemberProfileShowAddress
                },
                model: {
                  value: _vm.form.address,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address"
                }
              }),
              _c("MemberProfilePhone", {
                attrs: { "show-phone": _vm.form.showPhone },
                on: {
                  setMemberProfileShowPhone: _vm.setMemberProfileShowPhone
                },
                model: {
                  value: _vm.form.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone"
                }
              }),
              _c("MovieWebsite", {
                model: {
                  value: _vm.form.website,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "website", $$v)
                  },
                  expression: "form.website"
                }
              }),
              _c("div", { staticClass: "form-item" }, [
                _c("label", { attrs: { for: "profileText" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("MemberProfile.form.labels.text")) +
                      ": "
                  )
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.text,
                      expression: "form.text"
                    }
                  ],
                  attrs: { maxlength: 1000, id: "profileText", rows: "15" },
                  domProps: { value: _vm.form.text },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "text", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "flex justify-between w-100" }, [
                  _c("div", { staticClass: "tip text-small" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("MemberProfile.form.helpTexts.textTip")) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "tip text-small" }, [
                    _vm._v(_vm._s(_vm.countCharacters) + "/1000")
                  ])
                ])
              ]),
              _c("div", { staticClass: "form-item" }, [
                _c("label", { attrs: { for: "profileText" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("MemberProfile.form.labels.textFr")) +
                      ": "
                  )
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.textFr,
                      expression: "form.textFr"
                    }
                  ],
                  attrs: { id: "profileTextFr", rows: "15" },
                  domProps: { value: _vm.form.textFr },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "textFr", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "tip text-small" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("MemberProfile.form.helpTexts.textTip")) +
                      " "
                  )
                ])
              ]),
              _c("MemberProfileUploadPortrait", {
                attrs: {
                  value: _vm.form.portrait,
                  "portrait-changed": _vm.portraitChanged
                },
                on: { setPortrait: _vm.setPortrait }
              }),
              _c("MemberProfileUploadPdf", {
                attrs: { value: _vm.form.pdf, filename: _vm.form.pdfFilename },
                on: { setPDF: _vm.setPDF }
              }),
              _c("MemberProfileCredits", {
                model: {
                  value: _vm.form.memberCredits,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "memberCredits", $$v)
                  },
                  expression: "form.memberCredits"
                }
              }),
              _c("MemberProfileOccupation", {
                attrs: { "member-credits": _vm.form.movies },
                model: {
                  value: _vm.form.memberOccupations,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "memberOccupations", $$v)
                  },
                  expression: "form.memberOccupations"
                }
              }),
              _vm.form.credits && _vm.form.credits.length
                ? _c("Credits", {
                    attrs: {
                      "movie-credits": _vm.form.credits,
                      "hidden-movies": _vm.form.hiddenMovies
                    },
                    on: { hideMovie: _vm.updateHiddenMovies }
                  })
                : _vm._e(),
              _c("div", { staticClass: "flex justify-between s-mrgt-09" }, [
                _c(
                  "button",
                  {
                    staticClass: "button s-mrgr-05",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.cancel.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Movie.form.buttons.cancelMovie")) +
                        " "
                    )
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: {
                      type: "submit",
                      disabled: !_vm.isFormValid || _vm.isTextToLong
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("Movie.form.buttons.addMovie")) + " "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ])
      : _c("div", [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("MemberProfile.form.helpTexts.fetchingData")))
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }