<template>
  <div>
    <!-- Page title -->
    <h1 class="s-mrgb-05">{{ $t("Companies.title") }}</h1>
    <!-- Search -->
    <search-input @submitted="getInpit" />
    <div class="row">
      <!-- Filters -->
      <div class="col-xs-12 col-t-md-4">
        <Filters
          :group-occupation-list="groupOccupationList"
          @setSelectedFilters="setSelectedFilterList"
          @resetSearch="resetSearch"
        />
        <div class="s-mrgt-05">
          <p class="s-mrgt-05 col-t-md-9 s-pdgl-00 s-mrgb-05 text-small">
            <i18n path="Company.list.searchHelp.text">
              <template #mandate>
                <span>{{ mandate.name }}</span>
              </template>
              <template #registrationLink>
                <a :href="mandate.registrationLink">{{
                  $t("Company.list.searchHelp.linkText")
                }}</a>
              </template>
            </i18n>
          </p>
        </div>
        <Support />
      </div>
      <div class="col-xs-12 col-t-md-8">
        <CompaniesList v-if="!isLoading" :companies="companyListForPage" />
        <loader v-else></loader>
        <div v-if="pagedCompanyList" class="col-xs-12">
          <Pagination
            :current-page="page"
            :page-count="pagedCompanyList.length"
            @setCurrentPage="setCurrentPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCompanies,
  getFilteredCompanies
} from "../../services/Company.service";
import CompaniesList from "../../components/Company/CompaniesList";
import Filters from "../../components/MemberProfiles/Filters";
import Support from "../../components/Support";
import Pagination from "../../components/Pagination";
import { getGroupOccupationList } from "../../services/Occupation.service";
import { mapState } from "vuex";
import chunk from "lodash/chunk";
import loader from "../../components/loader";
import SearchInput from "../../components/SearchInput.vue";

export default {
  name: "Companies",
  components: {
    CompaniesList,
    Filters,
    Pagination,
    loader,
    Support,
    SearchInput
  },
  data() {
    return {
      isLoading: false,
      companyList: null,
      page: 1,
      pagedCompanyList: null,
      groupOccupationList: [],
      filters: [],
      submittedSearchTerm: "",
      companyOccupations: []
    };
  },
  methods: {
    setCurrentPage(page) {
      this.page = page;
    },
    setSelectedFilterList(filters) {
      this.filters = filters;
    },
    getInpit(value) {
      this.submittedSearchTerm = value;
    },
    resetSearch() {
      this.submittedSearchTerm = "";
    },
    addOccupationIfCurrentMandate(company) {
      if (company.mandates[0].name === this.mandate.name) {
        company.services.forEach(occupation => {
          if (occupation.isUnlocked) {
            this.companyOccupations.push(occupation);
          }
        });
      }
    },
    /**
     * returns grouped list of only services within given company list
     */
    filterOccupations() {
      this.groupOccupationList.forEach(group => {
        let groupOccupations = [];
        group.occupations.forEach(occupation => {
          if (
            !this.companyOccupations.some(
              occ => occ.occupation.id === occupation.id
            )
          ) {
            groupOccupations.push(occupation);
          }
        });
        groupOccupations.forEach(occ => {
          let index = group.occupations.indexOf(occ);
          group.occupations.splice(index, 1);
        });
      });
    }
  },
  computed: {
    ...mapState("MandateStore", ["mandate"]),
    companies() {
      return this.companyList.filter(company => {
        return company.mandates.some(mandate => mandate.id === this.mandate.id);
      });
    },
    getCompanyPage() {
      return this.page - 1;
    },
    companyListForPage() {
      if (this.pagedCompanyList) {
        return this.pagedCompanyList[this.getCompanyPage];
      }
      return this.companies;
    }
  },
  asyncComputed: {
    async setCompanyList() {
      this.isLoading = true;
      const response = await getFilteredCompanies(
        this.submittedSearchTerm,
        this.filters,
        this.mandate
      );
      this.companyList = response;

      if (this.companyList.length >= process.env.VUE_APP_COMPANY_LIST_PAGING) {
        this.pagedCompanyList = chunk(
          this.companyList,
          process.env.VUE_APP_COMPANY_LIST_PAGING
        );
      }
      this.isLoading = false;
    }
  },
  async mounted() {
    this.isLoading = true;
    this.companyList = await getCompanies();
    if (this.companyList.length >= process.env.VUE_APP_COMPANY_LIST_PAGING) {
      this.pagedCompanyList = chunk(
        this.companies,
        process.env.VUE_APP_COMPANY_LIST_PAGING
      );
    }
    this.companies.forEach(company => {
      if (company.mandates.length) {
        this.addOccupationIfCurrentMandate(company);
      }
    });
    this.groupOccupationList = await getGroupOccupationList();
    this.filterOccupations();
    this.isLoading = false;
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/base.scss";
.search {
  display: flex;
  margin-bottom: 1em;
  height: 4.2vh;
}
@media only screen and (max-width: 800px) {
  .search {
    .search_box {
      width: 100%;
    }
  }
}
</style>
