var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", { attrs: { for: "in-cinema" } }, [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.inCinema")) + ":")
    ]),
    _c("div", { staticClass: "form-item-input flex--col" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.movieInCinema,
            expression: "movieInCinema"
          }
        ],
        attrs: { type: "text", id: "in-cinema", pattern: "https?://.+" },
        domProps: { value: _vm.movieInCinema },
        on: {
          input: function($event) {
            if ($event.target.composing) return
            _vm.movieInCinema = $event.target.value
          }
        }
      }),
      _c("p", { staticClass: "tip text-small" }, [
        _vm._v(" " + _vm._s(_vm.$t("Movie.form.helpText.correctFormat")) + " ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }