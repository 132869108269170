var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap container-fluid s-pdgb-08", attrs: { id: "app" } },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12" },
          [
            _c("NavBarComponent"),
            _c("div", { staticClass: "l-pdgt-07" }, [_c("router-view")], 1)
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }