import { axiosBaseService } from "./axiosBase.service";
import { Supporter } from "../models/Supporter.model";

const getSupporterList = async () => {
  try {
    const url = `/moviesapi/supporters/`;
    const response = await axiosBaseService.get(url);
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.results.map(supporter => {
      return new Supporter(supporter);
    });
  } catch (error) {
    throw error;
  }
};

export { getSupporterList };
