<template>
  <div>
    <!-- movie screenshots -->
    <div class="flex flex--column">
      <div
        class="s-mrgb-06"
        v-for="(movieImage, key) in movie.movieImages.slice(1)"
        :key="key"
      >
        <img
          :src="movieImage.image"
          class="screenshot"
          alt="Film Screenshots"
        />
      </div>

      <div>
        <div>
          <h2 class="h1 s-mrgb-01">{{ movie.title }}</h2>
          <h2 v-if="direction" class="h1 s-mrgb-06">
            {{ $t("Movie.detail.movieBy") }}
            {{ direction }}
          </h2>
        </div>
        <div id="allCredits" v-if="movie.movieCredits">
          <MovieMainCastCredits :credit-list="movie.movieCredits" />
          <MovieCredits v-if="hasCredits" :credit-list="movie.movieCredits" />
          <FurtherInfos v-if="hasInfo" :movie="movie" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import MovieCredits from "../Credits/MovieCredits";
import MovieMainCastCredits from "../Credits/MovieMainCastCredits.vue";
import FurtherInfos from "../Credits/FurtherInfos.vue";

export default Vue.extend({
  props: {
    movie: {
      type: Object,
      required: true
    },
    hasInfo: {
      type: Boolean,
      required: true
    },
    hasCredits: {
      type: Boolean,
      required: true
    }
  },
  components: {
    MovieCredits,
    MovieMainCastCredits,
    FurtherInfos
  },
  computed: {
    direction() {
      return this.movie.movieCredits
        .filter(
          credit =>
            credit.occupation.credit ===
            this.$t("Movie.detail.occupations.direction")
        )
        .map(credit => credit.user.fullName)
        .join(", ");
    }
  }
});
</script>

<style lang="scss" scoped>
.screenshot {
  width: 100%;
}
</style>
