var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c(
            "div",
            [
              _c("h1", { staticClass: "s-mrgb-05 s-mrgt-08" }, [
                _vm._v(" " + _vm._s(_vm.$t("Company.form.edit.title")) + " ")
              ]),
              _c("CompanyEditForm", {
                attrs: { "company-profile": _vm.company },
                on: {
                  patchCompanyProfile: _vm.patchCompanyProfile,
                  cancel: _vm.cancel
                }
              })
            ],
            1
          )
        : _c("loader")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }