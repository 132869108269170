<template>
  <div class="search">
    <input
      class="search_box"
      type="text"
      v-model="searchQuery"
      :placeholder="$t('Movie.list.search.searchTip')"
      @keydown.enter.prevent="submitSearch"
      @search="submitSearch"
    />
    <button
      v-if="searchQuery"
      class="button button--border-left"
      type="button"
      @click="resetSearch"
    >
      X
    </button>
    <button
      class="button button--border-left"
      type="button"
      @click="submitSearch"
    >
      OK
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: ""
    };
  },
  methods: {
    submitSearch() {
      this.$emit("submitted", this.searchQuery);
    },
    resetSearch() {
      this.searchQuery = "";
      this.submitSearch();
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/base.scss";
.search {
  display: flex;
  margin-bottom: 1em;
  height: 4.2vh;
  width: 215px;
  border: 1px solid black;

  .search_box {
    border: none;
    width: 100%;
  }
}
@media only screen and (max-width: 800px) {
  .search {
    width: 100%;
  }
}
</style>
