<template>
  <div>
    <MovieEditForm
      :movieId="this.$route.params.Mid"
      @patchMovie="patchMovie"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import MovieEditForm from "@/components/movies/MovieForms/MovieEditForm";
import { patchMovie } from "@/services/movie.service";
import {
  patchMoviePoster,
  deleteMoviePoster,
  postMovieImages
} from "@/services/movie.service";
import { mapMutations } from "vuex";

export default {
  name: "MovieEditView",
  components: {
    MovieEditForm
  },
  methods: {
    ...mapMutations("Adding", ["addAddingState", "removeAddingState"]),
    async patchMovie(movie, poster, movieImages) {
      this.addAddingState();
      await patchMovie(movie, movie.id)
        .then(() => {
          if (poster) {
            patchMoviePoster(movie.id, poster);
          } else {
            deleteMoviePoster(movie.id);
          }
          this.collectMovieImagesData(movie, movieImages);
          movieImages.append("update", true);
          movieImages.append("movieId", movie.id);
          postMovieImages(movieImages)
            .then(() => {
              const to = movie.published
                ? { name: "MovieDetail", params: { Mid: movie.id } }
                : { name: "UserMovieListView" };
              this.$router.push(to).then(() => {
                this.removeAddingState();
                this.$toaster.success(
                  this.$i18n.t("Toaster.movieEdit.success")
                );
              });
            })
            .catch(error => {
              this.removeAddingState();
              this.$toaster.warning(error.response.data[0]);
            });
        })
        .catch(() => {
          this.removeAddingState();
          this.$toaster.error(this.$i18n.t("Toaster.movieEdit.failure"));
        });
    },
    cancel(movie) {
      this.$router.push({
        name: "MovieDetail",
        params: { Mid: movie.id }
      });
    },
    collectMovieImagesData(movie, formData) {
      movie.movieImages.forEach(movieImage => {
        if (!movieImage.new) {
          formData.append("remained", movieImage.id);
        }
      });
    }
  }
};
</script>
