import { getSupporterList } from "@/services/supporter.service";

export default {
  namespaced: true,
  state: {
    supporterList: []
  },
  mutations: {
    updateSupportersList(state, data) {
      state.supporterList = data;
    }
  },
  actions: {
    async setSupporterList({ commit }) {
      const supporterList = await getSupporterList();
      commit("updateSupportersList", supporterList);
    }
  }
};
