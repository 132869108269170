import {
  getMovieTypes,
  getMovieFormats,
  getMovieStyles
} from "@/services/Category.service";

export default {
  namespaced: true,
  state: {
    movieTypeList: [],
    movieFormatList: [],
    movieStyleList: []
  },
  mutations: {
    updateMovieTypeList(state, data) {
      state.movieTypeList = data;
    },
    updateMovieFormatList(state, data) {
      state.movieFormatList = data;
    },
    updateMovieStyleList(state, data) {
      state.movieStyleList = data;
    }
  },
  actions: {
    async setCategories({ commit }, isMovie) {
      const movieTypes = await getMovieTypes(isMovie);
      commit("updateMovieTypeList", movieTypes);

      const movieFormats = await getMovieFormats(isMovie);
      commit("updateMovieFormatList", movieFormats);

      const movieStyles = await getMovieStyles(isMovie);
      commit("updateMovieStyleList", movieStyles);
    }
  }
};
