<template>
  <div>
    <div
      class="row col-xs-12"
      v-for="(member, index) in membersList"
      :key="`member--${index}`"
    >
      <div class="col-t-md-8">
        <div>
          <div class="s-mrgb-06">
            <h2
              class="h1"
              @mouseover="setSelectedProfile(member)"
              @mouseout="setSelectedProfile(null)"
            >
              <router-link
                :to="{
                  name: 'MemberProfileDetailView',
                  params: { MpId: member.id }
                }"
              >
                {{ getFullName(member) }}
              </router-link>
            </h2>
            <div class="row col-xs-12">
              <!-- <div
                v-for="(memberOccupation, index) in getOnlyUnlockedOccupations(
                  member
                )"
                :key="index"
                class="occupations"
              >
                <div v-if="memberOccupation.isUnlocked">
                  <span class="credit s-mrgr-02">{{
                    memberOccupation.occupation.description
                  }}</span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-t-md-4 portrait-container">
        <div class="portrait">
          <p v-if="selectedProfile && selectedProfile.id === member.id">
            <img @error="usePlaceholder" :src="member.portrait" alt="" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberProfilesList",
  data() {
    return {
      selectedProfile: null,
      placeholderProfile:
        "https://via.placeholder.com/150x300/808080/FFFFFF/?text=User+Profile+Placeholder"
    };
  },
  props: {
    membersList: {
      type: Array,
      required: true
    }
  },
  methods: {
    usePlaceholder(e) {
      e.target.src = this.placeholderProfile;
    },
    getFullName(member) {
      return `${member.firstName} ${member.lastName}`;
    },
    setSelectedProfile(profile) {
      this.selectedProfile = profile;
    },
    getOnlyUnlockedOccupations(member) {
      return member.memberOccupations.filter(
        occupation => occupation.isUnlocked === true
      );
    }
  }
};
</script>

<style lang="scss">
.occupations:not(:last-of-type) {
  .credit:after {
    content: ",";
  }
}
</style>
