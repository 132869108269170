var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", { attrs: { for: "synopsis-de" } }, [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.Synopsis")) + "(de): "),
      _c("span", { staticClass: "error" }, [_vm._v("*")])
    ]),
    _c("div", { staticClass: "form-item-input" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.synopsis,
            expression: "synopsis"
          }
        ],
        staticClass: "synopsis",
        attrs: {
          id: "synopsis-de",
          name: "synopsis",
          rows: "7",
          maxlength: "2000"
        },
        domProps: { value: _vm.synopsis },
        on: {
          input: function($event) {
            if ($event.target.composing) return
            _vm.synopsis = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "flex justify-between w-100" }, [
        _c("p", { staticClass: "tip text-small" }, [
          _vm._v(" " + _vm._s(_vm.$t("Movie.form.helpText.synopsisTip")) + " ")
        ]),
        _c("div", { staticClass: "tip text-small" }, [
          _vm._v(_vm._s(_vm.countCharacters) + "/2000")
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }