var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mainCast.length || _vm.supportingCast.length
    ? _c("div", { staticClass: "s-mrgb-06" }, [
        _c("h2", { staticClass: "h1 s-mrgr-02" }, [
          _vm._v(_vm._s(_vm.$t("Movie.detail.credits.cast")))
        ]),
        _vm.mainCast.length
          ? _c("div", { staticClass: "row s-mrgb-04" }, [
              _c("div", { staticClass: "s-mrgr-02 col-xs-12 col-t-md-4" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("Movie.detail.credits.mainCast")))
                ])
              ]),
              _c(
                "div",
                { staticClass: "s-mrgr-02 col-xs-12 col-t-md-4" },
                _vm._l(_vm.mainCast, function({ name, to }) {
                  return _c(
                    "p",
                    { key: name },
                    [
                      to
                        ? _c(
                            "router-link",
                            { attrs: { to: to } },
                            [
                              _c("svg-icon", {
                                staticClass: "icon icon--small",
                                attrs: { name: "arrow-long-right" }
                              }),
                              _vm._v(" " + _vm._s(name) + " ")
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(name))])
                    ],
                    1
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _vm.supportingCast.length
          ? _c("div", { staticClass: "row s-mrgb-04" }, [
              _c("div", { staticClass: "s-mrgr-02 col-xs-12 col-t-md-4" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("Movie.detail.credits.supportingCast")))
                ])
              ]),
              _c(
                "div",
                { staticClass: "s-mrgr-02 col-xs-12 col-t-md-6" },
                _vm._l(_vm.supportingCast, function({ name, to }) {
                  return _c(
                    "p",
                    { key: name },
                    [
                      to
                        ? _c(
                            "router-link",
                            { attrs: { to: to } },
                            [
                              _c("svg-icon", {
                                staticClass: "icon icon--small",
                                attrs: { name: "arrow-long-right" }
                              }),
                              _vm._v(" " + _vm._s(name) + " ")
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(name))])
                    ],
                    1
                  )
                }),
                0
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }