<template>
  <div class="form-item">
    <label>{{ $t("Movie.form.labels.imageUpload.poster") }}</label>
    <div v-show="showThumbanil || value" class="image-thumbnail s-mrgb-04">
      <img
        id="poster-thumbnail"
        :src="value"
        :alt="$t('Movie.form.edit.movieImages.alt')"
      />
      <input
        v-show="!value"
        type="file"
        name="imageUpload"
        @change="submitPoster"
        accept="image/*"
      />
      <button type="button" class="button s-mrgt-02" @click="removePoster">
        {{ $t("Movie.form.edit.movieImages.buttons.removePoster") }}
      </button>
    </div>
    <button
      type="button"
      v-if="!showThumbanil && (hasNewPoster || !value)"
      class="button"
      @click="addPoster"
    >
      {{ $t("Movie.form.edit.movieImages.buttons.addPoster") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "MoviePoster",
  props: {
    value: null
  },
  data() {
    return {
      hasNewPoster: false,
      showThumbanil: false
    };
  },
  methods: {
    async submitPoster(event) {
      await this.updateImageThumbnail(event);
      const selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append("poster", selectedFile);
      this.$emit("setPoster", fd);
    },
    updateImageThumbnail(event) {
      const selectedFile = event.target.files[0];
      const fileReader = new FileReader();
      const imageElement = document.getElementById("poster-thumbnail");
      imageElement.title = selectedFile.name;
      fileReader.onload = function(event) {
        imageElement.src = event.target.result;
      };
      fileReader.readAsDataURL(selectedFile);
      this.hasNewPoster = false;
    },
    removePoster() {
      this.hasNewPoster = false;
      this.showThumbanil = false;
      this.$emit("setPoster", null);
    },
    addPoster() {
      this.hasNewPoster = false;
      this.showThumbanil = true;
      this.$emit("input", null);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base.scss";
.image-thumbnail {
  width: 25%;
  border: 1px solid $color-black;
  input {
    border: none;
  }
  button {
    margin: 0 0.5rem 0.2rem;
  }
}
</style>
