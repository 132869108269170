import { getAPI } from "./axiosBase.service";
import store from "../store";
import { Movie } from "../models/Movie.model";

const patchMoviePoster = async (id, payload) => {
  return getAPI.patch(
    `/${store.state.locale}/moviesapi/movies/${id}/upload-images/`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};

const deleteMoviePoster = async id => {
  return getAPI.delete(
    `/${store.state.locale}/moviesapi/movies/${id}/delete-poster/`
  );
};

const getMovieListForUser = async () => {
  try {
    const response = await getAPI.get(
      `/${store.state.locale}/moviesapi/movies/user-movies/`
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    const movies = response.data.movies.map(movie => {
      return new Movie(movie);
    });
    const draftMovies = response.data.draftMovies.map(movie => {
      return new Movie(movie);
    });
    return { movies: movies, draftMovies: draftMovies };
  } catch (e) {
    throw e;
  }
};

const patchMovie = async (payload, id) => {
  try {
    const response = await getAPI.put(
      `${store.state.locale}/moviesapi/movies/${id}/`,
      payload,
      {
        params: {
          id: id
        }
      }
    );
    if (response.status === 201) {
      return response;
    }
    return response.statusText;
  } catch (e) {
    throw e;
  }
};

const patchMovieImage = async (payload, id) => {
  try {
    const response = await getAPI.patch(
      `${store.state.locale}/moviesapi/screenshots/${id}/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    if (response.status === 201) {
      return response;
    }
    return response.statusText;
  } catch (e) {
    throw e;
  }
};

const postMovieImages = async payload => {
  return await getAPI.post(
    `/${store.state.locale}/moviesapi/screenshots/`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};

const postMovie = async payload => {
  return await getAPI.post(`${store.state.locale}/moviesapi/movies/`, payload);
};

export {
  patchMoviePoster,
  getMovieListForUser,
  patchMovie,
  patchMovieImage,
  postMovieImages,
  postMovie,
  deleteMoviePoster
};
