import { getMovieListForUser } from "@/services/movie.service";
import { getMovieDetail } from "@/services/getMovieDetail.service";

export default {
  namespaced: true,
  state: {
    submitMovieCompleted: false,
    submitMovieError: false,
    moviesList: [],
    selectedMovie: {},
    movieListForUser: [],
    yearFilters: []
  },
  mutations: {
    submitMovieSuccess(state) {
      state.submitMovieCompleted = true;
      state.submitMovieError = false;
    },
    updateMoviesList(state, data) {
      state.moviesList = data;
    },
    updateMoviesListYears(state, data) {
      state.yearFilters = data.sort((a, b) => {
        return a - b;
      });
    },
    updateSelectedMovie(state, data) {
      state.selectedMovie = data;
    },
    decrementMoviesList(state, MovieId) {
      const index = state.moviesList.findIndex(movie => movie.pk === MovieId);
      state.moviesList.splice(index, 1);
    },
    clearSubmitStatus(state) {
      state.submitMovieCompleted = false;
      state.submitMovieError = false;
    },
    updateMovieListForUser(state, data) {
      state.movieListForUser = data;
    }
  },
  actions: {
    async setMoviesListYears({ commit }, moviesListYearFilters) {
      commit("updateMoviesListYears", moviesListYearFilters);
    },
    async setPagedMovieList({ commit }, list) {
      commit("updateMoviesList", list);
    },
    async setSelectedMovie({ commit }, movieId) {
      const selectedMovie = await getMovieDetail(movieId);
      commit("updateSelectedMovie", selectedMovie);
    },
    async setMovieListForUser({ commit }) {
      const movieList = await getMovieListForUser();
      commit("updateMovieListForUser", movieList);
    },
    setMovieSuccess({ commit }) {
      commit("submitMovieSuccess");
    },
    setClearSubmitStatus({ commit }) {
      commit("clearSubmitStatus");
    }
  }
};
