<template>
  <div class="s-mrgb-05">
    <div v-if="movie.poster" class="poster__wrapper">
      <img @error="usePlaceholder" v-if="movie.poster" :src="movie.poster" alt="Filmposter" />
    </div>

    <div class="s-mrgb-06" v-if="movie.inCinema">
      <h2 class="h2">{{ $t("Movie.detail.inCinema") }}</h2>
      <div class="flex align-center">
        <svg-icon name="arrow-long-right" class="icon icon--small" /><a
          class="s-mrgl-02"
          :href="`${movie.inCinema}`"
          target="_blank"
        >
          {{ $t("Movie.detail.links.currentScreenings") }}
        </a>
      </div>
    </div>

    <SidebarCredits
      v-if="hasCredits"
      :credits="movie.movieCredits"
      :productionLinks="[...movie.productionLinks, ...movie.coProductionLinks]"
    />

    <PageNavigation
      :hasAwards="hasAwards"
      :hasFestivals="hasFestivals"
      :hasCredits="hasCredits"
      :hasInfo="hasInfo"
    />
    <Links v-if="hasLinks" :links="links" />

    <MovieEditButton :movie="movie" />
  </div>
</template>

<script>
import Vue from "vue";
import MovieEditButton from "../MovieEditComponents/Buttons/MovieEditButton";
import SidebarCredits from "./SidebarCredits";
import PageNavigation from "./PageNavigation";
import Links from "./Links";

export default Vue.extend({
  components: { SidebarCredits, MovieEditButton, PageNavigation, Links },
  data() {
    return {
      placeholderPoster: "https://via.placeholder.com/200x300/808080/FFFFFF/?text=Movie+Poster+Placeholder"
    };
  },
  props: {
    movie: {
      type: Object,
      required: true
    },
    hasInfo: {
      type: Boolean,
      required: true
    },
    hasCredits: {
      type: Boolean,
      required: true
    },
    hasLinks: {
      type: Boolean,
      required: true
    },
    hasAwards: {
      type: Boolean,
      required: true
    },
    hasFestivals: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    links() {
      const worldSalesLabel = this.movie.worldSalesLink?.title;
      const rentalLabel = this.movie.rentalLink?.title;
      const movieWebsiteTitle = this.$t("Movie.detail.links.movieWebsite");
      const rentalTitle = this.$t("Movie.detail.links.movieRental");
      const worldSalesTitle = this.$t("Movie.detail.links.worldSales");
      const vodTitle = this.$t("Movie.detail.links.VOD");
      const blurayTitle = this.$t("Movie.detail.links.bluray");

      return [
        { label: movieWebsiteTitle, to: this.movie.website },
        {
          label: rentalLabel ? `${rentalTitle}: ${rentalLabel}` : rentalTitle,
          to: this.movie.rentalLink?.url
        },
        {
          label: worldSalesLabel
            ? `${worldSalesTitle}: ${worldSalesLabel}`
            : worldSalesTitle,
          to: this.movie.worldSalesLink.url
        },
        { label: vodTitle, to: this.movie.vodLink },
        { label: blurayTitle, to: this.movie.dvdBlurayLink }
      ].filter(link => link.to);
    }
  },
  methods: {
    languagesString(languages) {
      return languages.map(langObj => langObj.language).join(", ");
    },
    usePlaceholder(e) {
      e.target.src = this.placeholderPoster;
    },
  }
});
</script>

<style lang="scss" scoped>
.poster__wrapper {
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    margin-bottom: 1rem;
    margin-top: 0;
  }
}
</style>
