var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex" },
    [
      _vm._l(_vm.pageNumbers, function(pageNumber, index) {
        return _c("div", { key: `pagination-button--${index}` }, [
          _c("input", {
            staticClass: "page-button s-mrgr-05 cursor-pointer",
            class: _vm.isActivePage(pageNumber) ? "is-active" : "",
            attrs: { type: "button", value: pageNumber },
            on: {
              click: function($event) {
                return _vm.setCurrentPage(pageNumber)
              }
            }
          })
        ])
      }),
      _vm.currentPage + 3 < _vm.pageCount
        ? _c("span", { staticClass: "s-mrgr-05" }, [_vm._v("...")])
        : _vm._e(),
      _c("div", [
        _c("input", {
          staticClass: "page-button s-mrgr-05 cursor-pointer",
          class: _vm.isActivePage(_vm.pageCount) ? "is-active" : "",
          attrs: { type: "button", value: _vm.pageCount },
          on: {
            click: function($event) {
              return _vm.setCurrentPage(_vm.pageCount)
            }
          }
        })
      ]),
      _c("input", {
        staticClass: "next-button cursor-pointer",
        attrs: {
          type: "button",
          value: _vm.$t("MemberPagination.next"),
          disabled: _vm.isNextButton
        },
        on: {
          click: function($event) {
            return _vm.setCurrentPage(_vm.currentPage + 1)
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }