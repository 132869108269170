var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "furtherInfo" } }, [
    _c(
      "div",
      { staticClass: "s-mrgb-06" },
      [
        _c("h2", { staticClass: "h1 s-mrgr-02" }, [
          _vm._v(_vm._s(_vm.$t("Movie.detail.technical")))
        ]),
        _vm._l(_vm.formats, function([label, format]) {
          return _c("div", { key: label, staticClass: "row s-mrgb-04" }, [
            _c("div", { staticClass: "s-mrgr-02 col-xs-12 col-t-md-4" }, [
              _c("p", [_vm._v(_vm._s(label))])
            ]),
            _c("div", { staticClass: "s-mrgr-02 col-xs-12 col-t-md-6" }, [
              _c("p", [_vm._v(" " + _vm._s(format) + " ")])
            ])
          ])
        })
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "s-mrgb-06" },
      [
        _c("h2", { staticClass: "h1 s-mrgr-02" }, [
          _vm._v(_vm._s(_vm.$t("Movie.detail.credits.language")))
        ]),
        _vm._l(_vm.languages, function([label, language]) {
          return _c("div", { key: label, staticClass: "row s-mrgb-04" }, [
            _c("div", { staticClass: "s-mrgr-02 col-xs-12 col-t-md-4" }, [
              _c("p", [_vm._v(_vm._s(label))])
            ]),
            _c("div", { staticClass: "s-mrgr-02 col-xs-12 col-t-md-6" }, [
              _c("p", [_vm._v(" " + _vm._s(language) + " ")])
            ])
          ])
        })
      ],
      2
    ),
    _vm.supporters.length
      ? _c("div", { staticClass: "s-mrgb-06 row" }, [
          _c("h2", { staticClass: "h1 s-mrgr-02 col-xs-12 col-t-md-4" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("Movie.detail.credits.sponsoring")) + " "
            )
          ]),
          _c(
            "div",
            { staticClass: "s-mrgr-02 col-xs-12 col-t-md-6" },
            _vm._l(_vm.movie.supporters, function(sponsor) {
              return _c("p", { key: sponsor.id }, [
                _vm._v(" " + _vm._s(sponsor.agency) + " ")
              ])
            }),
            0
          )
        ])
      : _vm._e(),
    _vm.movie.isan
      ? _c("div", { staticClass: "s-mrgb-06 row" }, [
          _c("h2", { staticClass: "h1 s-mrgr-02 col-xs-12 col-t-md-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("Movie.detail.credits.isan")) + " ")
          ]),
          _c("div", { staticClass: "s-mrgr-02 col-xs-12 col-t-md-6" }, [
            _c("p", [_vm._v(_vm._s(_vm.movie.isan))])
          ])
        ])
      : _vm._e(),
    _vm.awards.length
      ? _c("div", { staticClass: "s-mrgb-06 row", attrs: { id: "awards" } }, [
          _c("h2", { staticClass: "h1 s-mrgr-02 col-xs-12 col-t-md-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("Movie.detail.credits.awards")) + " ")
          ]),
          _c(
            "div",
            { staticClass: "s-mrgr-02 col-xs-12 col-t-md-6" },
            _vm._l(_vm.awards, function(award) {
              return _c("p", { key: award }, [_vm._v(_vm._s(award))])
            }),
            0
          )
        ])
      : _vm._e(),
    _vm.festivals.length
      ? _c(
          "div",
          { staticClass: "s-mrgb-06 row", attrs: { id: "festivals" } },
          [
            _c("h2", { staticClass: "h1 s-mrgr-02 col-xs-12 col-t-md-4" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("Movie.detail.credits.festivals")) + " "
              )
            ]),
            _c(
              "div",
              { staticClass: "s-mrgr-02 col-xs-12 col-t-md-6" },
              _vm._l(_vm.festivals, function(festival) {
                return _c("p", { key: festival }, [_vm._v(_vm._s(festival))])
              }),
              0
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }