var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasMovieUpdatePermission(_vm.movie.createdBy)
    ? _c("div", [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$router.push({
                  name: "MovieEditView",
                  params: { Mid: _vm.movie.id }
                })
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("Movie.detail.buttons.editMovie")) + " ")]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }