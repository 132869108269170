<template>
  <div>
    <div v-if="!isLoading" class="movie-list">
      <div class="row s-mrgb-10">
        <div class="col-xs-12 col-t-md-4">
          <h1 class="s-mrgb-05">{{ $t("Movie.userMovieList") }}</h1>
        </div>
        <div class="col-xs-12 col-t-md-8"></div>
        <MoviesList
          class="col-xs-12"
          :is-user-list="true"
          :movie-list="moviesListForPage"
        />
        <div class="col-xs-12 col-t-md-8 s-mrgt-05" v-if="isMoviesListPaged">
          <Pagination
            :current-page="page"
            :page-count="pagedMoviesList.length"
            @setCurrentPage="setCurrentPage"
          />
        </div>
      </div>
      <div v-if="draftMoviesListForPage.length" class="flex flex--column">
        <div class="s-mrgb-05">
          <h1>{{ $t("Movie.draftMovies") }}</h1>
        </div>
        <MoviesList :movie-list="draftMoviesListForPage" :draft="true" />
        <div
          class="col-xs-12 col-t-md-8 s-mrgt-05"
          v-if="isMoviesDraftListPaged"
        >
          <Pagination
            :current-page="pageDraft"
            :page-count="pagedMoviesDraftList.length"
            @setCurrentPage="setCurrentDraftPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MoviesList from "@/components/movies/MoviesList";
import Pagination from "../components/Pagination";
import chunk from "lodash/chunk";

export default {
  name: "UserMovieListView",
  components: {
    Pagination,
    MoviesList
  },
  data() {
    return {
      isLoading: true,
      movies: [],
      draftMovies: [],
      page: 1,
      pageDraft: 1,
      pagedMoviesList: null,
      pagedMoviesDraftList: null
    };
  },
  computed: {
    ...mapState("MoviesStore", ["movieListForUser"]),
    getMoviesPage() {
      return this.page - 1;
    },
    getMoviesDraftPage() {
      return this.pageDraft - 1;
    },
    isMoviesListPaged() {
      return this.pagedMoviesList;
    },
    isMoviesDraftListPaged() {
      return this.pagedMoviesDraftList;
    },
    moviesListForPage() {
      if (this.isMoviesListPaged) {
        return this.pagedMoviesList[this.getMoviesPage];
      }
      return this.movies;
    },
    draftMoviesListForPage() {
      if (this.isMoviesDraftListPaged) {
        return this.pagedMoviesDraftList[this.getMoviesDraftPage];
      }
      return this.draftMovies;
    }
  },
  methods: {
    ...mapActions("MoviesStore", ["setMovieListForUser"]),
    setCurrentPage(page) {
      this.page = page;
    },
    setCurrentDraftPage(page) {
      this.pageDraft = page;
    }
  },
  asyncComputed: {
    async setUserMoviesList() {
      if (this.movies.length >= process.env.VUE_APP_USER_MOVIE_LIST_PAGING) {
        this.pagedMoviesList = chunk(
          this.movies,
          process.env.VUE_APP_USER_MOVIE_LIST_PAGING
        );
      }
    },
    async setMoviesDraftList() {
      if (
        this.draftMovies.length >= process.env.VUE_APP_USER_MOVIE_LIST_PAGING
      ) {
        this.pagedMoviesDraftList = chunk(
          this.draftMovies,
          process.env.VUE_APP_USER_MOVIE_LIST_PAGING
        );
      }
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.setMovieListForUser();
    this.movies = this.movieListForUser.movies;
    this.draftMovies = this.movieListForUser.draftMovies;
    this.isLoading = false;
  }
};
</script>
