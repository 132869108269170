var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-item" },
    [
      _c("label", { attrs: { for: "phone" } }, [
        _vm._v(_vm._s(_vm.$t("MemberProfile.form.labels.phone")) + ": "),
        _c("span", { staticClass: "error" }, [_vm._v("*")])
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.memberProfilePhone,
            expression: "memberProfilePhone"
          }
        ],
        attrs: { type: "tel", id: "phone" },
        domProps: { value: _vm.memberProfilePhone },
        on: {
          input: function($event) {
            if ($event.target.composing) return
            _vm.memberProfilePhone = $event.target.value
          }
        }
      }),
      _c("p", { staticClass: "tip text-small" }, [
        _vm._v(_vm._s(_vm.$t("Generic.form.helpText.phoneNumber")))
      ]),
      _c("MemberProfileShowPhone", {
        model: {
          value: _vm.memberProfileShowPhone,
          callback: function($$v) {
            _vm.memberProfileShowPhone = $$v
          },
          expression: "memberProfileShowPhone"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }