<template>
  <div class="form-item">
    <label for="address"
      >{{ $t("MemberProfile.form.labels.address") }}:
      <span class="error">*</span></label
    >
    <input type="text" v-model="memberProfileAddress" id="address" />
    <MemberProfileShowAddress v-model="memberProfileShowAddress" />
  </div>
</template>

<script>
import MemberProfileShowAddress from "./MemberProfileShowAddress";
export default {
  name: "MemberProfileAddress",
  components: {
    MemberProfileShowAddress
  },
  props: {
    value: {
      type: String,
      required: true
    },
    showAddress: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    memberProfileAddress: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    },
    memberProfileShowAddress: {
      get() {
        return this.showAddress;
      },
      set(newVal) {
        this.$emit("setMemberProfileShowAddress", newVal);
      }
    }
  }
};
</script>
