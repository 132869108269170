<template>
  <div id="app" class="wrap container-fluid s-pdgb-08">
    <div class="row">
      <div class="col-xs-12">
        <NavBarComponent />
        <div class="l-pdgt-07">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBarComponent from "@/components/NavBarComponent";
import { mapState } from "vuex";

export default {
  name: "app",
  components: {
    NavBarComponent
  },
  computed: mapState(["locale"]),
  watch: {
    locale() {
      this.$router.replace({ params: { lang: this.locale } }).catch(() => {});
    }
  },
  created() {
    this.$store.dispatch("changeLocale", this.$store.state.locale);
  }
};
</script>
