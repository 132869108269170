<template>
  <div class="form-item">
    <label for="subtitle-language"
      >{{ $t("Movie.form.labels.dubbedLang") }}:</label
    >
    <div class="multiselect-wrapper">
      <multiselect
        v-model="movieDubbedLanguages"
        id="subtitle-language"
        label="language"
        track-by="id"
        :placeholder="$t('Movie.form.labels.search.typeToSearch')"
        open-direction="bottom"
        :options="dubbedLangMultiSelect.languageList"
        :multiple="true"
        :searchable="true"
        :loading="dubbedLangMultiSelect.isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        @search-change="searchLanguageList"
      >
        <template slot="tag" slot-scope="{ option, remove }"
          ><span class="custom__tag"
            ><span>{{ option.language }}</span
            ><span class="custom__remove" @click="remove(option)"
              ><svg-icon name="times" class="icon icon--small"/></span></span
        ></template>
        <span v-if="dubbedLangMultiSelect.languageList" slot="noResult">{{
          $t("Movie.form.errors.elementNotFound")
        }}</span>
      </multiselect>
      <p class="tip text-small">
        {{ $t("Movie.form.labels.search.liveSearch") }}
      </p>
    </div>
  </div>
</template>

<script>
import { getLanguageListBySearch } from "@/services/language.service";

export default {
  name: "DubbedLanguageMultiSelect",
  data() {
    return {
      dubbedLangMultiSelect: {
        languageList: this.languageList,
        isLoading: false
      }
    };
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    languageList: {
      type: Array,
      required: true
    }
  },
  computed: {
    movieDubbedLanguages: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  },
  methods: {
    async searchLanguageList(searchQuery) {
      this.dubbedLangMultiSelect.isLoading = true;
      this.dubbedLangMultiSelect.languageList = await getLanguageListBySearch(
        searchQuery
      );
      this.dubbedLangMultiSelect.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/layout/_multiselect.scss";
.multiselect-wrapper {
  width: 100%;
  .multiselect__tags-wrap {
    display: inline-block;
    .custom__tag {
      background-color: #eee;
      padding: 0.15rem;
      border-radius: 3px;
      font-size: 14px;
      margin-right: 0.5rem;
    }
    .custom__remove {
      position: relative;
      top: 3px;
      cursor: pointer;
    }
  }
  .multiselect__tags {
    border: 1px solid black;
    display: block;
    min-height: 25px;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    input {
      border: none;
      padding: 0;
    }
    .multiselect__input {
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
    .multiselect__placeholder {
      margin-bottom: 0;
    }
  }
}
</style>
