class Mandate {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.name = payload.name || null;
    this.homepage = payload.homepage || null;
    this.registrationLink = payload.registrationLink || null;
    this.logo = payload.logo || null;
    this.fontFamily = payload.fontFamily || null;
    this.institution = payload.institution || null;
    this.mandateLinks = payload.MandateLinks || null;
  }
}

export { Mandate };
