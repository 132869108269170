var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-item" },
    [
      _c("label", { attrs: { for: "address" } }, [
        _vm._v(_vm._s(_vm.$t("MemberProfile.form.labels.address")) + ": "),
        _c("span", { staticClass: "error" }, [_vm._v("*")])
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.memberProfileAddress,
            expression: "memberProfileAddress"
          }
        ],
        attrs: { type: "text", id: "address" },
        domProps: { value: _vm.memberProfileAddress },
        on: {
          input: function($event) {
            if ($event.target.composing) return
            _vm.memberProfileAddress = $event.target.value
          }
        }
      }),
      _c("MemberProfileShowAddress", {
        model: {
          value: _vm.memberProfileShowAddress,
          callback: function($$v) {
            _vm.memberProfileShowAddress = $$v
          },
          expression: "memberProfileShowAddress"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }