<template>
  <div class="form-item">
    <label for="synopsis-fr">{{ $t("Movie.form.labels.Synopsis") }}(fr):</label>
    <div class="form-item-input">
      <textarea
        class="synopsis"
        v-model="synopsisFr"
        id="synopsis-fr"
        name="synopsis"
        rows="7"
        maxlength="2000"
      />
      <div class="flex justify-between w-100">
        <p class="tip text-small">
          {{ $t("Movie.form.helpText.synopsisTip") }}
        </p>
        <div class="tip text-small">{{ countCharacters }}/2000</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovieSynopsisFR",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  computed: {
    synopsisFr: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    },
    countCharacters() {
      return this.synopsisFr.length;
    }
  }
};
</script>
