class MovieType {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.name = payload.name || "";
  }
}

class MovieFormat {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.name = payload.name || "";
  }
}

class MovieStyle {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.name = payload.name || "";
  }
}

export { MovieType, MovieFormat, MovieStyle };
