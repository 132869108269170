<template>
  <div>
    <div v-if="!isLoading">
      <h1 class="s-mrgb-05 s-mrgt-08">
        {{ $t("Company.form.edit.title") }}
      </h1>
      <CompanyEditForm
        @patchCompanyProfile="patchCompanyProfile"
        @cancel="cancel"
        :company-profile="company"
      />
    </div>
    <loader v-else></loader>
  </div>
</template>

<script>
import CompanyEditForm from "@/components/Company/Forms/CompanyEditForm";
import { patchCompanyProfile } from "@/services/Company.service";
import {
  getCompany,
  removeCompanyProfilePortrait,
  uploadCompanyProfilePortrait
} from "../../services/Company.service";
import loader from "../../components/loader";

export default {
  name: "CompanyEditView",
  components: {
    CompanyEditForm,
    loader
  },
  data() {
    return {
      isLoading: false,
      validationErrors: {}
    };
  },
  props: {
    company: null
  },
  computed: {},
  methods: {
    async patchCompanyProfile(company, portrait, portraitChanged) {
      await patchCompanyProfile(company.id, company)
        .then(() => {
          if (portraitChanged) {
            if (portrait) {
              uploadCompanyProfilePortrait(company.id, portrait)
                .then(() => {
                  this.goToProfile();
                })
                .catch(() => {
                  this.$toaster.success(
                    "Toaster.memberProfileEdit.media.failure"
                  );
                });
            } else {
              removeCompanyProfilePortrait(company.id)
                .then(() => {
                  this.goToProfile();
                })
                .catch(() => {
                  this.$toaster.success(
                    "Toaster.memberProfileEdit.media.failure"
                  );
                });
            }
          } else {
            this.goToProfile();
          }
        })
        .catch(error => {
          this.validationErrors = error.response.data;
          Object.values(this.validationErrors).forEach(errorGroup => {
            Object.values(errorGroup).forEach(error => {
              if (Object.values(error).length) {
                Object.values(error)[0].forEach(errorMessage =>
                  this.$toaster.error(errorMessage)
                );
              }
            });
          });
        });
    },
    goToProfile() {
      this.$router
        .push({
          name: "Company",
          params: { Cid: this.$route.params.Cid }
        })
        .then(() => {
          this.$toaster.success(
            this.$i18n.t("Toaster.memberProfileEdit.success")
          );
        });
    },
    cancel() {
      this.$router.push({
        name: "Company",
        params: { MpId: this.$route.params.Cid }
      });
    }
  },
  async mounted() {
    this.isLoading = true;
    this.company = await getCompany(this.$route.params.Cid);
    this.isLoading = false;
  }
};
</script>
