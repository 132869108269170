import { getFestivalList } from "@/services/festival.service";

export default {
  namespaced: true,
  state: {
    festivalList: [],
    mediaArtFestivalList: []
  },
  mutations: {
    updateFestivalList(state, data) {
      state.festivalList = data;
    }
  },
  actions: {
    async setFestivalList({ commit }) {
      const festivalList = await getFestivalList();
      commit("updateFestivalList", festivalList);
    }
  }
};
