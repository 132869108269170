<template>
  <div>
    <div class="movie-list">
      <div class="row">
        <div class="col-xs-12 col-t-md-4">
          <h1 class="s-mrgb-05">{{ $t("Movie.list.title") }}</h1>
        </div>
        <div class="col-xs-12 col-t-md-8"></div>

        <MovieFilters @setFilters="setFilters" />
        <div class="col-xs-12 col-t-md-8">
          <MoviesList
            v-if="!isLoading"
            :is-loading="isLoading"
            :movie-list="movies"
          />
          <loader v-else></loader>
          <Pagination
            v-if="
              (!isLoading && movies.length >= numOfMoviesPerPage) ||
                currentPage > 1
            "
            :current-page="currentPage"
            :page-count="pageCount"
            @setCurrentPage="setCurrentPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MoviesList from "@/components/movies/MoviesList.vue";
import { mapActions, mapState } from "vuex";
import MovieFilters from "@/components/movies/MovieListFilters/MovieFilters";
import Pagination from "../components/Pagination";
import { getPagedMoviesList } from "../services/getMoviesList.service";
import { Movie } from "../models/Movie.model";
import loader from "../components/loader";

export default {
  name: "MoviesListView",
  components: {
    MoviesList,
    MovieFilters,
    Pagination,
    loader
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      pageCount: 0,
      numOfMoviesPerPage: 0,
      filters: {}
    };
  },
  computed: {
    ...mapState("MoviesStore", ["moviesList"]),
    ...mapState("MandateStore", ["mandate"]),
    movies() {
      return this.moviesList.filter(movie => {
        return movie.mandates.some(mandate => mandate.id === this.mandate.id);
      });
    },
  },
  methods: {
    ...mapActions("MoviesStore", ["setPagedMovieList", "setMoviesListYears"]),

    setCurrentPage(page) {
      this.currentPage = page;
    },
    setFilters(filters) {
      this.filters = filters;
      this.setCurrentPage(1);
    }
  },
  asyncComputed: {
    async setMoviesList() {
      this.isLoading = true;
      const response = await getPagedMoviesList(
        this.currentPage,
        this.mandate,
        this.filters
      );
      this.pageCount = response.numPage;
      this.numOfMoviesPerPage = response.pageSize;
      const movies = response.results.map(movie => {
        return new Movie(movie);
      });
      await this.setMoviesListYears(response.years);
      await this.setPagedMovieList(movies);
      this.isLoading = false;
    }
  }
};
</script>
