import AuthStore from "./AuthStore";
import { getUserList } from "@/services/User.service";

export default {
  namespaced: true,
  state: {
    authUser: JSON.parse(localStorage.getItem("authUser")) || null,
    userList: []
  },
  mutations: {
    updateLocalStorage(state, data) {
      localStorage.setItem("authUser", JSON.stringify(data));
    },
    updateUserState(state, data) {
      state.authUser = data;
    },
    clearStorage() {
      localStorage.clear();
    },
    updateUserList(state, data) {
      state.userList = data;
    }
  },
  actions: {
    setLocalStorage({ commit }, data) {
      commit("updateLocalStorage", data);
    },
    setUserState({ commit }, data) {
      commit("updateUserState", data);
    },
    destroyUserStateAndStorage({ commit }) {
      commit("clearStorage");
      commit("updateUserState", null);
    },
    async setUserList({ commit }) {
      const userList = await getUserList();
      commit("updateUserList", userList);
    }
  },
  getters: {
    currentAuthUser(state) {
      return state.authUser;
    },
    hasMovieCreatePermission(state) {
      return !!(state.authUser && state.authUser.isMovieCreator);
    },
    hasMovieUpdatePermission: state => movieCreator => {
      if (!state.authUser || !AuthStore.state.isLoggedIn) {
        return false;
      }
      return state.authUser.id === movieCreator || state.authUser.isSuperuser;
    },
    isCompany(state) {
      return state.authUser?.companies?.length > 0;
    }
  }
};
