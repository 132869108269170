import { getLanguageList } from "@/services/language.service";

export default {
  namespaced: true,
  state: {
    languageList: []
  },
  mutations: {
    updateLanguagesList(state, data) {
      state.languageList = data;
    }
  },
  actions: {
    async setLanguageList({ commit }) {
      const languageList = await getLanguageList();
      commit("updateLanguagesList", languageList);
    }
  }
};
