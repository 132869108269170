<template>
  <div class="form-item">
    <label class="typo__label" for="MemberCreditFormat"
      >{{ $t("MemberProfile.form.credits.format") }}:
      <span class="error">*</span></label
    >
    <div class="multiselect-wrapper">
      <multiselect
        v-model="Format"
        id="MemberCreditFormat"
        track-by="id"
        label="name"
        placeholder=""
        open-direction="bottom"
        :options="formats"
        :multiple="false"
        :searchable="false"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="true"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
      >
        <template slot="tag" slot-scope="{ option, remove }"
          ><span class="custom__tag"
            ><span> {{ option.name }}</span
            ><span class="custom__remove" @click="remove(option)"
              ><svg-icon name="times" class="icon icon--small"/></span></span
        ></template>
        <span v-if="!formats" slot="noResult">{{
          $t("MemberProfile.form.helpTexts.elementNotFound")
        }}</span>
      </multiselect>
    </div>
  </div>
</template>

<script>
export default {
  name: "Format",
  props: {
    value: null,
    formats: null
  },
  computed: {
    Format: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

<style scoped></style>
