import { axiosBaseService, getAPI } from "./axiosBase.service";
import { Company } from "../models/Company.models";
import store from "../store";

const getCompany = async (payload, source = null) => {
  try {
    const response = await axiosBaseService.get(
      `/usersapi/company/${payload}/`,
      {
        params: {
          source: source
        }
      }
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    return new Company(response.data);
  } catch (e) {
    throw e;
  }
};

const getCompanies = async () => {
  try {
    const response = await axiosBaseService.get(`/usersapi/company/`);
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.map(company => {
      return new Company(company);
    });
  } catch (e) {
    throw e;
  }
};

const getCompanyProjects = async companyId => {
  try {
    const response = await axiosBaseService.get(
      `/usersapi/company/${companyId}/projects/`
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.projects;
  } catch (e) {
    throw e;
  }
};

const getFilteredCompanies = async (searchTerm, filters, mandate) => {
  try {
    const response = await axiosBaseService.get(
      `${store.state.locale}/usersapi/company/`,
      {
        params: {
          occupations: filters.map(filter => filter.id).join(","),
          search: searchTerm,
          mandates: mandate ? mandate.id : ""
        }
      }
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.map(company => {
      return new Company(company);
    });
  } catch (error) {
    throw error;
  }
};

const uploadCompanyProfilePortrait = async (id, payload) => {
  try {
    const response = await getAPI.patch(
      `${store.state.locale}/usersapi/company/${id}/upload_portrait/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const removeCompanyProfilePortrait = async (id) => {
  const response = await getAPI.delete(
    `${store.state.locale}/usersapi/company/${id}/delete_portrait/`
  );
  return response;
};

const patchCompanyProfile = async (id, payload) => {
  try {
    const response = await getAPI.put(
      `${store.state.locale}/usersapi/company/${id}/`,
      payload
    );
    if (response.status !== 200 && response.status !== 201) {
      return response.statusText;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  getCompany,
  getCompanies,
  patchCompanyProfile,
  uploadCompanyProfilePortrait,
  removeCompanyProfilePortrait,
  getFilteredCompanies,
  getCompanyProjects
};
