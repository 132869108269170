var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    _vm._l(_vm.values, function({ name, to }, i) {
      return _c(
        "span",
        { key: name },
        [
          to
            ? _c(
                "router-link",
                { attrs: { to: to } },
                [
                  _c("svg-icon", {
                    staticClass: "icon icon--small",
                    attrs: { name: "arrow-long-right" }
                  }),
                  _c("span", [_vm._v(_vm._s(name))]),
                  i < _vm.values.length - 1
                    ? _c("span", [_vm._v(", ")])
                    : _vm._e()
                ],
                1
              )
            : _c("span", [
                _vm._v(" " + _vm._s(name)),
                i < _vm.values.length - 1
                  ? _c("span", [_vm._v(", ")])
                  : _vm._e()
              ])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }