<template>
  <div class="form-item">
    <label for="screening-format"
      >{{ $t("Movie.form.labels.screeningFormat") }}:</label
    >
    <input type="text" v-model="movieScreeningFormat" id="screening-format" />
  </div>
</template>

<script>
export default {
  name: "MovieScreeningFormat",
  props: {
    value: {
      type: String,
      default: "",
      required: false
    }
  },
  computed: {
    movieScreeningFormat: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
