<template>
  <div class="form-item">
    <label for="additional-festivals"
      >{{ $t("Movie.form.labels.additionalFestivals") }}:</label
    >
    <textarea
      v-model="movieAdditionalFestivals"
      id="additional-festivals"
      name="additional-festivals"
      rows="2"
    />
    <p class="tip text-small">
      {{ $t("Movie.form.helpText.additionalFestivals") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "MovieAdditionalFestivals",
  props: {
    value: {
      type: String,
      default: "",
      required: false
    }
  },
  computed: {
    movieAdditionalFestivals: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
