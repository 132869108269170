var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.membersList, function(member, index) {
      return _c(
        "div",
        { key: `member--${index}`, staticClass: "row col-xs-12" },
        [
          _c("div", { staticClass: "col-t-md-8" }, [
            _c("div", [
              _c("div", { staticClass: "s-mrgb-06" }, [
                _c(
                  "h2",
                  {
                    staticClass: "h1",
                    on: {
                      mouseover: function($event) {
                        return _vm.setSelectedProfile(member)
                      },
                      mouseout: function($event) {
                        return _vm.setSelectedProfile(null)
                      }
                    }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "MemberProfileDetailView",
                            params: { MpId: member.id }
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.getFullName(member)) + " ")]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "row col-xs-12" })
              ])
            ])
          ]),
          _c("div", { staticClass: "col-t-md-4 portrait-container" }, [
            _c("div", { staticClass: "portrait" }, [
              _vm.selectedProfile && _vm.selectedProfile.id === member.id
                ? _c("p", [
                    _c("img", {
                      attrs: { src: member.portrait, alt: "" },
                      on: { error: _vm.usePlaceholder }
                    })
                  ])
                : _vm._e()
            ])
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }