<template>
  <div class="form-item">
    <label for="in-cinema">{{ $t("Movie.form.labels.inCinema") }}:</label>
    <div class="form-item-input flex--col">
      <input
        type="text"
        v-model="movieInCinema"
        id="in-cinema"
        pattern="https?://.+"
      />
      <p class="tip text-small">
        {{ $t("Movie.form.helpText.correctFormat") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovieInCinema",
  props: {
    value: {
      type: String,
      default: "",
      required: true
    }
  },
  computed: {
    movieInCinema: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
