<template>
  <div class="support">
    <h2>supported by:</h2>
    <div class="logo">
      <ul>
        <li v-for="logo in mandate.institution" :key="logo.website">
          <a :href="logo.website" target="_blank">
            <img
              class="institution__logo"
              :src="logo.institutionLogo"
              alt="Logo"
              title="Home"
            />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("MandateStore", ["mandate"])
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/base.scss";

.support {
  h2 {
    margin-top: 1em;
    font-size: 1em;
  }
  .logo {
    margin-bottom: 4em;
    ul {
      padding-left: 0;
      list-style-type: none;
      li {
        margin: 1.5em 0;
        margin-left: 0;
        width: 6em;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .support {
    h2 {
      margin-top: 0;
    }
    .logo {
      li {
        margin: 1em 0;
      }
    }
  }
}
</style>
