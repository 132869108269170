import {
  getOccupationGroups,
  getMediaArtGroup,
  getOccupations
} from "@/services/Occupation.service";

export default {
  namespaced: true,
  state: {
    groupList: [],
    occupationList: []
  },
  mutations: {
    updateGroupList(state, data) {
      state.groupList = data;
    },
    updateOccupationList(state, data) {
      state.occupationList = data;
    },
    updateGroupListForMediaArt(state, data) {
      state.groupList = [data];
    }
  },
  actions: {
    async setOccupationList({ commit }) {
      const occupationList = await getOccupations();
      commit("updateOccupationList", occupationList);
    },
    async setGroupList({ commit }) {
      const occupationGroupList = await getOccupationGroups();
      commit("updateGroupList", occupationGroupList);
    },
    async setMediaArtGroup({ commit }) {
      const mediaArtGroup = await getMediaArtGroup();
      commit("updateGroupListForMediaArt", mediaArtGroup);
    }
  }
};
