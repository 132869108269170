import { axiosBaseService } from "./axiosBase.service";
import { MovieType, MovieFormat, MovieStyle } from "@/models/Category.model";

const getMovieTypes = async isMovie => {
  try {
    const response = await axiosBaseService.get(`/moviesapi/movie-type/`, {
      params: {
        movie: isMovie
      }
    });
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.map(movieType => {
      return new MovieType(movieType);
    });
  } catch (e) {
    throw e;
  }
};

const getMovieFormats = async isMovie => {
  try {
    const response = await axiosBaseService.get(`/moviesapi/movie-format/`, {
      params: {
        movie: isMovie
      }
    });
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.map(movieFormat => {
      return new MovieFormat(movieFormat);
    });
  } catch (e) {
    throw e;
  }
};

const getMovieStyles = async isMovie => {
  try {
    const response = await axiosBaseService.get(`/moviesapi/movie-style/`, {
      params: {
        movie: isMovie
      }
    });
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.map(movieStyle => {
      return new MovieStyle(movieStyle);
    });
  } catch (e) {
    throw e;
  }
};

export { getMovieTypes, getMovieFormats, getMovieStyles };
