<template>
  <div class="form-item">
    <label for="synopsis-de"
      >{{ $t("Movie.form.labels.Synopsis") }}(de):
      <span class="error">*</span></label
    >
    <div class="form-item-input">
      <textarea
        class="synopsis"
        v-model="synopsis"
        id="synopsis-de"
        name="synopsis"
        rows="7"
        maxlength="2000"
      />
      <div class="flex justify-between w-100">
        <p class="tip text-small">
          {{ $t("Movie.form.helpText.synopsisTip") }}
        </p>
        <div class="tip text-small">{{ countCharacters }}/2000</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovieSynopsisDE",
  props: {
    value: {
      type: String,
      default: "",
      required: true
    }
  },
  computed: {
    synopsis: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    },
    countCharacters() {
      return this.synopsis.length;
    }
  }
};
</script>
