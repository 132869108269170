<template>
  <span>
    <span v-for="({ name, to }, i) in values" :key="name">
      <router-link v-if="to" :to="to">
        <svg-icon name="arrow-long-right" class="icon icon--small" />
        <span>{{ name }}</span>
        <span v-if="i < values.length - 1">, </span>
      </router-link>
      <span v-else>
        {{ name }}<span v-if="i < values.length - 1">, </span>
      </span>
    </span>
  </span>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    values: {
      type: Array,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped></style>
