var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _vm.row == 0
      ? _c("div", { staticClass: "col-xs-12 col-t-md-2" }, [
          _c("h3", { staticClass: "h1" }, [
            _vm._v(_vm._s(_vm.memberCredit.year))
          ])
        ])
      : _c("div", { staticClass: "col-xs-12 col-t-md-2" }, [
          _c("h3", { staticClass: "h1" })
        ]),
    _vm._m(0),
    _c("div", { staticClass: "col-xs-12 col-t-md-6 s-mrgb-10" }, [
      _c("h3", { staticClass: "h1 s-mrgb-05" }, [
        _vm._v(_vm._s(_vm.memberCredit.title))
      ]),
      _c("div", { staticClass: "row col-xs-12 align-start" }, [
        _c(
          "div",
          { staticClass: "flex align-baseline align-center" },
          [
            _c("svg-icon", {
              staticClass: "icon icon--small",
              attrs: { name: "regie_path" }
            })
          ],
          1
        ),
        _c("div", [
          _c("div", { staticClass: "s-mrgl-04" }, [
            _vm._v(" " + _vm._s(_vm.memberCredit.direction) + " ")
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "row col-xs-12 align-start s-mrgl-03 s-mrgt-03" },
        [
          _c("div", [
            _c("div", { staticClass: "s-mrgl-04" }, [
              _vm._v(" " + _vm._s(_vm.memberCredit.production) + " ")
            ]),
            _c("div", { staticClass: "s-mrgl-04 s-mrgt-04" }, [
              _vm._v(
                " Rolle: " +
                  _vm._s(_vm.memberCredit.credits || _vm.memberCredit.credit) +
                  " "
              )
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-xs-12 col-t-md-4 s-mrgb-10" }, [
      _c("div", { staticClass: "credit_poster" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }