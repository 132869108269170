import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
// import createPersistedState from "vuex-persistedstate";
import i18n, { selectedLocale } from "../plugins/i18n";

import AuthStore from "./modules/AuthStore";
import MoviesStore from "./modules/MoviesStore";
import PasswordStore from "./modules/PasswordStore";
import LanguageStore from "./modules/LanguageStore";
import LinkStore from "./modules/LinkStore";
import SupporterStore from "./modules/SupporterStore";
import UserStore from "./modules/UserStore";
import CategoryStore from "./modules/Category.store";
import MandateStore from "./modules/Mandate.store";
import OccupationStore from "./modules/Occupation.store";
import MemberProfileStore from "./modules/MemberProfileStore";
import FestivalStore from "./modules/Festival.store";
import CompanyStore from "./modules/CompanyStore";
import Adding from "./modules/Adding.js";

Vue.use(Vuex, axios);

export default new Vuex.Store({
  state: {
    locale: selectedLocale
  },
  mutations: {
    updateLocale(state, newLocale) {
      state.locale = newLocale;
    }
  },
  actions: {
    changeLocale({ commit }, newLocale) {
      i18n.locale = newLocale;
      commit("updateLocale", newLocale);
    }
  },
  // plugins: [createPersistedState()],
  modules: {
    AuthStore,
    MoviesStore,
    PasswordStore,
    LanguageStore,
    LinkStore,
    SupporterStore,
    UserStore,
    CategoryStore,
    MandateStore,
    OccupationStore,
    MemberProfileStore,
    FestivalStore,
    Adding,
    CompanyStore
  }
});
