class Link {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.title = payload.title || null;
    this.url = payload.url || null;
  }
}

export { Link };
