export default {
  namespaced: true,
  state: {
    addingList: []
  },
  getters: {
    adding(state) {
      return state.addingList.length !== 0;
    }
  },
  mutations: {
    addAddingState(state) {
      state.addingList.push("adding");
    },
    removeAddingState(state) {
      state.addingList.pop();
    }
  }
};
