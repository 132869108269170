import { axiosBaseService } from "./axiosBase.service";
import { Mandate } from "../models/Mandate.model";

const getMandate = async query => {
  try {
    const response = await axiosBaseService.get(
      `/moviesapi/mandates/?search=${query}`
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.results.map(mandate => {
      return new Mandate(mandate);
    });
  } catch (e) {
    throw e;
  }
};

export { getMandate };
