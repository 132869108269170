<template>
  <div class="form-item-input flex--row s-mrgt-03 align-center">
    <label for="showAddress">{{
      $t("MemberProfile.form.labels.showAddress")
    }}</label>
    <input
      id="showAddress"
      type="checkbox"
      v-model="memberProfileShowAddress"
    />
  </div>
</template>

<script>
export default {
  name: "MemberProfileShowAddress",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    memberProfileShowAddress: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-item-input {
  label {
    width: 30%;
  }
  input {
    width: initial;
  }
}
</style>
