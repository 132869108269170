var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", { attrs: { for: "additional-festivals" } }, [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.additionalFestivals")) + ":")
    ]),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.movieAdditionalFestivals,
          expression: "movieAdditionalFestivals"
        }
      ],
      attrs: {
        id: "additional-festivals",
        name: "additional-festivals",
        rows: "2"
      },
      domProps: { value: _vm.movieAdditionalFestivals },
      on: {
        input: function($event) {
          if ($event.target.composing) return
          _vm.movieAdditionalFestivals = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("p", { staticClass: "tip text-small" }, [
      _vm._v(
        " " + _vm._s(_vm.$t("Movie.form.helpText.additionalFestivals")) + " "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }