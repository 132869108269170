import { getMemberProfileDetail } from "@/services/memberProfile.service";
import { MemberProfile } from "@/models/MemberProfile.models";

export default {
  namespaced: true,
  state: {
    memberProfile: null,
    memberProfileList: []
  },
  mutations: {
    updateMemberProfile(state, data) {
      state.memberProfile = data;
    },
    updateMemberProfileList(state, data) {
      state.memberProfileList = data;
    }
  },
  actions: {
    async setMemberProfileDetail({ commit }, id) {
      const memberProfile = await getMemberProfileDetail(id);
      commit("updateMemberProfile", memberProfile);
    },
    setMemberProfile({ commit }, memberProfile) {
      commit("updateMemberProfile", memberProfile);
    },
    destroyMemberProfile({ commit }) {
      commit("updateMemberProfile", null);
    }
  },
  getters: {
    getMemberProfileForUser: state => userId => {
      let userMemberProfile = null;
      if (state.memberProfileList) {
        userMemberProfile = state.memberProfileList.find(
          memberProfile => memberProfile.user.id === userId
        );
      }
      if (userMemberProfile) {
        return userMemberProfile;
      }
      return new MemberProfile({});
    }
  }
};
