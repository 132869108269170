<template>
  <div>
    <h2 class="s-mrgb-05">
      {{ $t("MemberProfile.form.labels.memberOccupations") }}
    </h2>
    <div class="form-item">
      <div class="multiselect-wrapper">
        <multiselect
          v-model="memberOccupations"
          :custom-label="getOccupation"
          track-by="occupation"
          :placeholder="
            $t('MemberProfile.form.memberOccupations.chooseOccupation')
          "
          open-direction="bottom"
          :options="memberOccupationsList"
          :multiple="true"
          :taggable="true"
          :clear-on-select="false"
          :close-on-select="false"
          :max-height="600"
          :show-no-results="false"
          :hide-selected="true"
          :max="7"
          @close="updateListSortOrder"
          group-values="occupations"
          group-label="title"
          :group-select="false"
          :tag-placeholder="$t('Movie.form.labels.creditCreation.placeholder')"
          @remove="remove"
        >
          <template slot="tag" slot-scope="{ option, remove }"
            ><span class="custom__tag"
              ><span>{{ option.occupation.description }}</span
              ><span class="custom__remove" @click="remove(option)"
                ><svg-icon name="times" class="icon icon--small"/></span></span
          ></template>
          <template slot="maxElements">
            <span class="multiselect__content">
              <span>{{
                $t("MemberProfile.form.memberOccupations.maxElements")
              }}</span>
            </span>
          </template>
        </multiselect>
        <p class="tip text-small">
          {{ $t("MemberProfile.form.memberOccupations.helpText") }}
        </p>
      </div>
    </div>
    <div>
      <p class="text-bold">
        {{ $t("MemberProfile.form.memberOccupations.title") }}
      </p>
      <p class="text-small">
        {{ $t("MemberProfile.form.memberOccupations.tip") }}
      </p>
      <div class="flex flex--column around-md col-t-md-6 s-pdg-00">
        <table class="table s-mrgt-06 s-mrgb-08">
          <thead class="thead-dark text-left">
            <tr>
              <th>
                {{ $t("MemberProfile.form.memberOccupations.table.sort") }}
              </th>
              <th>
                {{
                  $t("MemberProfile.form.memberOccupations.table.occupation")
                }}
              </th>
              <th>
                {{ $t("MemberProfile.form.memberOccupations.table.unlocked") }}
              </th>
              <th>
                {{ $t("MemberProfile.form.memberOccupations.table.delete") }}
              </th>
            </tr>
          </thead>
          <draggable
            v-model="memberOccupations"
            tag="tbody"
            @change="updateListSortOrder"
            class="drag"
          >
            <tr v-for="(credit, index) in memberOccupations" :key="index">
              <td>
                <svg-icon name="arrow-move" class="icon icon--small" />
              </td>
              <td>{{ credit.occupation.description }}</td>
              <td v-if="credit.isUnlocked">
                <svg-icon name="yes-symbol" class="icon icon--small" />
              </td>
              <td v-else>
                <svg-icon name="times" class="icon icon--small" />
              </td>
              <td @click="deleteCredit(index)">
                <svg-icon name="times" class="icon icon--small" />
              </td>
            </tr>
          </draggable>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { getOccupations } from "@/services/Occupation.service";
import { MemberOccupation } from "../../../../models/MemberOccupation";
import groupBy from "lodash/groupBy";

export default {
  name: "MemberProfileOccupation",
  components: {
    draggable
  },
  data() {
    return {
      occupationList: [],
      movieCredits: [],
      memberOccupationsList: [],
      shortMovies: []
    };
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    memberCredits: {
      type: Array,
      required: true
    }
  },
  computed: {
    memberOccupations: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  },
  methods: {
    updateListSortOrder() {
      this.memberOccupations = [...this.memberOccupations].map(
        (item, index) => {
          let newSort = index;
          item.hasChanged = item.order !== newSort;
          if (item.hasChanged) {
            item.order = newSort;
            newSort = index + 1;
          }
          return item;
        }
      );
    },
    getOccupation(option) {
      return `${option.occupation.description}`;
    },
    deleteCredit(index) {
      this.memberOccupations.splice(index, 1);
    },
    setGroupedOccupations() {
      this.memberOccupationsList = [
        {
          title: this.$i18n.t(
            "MemberProfile.form.memberOccupations.suggestedOccupations"
          ),
          occupations: this.movieCredits
        },
        {
          title: this.$i18n.t(
            "MemberProfile.form.memberOccupations.otherOccupations"
          ),
          occupations: this.occupationList
        }
      ];
    },
    setShortMoviesList() {
      this.memberCredits.forEach(movie => {
        movie.movieCredits.forEach(credit => {
          if (
            movie.movieFormat?.name === "Kurzfilm bis 59 Minuten" &&
            credit.user?.memberProfile === this.$route.params.MpId
          ) {
            this.shortMovies.push(credit);
          }
        });
      });
      this.shortMovies = groupBy(this.shortMovies, "occupation.id");
    },
    checkIfOccupationInList() {
      this.removeDuplicatedCredits();
      this.memberOccupations.forEach(occupation => {
        this.movieCredits.forEach((credit, index) => {
          if (occupation.occupation.id === credit.occupation.id) {
            this.movieCredits.splice(index, 1);
          }
        });
        this.occupationList.forEach((credit, index) => {
          if (occupation.occupation.id === credit.occupation.id) {
            this.occupationList.splice(index, 1);
          }
        });
      });
      this.occupationList.forEach((occupation, index) => {
        this.movieCredits.forEach(credit => {
          if (occupation.occupation.id === credit.occupation.id) {
            this.occupationList.splice(index, 1);
          }
        });
      });
    },
    remove(removedOption) {
      let credits = [];
      if (removedOption.id) {
        this.memberCredits.forEach(movie => {
          movie.movieCredits.forEach(credit => {
            if (credit.user?.memberProfile === this.$route.params.MpId) {
              credits.push(credit);
            }
          });
        });
        if (
          credits.some(
            occupation =>
              occupation.occupation.id === removedOption.occupation.id
          )
        ) {
          removedOption.id = null;
          this.movieCredits.unshift(removedOption);
        } else {
          removedOption.id = null;
          this.occupationList.unshift(removedOption);
        }
      }
    },
    removeDuplicatedCredits() {
      const occupations = [];
      this.movieCredits.forEach(occupation => {
        if (
          !occupations.some(
            occ => occ.occupation.id === occupation.occupation.id
          )
        ) {
          occupations.push(occupation);
        }
      });
      this.movieCredits = occupations;
    }
  },
  async mounted() {
    this.setShortMoviesList();
    this.memberCredits.forEach(movie => {
      movie.movieCredits.forEach(credit => {
        if (credit.user?.memberProfile === this.$route.params.MpId) {
          if (
            credit.occupation.isAutomaticallyUnlocked ||
            movie.movieFormat?.name !== "Kurzfilm bis 59 Minuten"
          ) {
            this.movieCredits.push(
              new MemberOccupation({
                occupation: credit.occupation,
                isUnlocked: true,
                memberProfile: null
              })
            );
          } else {
            if (
              movie.movieFormat?.name === "Kurzfilm bis 59 Minuten" &&
              this.shortMovies[credit.occupation.id].length >= 5
            ) {
              this.movieCredits.push(
                new MemberOccupation({
                  occupation: credit.occupation,
                  isUnlocked: true,
                  memberProfile: null
                })
              );
            } else {
              this.movieCredits.push(
                new MemberOccupation({
                  occupation: credit.occupation,
                  isUnlocked: false,
                  memberProfile: null
                })
              );
            }
          }
        }
      });
    });
    const occupations = await getOccupations();
    occupations.forEach(occupation => {
      if (!occupation.isService && !occupation.isCreditOnly) {
        this.occupationList.push(
          new MemberOccupation({
            occupation: occupation,
            isUnlocked: false,
            memberProfile: null
          })
        );
      }
    });
    this.checkIfOccupationInList();
    this.setGroupedOccupations();
  }
};
</script>

<style lang="scss" scoped>
.drag {
  cursor: pointer;
}
</style>
