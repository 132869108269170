var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "s-mrgb-06" },
    [
      _c("h2", { staticClass: "h1 s-mrgr-02" }, [
        _vm._v(_vm._s(_vm.$t("Movie.detail.credits.title")))
      ]),
      _vm._l(_vm.occupationGroups, function(group) {
        return _c(
          "div",
          { key: group.groupName },
          _vm._l(group.credits, function(credit, key) {
            return _c("div", { key: key, staticClass: "row s-mrgb-04" }, [
              _c("div", { staticClass: "s-mrgr-02 col-xs-12 col-t-md-4" }, [
                _c("p", [_vm._v(_vm._s(key))])
              ]),
              _c(
                "div",
                { staticClass: "s-mrgr-02 col-xs-12 col-t-md-6" },
                _vm._l(credit, function(occupation) {
                  return _c(
                    "p",
                    { key: occupation.name },
                    [
                      occupation.to
                        ? _c(
                            "router-link",
                            { attrs: { to: occupation.to } },
                            [
                              _c("svg-icon", {
                                staticClass: "icon icon--small",
                                attrs: { name: "arrow-long-right" }
                              }),
                              _vm._v(" " + _vm._s(occupation.name) + " ")
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(occupation.name))])
                    ],
                    1
                  )
                }),
                0
              )
            ])
          }),
          0
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }