import axios from "axios";
import store from "../store/index.js";
import router from "../router";
const APIUrl = process.env.VUE_APP_API_URL + "/";

const CSRF_COOKIE_NAME = "csrftoken";
const CSRF_HEADER_NAME = "X-CSRFToken";

/**
 * Used in user registeration
 */
const axiosBaseService = axios.create({
  baseURL: APIUrl,
  headers: {
    contentType: "application/json"
  },
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
  withCredentials: true
});

/**
 * Used when user is already authenticated,
 * in order to intercept the request/response
 */
const getAPI = axios.create({
  baseURL: APIUrl,
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
  withCredentials: true
});

/**
 * Intercepts the request
 * Checks the existence of the access token from AuthStore
 * If it exists, smuggle it inside the config.
 */
getAPI.interceptors.request.use(config => {
  try {
    if (store.state.AuthStore.accessToken) {
      config.headers[
        "Authorization"
      ] = `Bearer ${store.state.AuthStore.accessToken}`;
    }
    return config;
  } catch (err) {
    throw err;
  }
});

async function getRefreshtoken(token) {
  const response = await getAPI.post("de/usersapi/token/refresh/", {
    refresh: token
  });

  return response.data;
}

/**
 * Intercepts the response
 * If its is unauthorized then refresh the token
 * Then create a new request passing the new access token with it
 * When done, return the data fetched.
 * @param {response} undefined- is the response itself
 */
getAPI.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Return any error which is not due to authentication back to the calling service
    if (
      error.response.status !== 401 &&
      !error.response.config.url.includes("token/refresh")
    ) {
      return Promise.reject(error);
    }
    // Logout user if token refresh didn't work or user is disabled
    if (error.response.config.url.includes("token/refresh")) {
      store.dispatch("UserStore/destroyUserStateAndStorage").then(() => {
        store.dispatch("AuthStore/destroyTokenStateAndStorage");
        router.push({ name: "Login" });
      });
      return Promise.reject(error);
    }
    // Try request again with new token
    return getRefreshtoken(store.state.AuthStore.refreshToken)
      .then(token => {
        // set token in store
        store.dispatch("AuthStore/submitRefreshToken", token);
        // New request with new token
        const config = error.response.config;
        config.headers["Authorization"] = `Bearer ${token.access}`;
        return axios.request(config);
      })
      .catch(error => {
        Promise.reject(error);
      });
  }
);

export { axiosBaseService, getAPI };
