var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "movie-list" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-xs-12 col-t-md-4" }, [
            _c("h1", { staticClass: "s-mrgb-05" }, [
              _vm._v(_vm._s(_vm.$t("Movie.list.title")))
            ])
          ]),
          _c("div", { staticClass: "col-xs-12 col-t-md-8" }),
          _c("MovieFilters", { on: { setFilters: _vm.setFilters } }),
          _c(
            "div",
            { staticClass: "col-xs-12 col-t-md-8" },
            [
              !_vm.isLoading
                ? _c("MoviesList", {
                    attrs: {
                      "is-loading": _vm.isLoading,
                      "movie-list": _vm.movies
                    }
                  })
                : _c("loader"),
              (!_vm.isLoading && _vm.movies.length >= _vm.numOfMoviesPerPage) ||
              _vm.currentPage > 1
                ? _c("Pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "page-count": _vm.pageCount
                    },
                    on: { setCurrentPage: _vm.setCurrentPage }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }