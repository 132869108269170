export default {
  namespaced: true,
  state: {
    mandate: null
  },
  mutations: {
    updateMandate(state, data) {
      state.mandate = data;
    }
  },
  actions: {
    async setMandate({ commit }, mandate) {
      commit("updateMandate", mandate);
    }
  }
};
