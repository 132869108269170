<template>
  <div class="form-item">
    <label for="title-de"
      >{{ $t("Movie.form.labels.title") }}(de):
      <span class="error">*</span></label
    >
    <input type="text" v-model="title" id="title-de" />
  </div>
</template>

<script>
export default {
  name: "MovieTitle",
  props: {
    value: {
      type: String,
      default: "",
      required: true
    }
  },
  computed: {
    title: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  }
};
</script>
