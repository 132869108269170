import { axiosBaseService } from "./axiosBase.service";
import store from "../store/index.js";
import { Movie } from "../models/Movie.model";

/**
 * Gets a movie detail
 */

const getMovieDetail = async movieId => {
  try {
    const url = `/${store.state.locale}/moviesapi/movies/${movieId}/`;
    const response = await axiosBaseService.get(url, {
      params: { id: movieId }
    });
    if (response.status !== 200) {
      return response.statusText;
    }
    return new Movie(response.data);
  } catch (error) {
    throw error;
  }
};

export { getMovieDetail };
