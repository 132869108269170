<template>
  <div>
    <div v-if="!isLoading">
      <h1 class="s-mrgb-05 s-mrgt-08">
        {{ $t("MemberProfile.form.edit.title") }}
      </h1>
      <MemberProfileEditForm
        @patchMemberProfile="patchMemberProfile"
        @cancel="cancel"
        :member-profile="memberProfile"
      />
    </div>
    <div v-else>{{ $t("MemberProfile.form.helpTexts.fetchingData") }}</div>
  </div>
</template>

<script>
import MemberProfileEditForm from "@/components/MemberProfiles/Forms/MemberProfileEditForm";
import { mapActions, mapState } from "vuex";
import { patchMemberProfile } from "@/services/memberProfile.service";
import {
  uploadMemberProfileMedia,
  removeMemberProfilePortrait
} from "@/services/memberProfile.service";

export default {
  name: "MemberProfileEditView",
  components: {
    MemberProfileEditForm
  },
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    ...mapState("MemberProfileStore", ["memberProfile"])
  },
  methods: {
    ...mapActions("MemberProfileStore", ["setMemberProfileDetail"]),
    async patchMemberProfile(memberProfile, portrait, portraitChanged, pdf) {
      // Only send Id of user for safety reasons
      memberProfile.user = memberProfile.user.id;
      await patchMemberProfile(memberProfile.id, memberProfile)
        .then(async () => {
          if (portraitChanged && !portrait) {
            await removeMemberProfilePortrait(memberProfile.id);
          }

          if (portrait) {
            portrait.append("pdf", pdf);
            uploadMemberProfileMedia(memberProfile.id, portrait)
              .then(() => {
                this.$router
                  .push({
                    name: "MemberProfileDetailView",
                    params: { MpId: this.$route.params.MpId }
                  })
                  .then(() => {
                    this.$toaster.success(
                      this.$i18n.t("Toaster.memberProfileEdit.success")
                    );
                  });
              })
              .catch(() => {
                this.$toaster.success(
                  "Toaster.memberProfileEdit.media.failure"
                );
              });
          } else if (pdf) {
            const fd = new FormData();
            fd.append("pdf", pdf);
            uploadMemberProfileMedia(memberProfile.id, fd)
              .then(() => {
                this.$router
                  .push({
                    name: "MemberProfileDetailView",
                    params: { MpId: this.$route.params.MpId }
                  })
                  .then(() => {
                    this.$toaster.success(
                      this.$i18n.t("Toaster.memberProfileEdit.success")
                    );
                  });
              })
              .catch(() => {
                this.$toaster.success(
                  "Toaster.memberProfileEdit.media.failure"
                );
              });
          } else {
            this.$router
              .push({
                name: "MemberProfileDetailView",
                params: { MpId: this.$route.params.MpId }
              })
              .then(() => {
                this.$toaster.success(
                  this.$i18n.t("Toaster.memberProfileEdit.success")
                );
              });
          }
        })
        .catch(() => {
          this.$toaster.error(
            this.$i18n.t("Toaster.memberProfileEdit.failure")
          );
        });
    },
    cancel() {
      this.$router.push({
        name: "MemberProfileDetailView",
        params: { MpId: this.$route.params.MpId }
      });
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.setMemberProfileDetail(this.$route.params.MpId);
    this.isLoading = false;
  }
};
</script>
