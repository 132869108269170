<template>
  <div>
    <div class="form-item" v-for="(group, key) in groupList" :key="key">
      <h2 class="h2 text-normal">{{ group.name }}</h2>
      <div
        v-for="(formCredit, creditIndex) in getCreditByGroup(group.id)"
        :key="formCredit.id"
        class="form-item-input"
      >
        <p v-if="isCreditEditable" class="s-mrgt-03 s-mrgb-03 error">
          {{ $t("Movie.form.labels.creditCreation.error") }}
        </p>
        <p v-if="!isCreditValid(formCredit)" class="error">
          {{ $t("Movie.form.helpText.creditError") }}
        </p>
        <div class="s-mrgb-04">
          <label>{{
            $t("Movie.form.labels.creditCreation.labels.occupations")
          }}</label>
          <select v-model="formCredit.occupation">
            <option
              disabled
              :value="$t('Movie.form.labels.creditCreation.select')"
            >
              {{ $t("Movie.form.labels.creditCreation.select") }}
            </option>
            <option
              v-for="(credit, key) in getOccupationByGroup(group.id)"
              :key="key"
              :value="credit"
              :disabled="isCreditEditable"
              >{{ credit.credit }}</option
            >
          </select>
        </div>
        <div class="s-mrgb-04 form-item">
          <label for="creditUser">{{
            $t("Movie.form.labels.creditCreation.labels.users")
          }}</label>
          <div class="multiselect-wrapper">
            <!-- Users multiselect -->
            <multiselect
              v-if="!isServiceOccupation(formCredit)"
              v-model="formCredit.user"
              id="creditUser"
              label="fullName"
              track-by="id"
              :placeholder="$t('Movie.form.labels.creditCreation.placeholder')"
              open-direction="bottom"
              :options="users"
              :clear-on-select="false"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :taggable="true"
              :tag-placeholder="
                $t('Movie.form.labels.creditCreation.placeholder')
              "
              @tag="addUnknownUser($event, formCredit)"
              :deselect-label="$t('Movie.form.labels.creditCreation.create')"
              :multiple="isMediaMovieType(formCredit)"
              :max="10"
              :disabled="!isOccupationSelected(formCredit)"
            >
              <template slot="tag" slot-scope="{ option, remove }"
                ><span class="custom__tag"
                  ><span>{{ option.fullName }}</span
                  ><span class="custom__remove" @click="remove(option)"
                    ><svg-icon
                      name="times"
                      class="icon icon--small"/></span></span
              ></template>
            </multiselect>

            <!-- Company multiselect -->
            <multiselect
              v-else
              v-model="formCredit.company"
              id="creditCopmany"
              label="name"
              track-by="id"
              :placeholder="$t('Movie.form.labels.creditCreation.placeholder')"
              open-direction="bottom"
              :options="companies"
              :clear-on-select="false"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :taggable="true"
              :tag-placeholder="
                $t('Movie.form.labels.creditCreation.placeholder')
              "
              @tag="addUnknownCompany($event, formCredit)"
              :deselect-label="$t('Movie.form.labels.creditCreation.create')"
              :multiple="false"
              :max="10"
              :disabled="!isOccupationSelected(formCredit)"
            >
              <template slot="tag" slot-scope="{ option, remove }"
                ><span class="custom__tag"
                  ><span>{{ option.name }}</span
                  ><span class="custom__remove" @click="remove(option)"
                    ><svg-icon
                      name="times"
                      class="icon icon--small"/></span></span
              ></template>
            </multiselect>
          </div>
        </div>
        <button
          v-if="movieCredits.length"
          class="button button--delete s-mrgb-06"
          type="button"
          @click="removeCredit(creditIndex, group.id)"
        >
          {{ $t("Movie.form.labels.creditCreation.buttons.delete") }}
        </button>
      </div>
      <button class="button" @click="addCredit(group.id)" type="button">
        {{ $t("Movie.form.labels.creditCreation.buttons.add") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Credit } from "@/models/Credit.model";
import { User } from "@/models/User.model";
import { Company } from "@/models/Company.models";
import {
  movieTypeAnimation,
  movieTypeDocumentation,
  movieTypeMediaArt,
  movieTypeShortMovie
} from "@/globals/variables";

export default {
  name: "MovieCredits",
  props: {
    value: {
      type: Array,
      required: true
    },
    movieType: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    companies: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    }
  },
  data() {
    return {
      users: []
    };
  },
  computed: {
    ...mapState("OccupationStore", ["groupList", "occupationList"]),
    ...mapState("UserStore", ["userList"]),
    movieCredits: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    },
    isCreditEditable() {
      // If movie format and type are not set
      if (this.form.movieType.id && this.form.movieFormat?.id) {
        return false;
      }
      return this.form.movieType.name !== movieTypeMediaArt;
    }
  },
  methods: {
    isCreditValid(credit) {
      if (this.isCreditEditable) {
        return true;
      }
      return !(
        !credit.occupation.credit ||
        (credit.user.id === undefined && credit.company.id === undefined)
      );
    },
    isServiceOccupation(credit) {
      return !!credit.occupation.isService;
    },
    isOccupationSelected(credit) {
      if (this.isCreditEditable) return false;
      if (credit.occupation.id) return true;
      return false;
    },
    addUnknownUser(newUser, id) {
      let fullName = newUser.split(" ");
      const [firstName, ...names] = fullName;
      const lastName = names.join(" ");

      this.movieCredits.find(credit => credit === id).user = new User({
        firstName,
        lastName,
        fullName: newUser,
        isStaff: false
      });
    },
    addUnknownCompany(newCompany, id) {
      this.movieCredits.find(credit => credit === id).company = new Company({
        name: newCompany
      });
    },
    addCredit(groupID) {
      this.movieCredits.push(
        new Credit({
          occupation: {
            group: {
              id: groupID
            }
          },
          user: null
        })
      );
    },
    removeCredit(creditIndex, groupId) {
      let groupCredits = this.movieCredits.filter(
        credit => credit.occupation.group.id === groupId
      );
      let index = this.movieCredits.indexOf(groupCredits[creditIndex]);
      this.movieCredits.splice(index, 1);
    },
    getCreditByGroup(groupID) {
      return this.movieCredits.filter(
        credit => credit.occupation.group.id === groupID
      );
    },
    getOccupationByGroup(groupID) {
      const occupations = this.occupationList;
      const category = this.checkOccupationRules();
      const filteredOccupations = [];
      occupations.forEach(occupation => {
        if (
          occupation.movieType ||
          occupation.movieStyle ||
          occupation.movieFormat
        ) {
          if (occupation.movieType && occupation.movieType.name === category) {
            filteredOccupations.push(occupation);
          }
          if (
            occupation.movieStyle &&
            occupation.movieStyle.name === category
          ) {
            filteredOccupations.push(occupation);
          }
          if (
            occupation.movieFormat &&
            occupation.movieFormat.name === category
          ) {
            filteredOccupations.push(occupation);
          }
        } else {
          filteredOccupations.push(occupation);
        }
      });
      return filteredOccupations.filter(
        occupation => occupation.group.id === groupID
      );
    },
    isMediaMovieType(credit) {
      if (credit.occupation.credit === "Weitere Beteiligte") {
        return true;
      } else if (credit.user.length > 1) {
        credit.user = [];
        return false;
      }
      return false;
    },
    filterUserList() {
      this.userList.forEach(user => {
        if (user.isMember) this.users.push(user);
      });
    },
    checkOccupationRules() {
      const styles = [];
      this.form.styles.forEach(style => {
        styles.push(style.name);
      });
      if (
        this.form.movieType.name === movieTypeDocumentation &&
        styles.includes(movieTypeAnimation)
      ) {
        return this.form.movieType.name;
      }
      if (
        this.form.movieType.name !== movieTypeDocumentation &&
        styles.includes(movieTypeAnimation)
      ) {
        return movieTypeAnimation;
      }
      if (
        this.form.movieFormat?.name === movieTypeShortMovie &&
        !styles.includes(movieTypeAnimation)
      ) {
        return movieTypeShortMovie;
      }
      if (
        this.form.movieFormat?.name !== movieTypeShortMovie &&
        !styles.includes(movieTypeAnimation)
      ) {
        return this.form.movieType.name;
      }
      if (this.form.movieType.name === movieTypeMediaArt) {
        this.form.movieType.name;
      }
    }
  },
  async mounted() {
    this.filterUserList();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/layout/_multiselect.scss";
.multiselect-wrapper {
  width: 100%;
  .multiselect__tags-wrap {
    display: inline-block;
    .custom__tag {
      background-color: #eee;
      padding: 0.15rem;
      border-radius: 3px;
      font-size: 14px;
      margin-right: 0.5rem;
    }
    .custom__remove {
      position: relative;
      top: 3px;
      cursor: pointer;
    }
  }
  .multiselect__tags {
    border: 1px solid black;
    display: block;
    min-height: 25px;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    input {
      border: none;
      padding: 0;
    }
    .multiselect__input {
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
    .multiselect__placeholder {
      margin-bottom: 0;
    }
  }
}
</style>
