<template>
  <div class="loader_wrapper">
    <div class="loader">
      <div class="loader__filmstrip"></div>
      <div>
        <p class="loader__text">
          {{ $t("Loader.loading") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loader"
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css?family=Anton");
$color-primary: #000;
$color-secondary: #fff;
$font: Anton, sans-serif;

.loader_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 200px;
  background: $color-secondary;
}
.loader {
  z-index: 0;
  &__text {
    margin: 5px auto;
    color: $color-primary;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: $font;
    text-align: center;
  }
  &__filmstrip {
    position: relative;
    width: 56px;
    height: 70px;
    margin: 0 auto;
    background: $color-primary;
    z-index: -1;
    &:before,
    &:after {
      content: "";
      position: absolute;
      height: 120%;
      border-left: 5px dashed $color-secondary;
      animation: roll 18ms infinite;
    }
    &:before {
      left: 5px;
    }
    &:after {
      right: 5px;
    }
  }
}

@keyframes roll {
  0% {
    top: 0;
  }
  25% {
    top: -4px;
  }
  50% {
    top: -7.5px;
  }
  74% {
    top: -12px;
  }
  100% {
    top: -15px;
  }
}
</style>
