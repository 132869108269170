var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", { attrs: { for: "awards" } }, [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.awards")) + ":")
    ]),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.movieAwards,
          expression: "movieAwards"
        }
      ],
      attrs: { id: "awards", name: "awards", rows: "2" },
      domProps: { value: _vm.movieAwards },
      on: {
        input: function($event) {
          if ($event.target.composing) return
          _vm.movieAwards = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("p", { staticClass: "tip text-small" }, [
      _vm._v(" " + _vm._s(_vm.$t("Movie.form.helpText.awards")) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }