<template>
  <ul class="links s-mrgb-06">
    <li v-if="hasCredits">
      <a href="#allCredits">
        <svg-icon name="long-arrow-down" class="icon icon--small" />{{
          $t("Movie.detail.credits.allCredits")
        }}</a
      >
    </li>
    <li v-if="hasFestivals">
      <a href="#festivals"
        ><svg-icon name="long-arrow-down" class="icon icon--small" />{{
          $t("Movie.detail.festivals")
        }}</a
      >
    </li>
    <li v-if="hasAwards">
      <a href="#awards"
        ><svg-icon name="long-arrow-down" class="icon icon--small" />{{
          $t("Movie.detail.awards")
        }}</a
      >
    </li>
    <li v-if="hasInfo">
      <a href="#furtherInfo"
        ><svg-icon name="long-arrow-down" class="icon icon--small" />{{
          $t("Movie.detail.credits.furtherInfo")
        }}</a
      >
    </li>
  </ul>
</template>

<script>
import Vue from "vue";
import SvgIcon from "../../SvgIcon.vue";

export default Vue.extend({
  components: { SvgIcon },
  name: "PageNavigation",
  props: {
    hasCredits: { type: Boolean, required: true },
    hasAwards: { type: Boolean, required: true },
    hasFestivals: { type: Boolean, required: true },
    hasInfo: { type: Boolean, required: true }
  }
});
</script>

<style lang="scss" scoped>
.links {
  list-style: none;
  padding: 0;

  li:first-child {
    margin-bottom: 1rem;
  }
}
</style>
