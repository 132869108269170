<template>
  <div>
    <div v-if="!isLoading">
      <form @submit.prevent="submitMemberProfile" v-on:keydown.enter.prevent="">
        <div class="form-item">
          <label for="firstName"
            >{{ $t("MemberProfile.form.labels.firstName") }}:
            <span class="error">*</span></label
          >
          <input type="text" v-model="form.firstName" id="firstName" />
        </div>

        <div class="form-item">
          <label for="lastName"
            >{{ $t("MemberProfile.form.labels.lastName") }}:
            <span class="error">*</span></label
          >
          <input type="text" v-model="form.lastName" id="lastName" />
        </div>
        <MemberProfileAddress
          v-model="form.address"
          :show-address="form.showAddress"
          @setMemberProfileShowAddress="setMemberProfileShowAddress"
        />
        <MemberProfilePhone
          v-model="form.phone"
          :show-phone="form.showPhone"
          @setMemberProfileShowPhone="setMemberProfileShowPhone"
        />
        <MovieWebsite v-model="form.website" />

        <div class="form-item">
          <label for="profileText">
            {{ $t("MemberProfile.form.labels.text") }}:
          </label>
          <textarea
            :maxlength="1000"
            v-model="form.text"
            id="profileText"
            rows="15"
          />
          <div class="flex justify-between w-100">
            <div class="tip text-small">
              {{ $t("MemberProfile.form.helpTexts.textTip") }}
            </div>
            <div class="tip text-small">{{ countCharacters }}/1000</div>
          </div>
        </div>

        <div class="form-item">
          <label for="profileText">
            {{ $t("MemberProfile.form.labels.textFr") }}:
          </label>
          <textarea v-model="form.textFr" id="profileTextFr" rows="15" />
          <p class="tip text-small">
            {{ $t("MemberProfile.form.helpTexts.textTip") }}
          </p>
        </div>

        <MemberProfileUploadPortrait
          @setPortrait="setPortrait"
          :value="form.portrait"
          :portrait-changed="portraitChanged"
        />

        <MemberProfileUploadPdf
          @setPDF="setPDF"
          :value="form.pdf"
          :filename="form.pdfFilename"
        />

        <MemberProfileCredits v-model="form.memberCredits" />
        <MemberProfileOccupation
          v-model="form.memberOccupations"
          :member-credits="form.movies"
        />

        <Credits
          v-if="form.credits && form.credits.length"
          :movie-credits="form.credits"
          :hidden-movies="form.hiddenMovies"
          @hideMovie="updateHiddenMovies"
        />

        <div class="flex justify-between s-mrgt-09">
          <button
            type="button"
            class="button s-mrgr-05"
            @click.prevent="cancel"
          >
            {{ $t("Movie.form.buttons.cancelMovie") }}
          </button>
          <button
            class="button"
            type="submit"
            :disabled="!isFormValid || isTextToLong"
          >
            {{ $t("Movie.form.buttons.addMovie") }}
          </button>
        </div>
      </form>
    </div>
    <div v-else>
      <p>{{ $t("MemberProfile.form.helpTexts.fetchingData") }}</p>
    </div>
  </div>
</template>

<script>
import MemberProfileAddress from "../MemberProfileEditComponents/MemberProfileAddress/MemberProfileAddress";
import MemberProfilePhone from "../MemberProfileEditComponents/MemberProfilePhoneNumber/MemberProfilePhone";
import MovieWebsite from "../../movies/MovieEditComponents/Links/MovieWebsite";
import MemberProfileUploadPortrait from "../MemberProfileEditComponents/MemberProfilePortrait/MemberProfileUploadPortrait";
import MemberProfileUploadPdf from "../MemberProfileEditComponents/MemberProfilePDF/MemberProfileUploadPdf";
import { MemberProfile } from "@/models/MemberProfile.models";
import MemberProfileCredits from "../MemberProfileEditComponents/MemberProfileCredits/MemberProfileCredits";
import { mapState } from "vuex";
import MemberProfileOccupation from "../MemberProfileEditComponents/MemberProfileOccupation/MemberProfileOccupation";
import { getMemberProfileMovies } from "@/services/memberProfile.service";
import Credits from "../MemberProfileEditComponents/Credits";

export default {
  name: "MemberProfileEditForm",
  components: {
    MemberProfileOccupation,
    Credits,
    MemberProfileAddress,
    MemberProfilePhone,
    MemberProfileUploadPortrait,
    MemberProfileUploadPdf,
    MemberProfileCredits,
    MovieWebsite
  },
  data() {
    return {
      form: {},
      isLoading: true,
      portrait: null,
      pdf: null,
      portraitChanged: false
    };
  },
  props: {
    memberProfile: null
  },
  computed: {
    isUpdate() {
      return !!this.form.id;
    },
    ...mapState("UserStore", ["authUser"]),
    isFormValid() {
      return (
        this.form.firstName !== "" &&
        this.form.lastName !== "" &&
        this.form.phone !== "" &&
        this.memberCreditsValid
      );
    },
    memberCreditsValid() {
      let creditsValid = true;
      if (this.form.memberCredits.length) {
        this.form.memberCredits.forEach(credit => {
          creditsValid =
            credit.title !== "" &&
            credit.year !== "" &&
            credit.memberType &&
            credit.memberFormat &&
            credit.memberStyles &&
            credit.direction.length &&
            credit.production.length &&
            credit.credit;
        });
      }
      return creditsValid;
    },
    isTextToLong() {
      return this.form.text.length > 1000;
    },
    countCharacters() {
      return this.form.text.length;
    }
  },
  methods: {
    setMemberProfileShowAddress(showAddress) {
      this.form.showAddress = showAddress;
    },
    setMemberProfileShowPhone(showPhone) {
      this.form.showPhone = showPhone;
    },
    setPortrait(portrait) {
      this.portrait = portrait;
      this.portraitChanged = true;
    },
    setPDF(pdf) {
      this.pdf = pdf;
    },
    cancel() {
      this.$emit("cancel");
    },
    submitMemberProfile() {
      this.form.memberCredits = this.cleanMemberCreditData(
        this.form.memberCredits
      );
      let eventName = this.form.id ? "patchMemberProfile" : "postMemberProfile";
      this.$emit(
        eventName,
        this.form,
        this.portrait,
        this.portraitChanged,
        this.pdf
      );
    },
    cleanMemberCreditData(memberCredits) {
      return memberCredits.map(credit => {
        return {
          id: credit.id ? credit.id : null,
          title: credit.title,
          year: credit.year,
          direction: credit.direction,
          production: credit.production,
          credits: credit.credit,
          memberType: credit.memberType.id,
          memberFormat: credit.memberFormat.id,
          memberStyles: credit.memberStyles.map(style => style.id)
        };
      });
    },
    updateHiddenMovies(movieId, is_hidden) {
      if (is_hidden === true) {
        this.form.hiddenMovies.push(movieId);
      } else {
        let index = this.form.hiddenMovies.indexOf(movieId);
        if (index !== -1) {
          this.form.hiddenMovies.splice(index, 1);
        }
      }
    }
  },
  async mounted() {
    this.isLoading = true;
    if (this.memberProfile) {
      this.form = this.memberProfile;
    } else {
      this.form = new MemberProfile({});
    }
    this.form.credits = await getMemberProfileMovies();
    this.isLoading = false;
  }
};
</script>
