var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "s-mrgb-05" },
    [
      _vm.movie.poster
        ? _c("div", { staticClass: "poster__wrapper" }, [
            _vm.movie.poster
              ? _c("img", {
                  attrs: { src: _vm.movie.poster, alt: "Filmposter" },
                  on: { error: _vm.usePlaceholder }
                })
              : _vm._e()
          ])
        : _vm._e(),
      _vm.movie.inCinema
        ? _c("div", { staticClass: "s-mrgb-06" }, [
            _c("h2", { staticClass: "h2" }, [
              _vm._v(_vm._s(_vm.$t("Movie.detail.inCinema")))
            ]),
            _c(
              "div",
              { staticClass: "flex align-center" },
              [
                _c("svg-icon", {
                  staticClass: "icon icon--small",
                  attrs: { name: "arrow-long-right" }
                }),
                _c(
                  "a",
                  {
                    staticClass: "s-mrgl-02",
                    attrs: { href: `${_vm.movie.inCinema}`, target: "_blank" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Movie.detail.links.currentScreenings")) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.hasCredits
        ? _c("SidebarCredits", {
            attrs: {
              credits: _vm.movie.movieCredits,
              productionLinks: [
                ..._vm.movie.productionLinks,
                ..._vm.movie.coProductionLinks
              ]
            }
          })
        : _vm._e(),
      _c("PageNavigation", {
        attrs: {
          hasAwards: _vm.hasAwards,
          hasFestivals: _vm.hasFestivals,
          hasCredits: _vm.hasCredits,
          hasInfo: _vm.hasInfo
        }
      }),
      _vm.hasLinks ? _c("Links", { attrs: { links: _vm.links } }) : _vm._e(),
      _c("MovieEditButton", { attrs: { movie: _vm.movie } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }