var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c(
            "div",
            [
              _c(
                "form",
                [
                  !_vm.isUpdate
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "s-mrgb-05" },
                          [
                            _c(
                              "i18n",
                              {
                                staticClass: "tip",
                                attrs: {
                                  path: "Movie.form.create.agb.text",
                                  tag: "p",
                                  for: "link"
                                }
                              },
                              [
                                _c("a", [
                                  _c("p", { staticClass: "tip text-small" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("Movie.form.create.agb.link")
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        !_vm.isMovieFormValid
                          ? _c("p", { staticClass: "text-bold s-mrgb-05" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("Movie.form.helpText.pleaseFill")
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c("MovieTitle", {
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  }),
                  _c("MovieAlternativeTitle", {
                    model: {
                      value: _vm.form.alternativeTitles,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "alternativeTitles", $$v)
                      },
                      expression: "form.alternativeTitles"
                    }
                  }),
                  _c("MovieSynopsisDE", {
                    model: {
                      value: _vm.form.synopsis,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "synopsis", $$v)
                      },
                      expression: "form.synopsis"
                    }
                  }),
                  _c("MovieSynopsisFR", {
                    model: {
                      value: _vm.form.synopsisFr,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "synopsisFr", $$v)
                      },
                      expression: "form.synopsisFr"
                    }
                  }),
                  _c("MovieCompletionStatus", {
                    model: {
                      value: _vm.form.completionStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "completionStatus", $$v)
                      },
                      expression: "form.completionStatus"
                    }
                  }),
                  _vm.isUpdate
                    ? _c("MoviePoster", {
                        on: { setPoster: _vm.setPoster },
                        model: {
                          value: _vm.form.poster,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "poster", $$v)
                          },
                          expression: "form.poster"
                        }
                      })
                    : _c("CreateMoviePoster", {
                        on: { setPoster: _vm.setPoster }
                      }),
                  _c("MovieImages", {
                    on: { setMovieImages: _vm.setMovieImages },
                    model: {
                      value: _vm.form.movieImages,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "movieImages", $$v)
                      },
                      expression: "form.movieImages"
                    }
                  }),
                  _c("MovieType", {
                    on: { setMovieType: _vm.setMovieType },
                    model: {
                      value: _vm.form.movieType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "movieType", $$v)
                      },
                      expression: "form.movieType"
                    }
                  }),
                  _vm.form.movieType.name !== "Medienkunst"
                    ? _c("MovieFormat", {
                        attrs: { "form-object": this.form },
                        model: {
                          value: _vm.form.movieFormat,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "movieFormat", $$v)
                          },
                          expression: "form.movieFormat"
                        }
                      })
                    : _vm._e(),
                  _vm.form.movieType.name !== "Medienkunst"
                    ? _c("MovieStyles", {
                        attrs: {
                          "movie-style-list": _vm.movieStyleList,
                          "form-object": this.form
                        },
                        model: {
                          value: _vm.form.styles,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "styles", $$v)
                          },
                          expression: "form.styles"
                        }
                      })
                    : _vm._e(),
                  _c("MovieDuration", {
                    model: {
                      value: _vm.form.duration,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "duration", $$v)
                      },
                      expression: "form.duration"
                    }
                  }),
                  _c("MoviePublishedYear", {
                    model: {
                      value: _vm.form.publishedYear,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "publishedYear", $$v)
                      },
                      expression: "form.publishedYear"
                    }
                  }),
                  _c("MovieMediaArtProjects", {
                    model: {
                      value: _vm.form.mediaArtFestivals,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mediaArtFestivals", $$v)
                      },
                      expression: "form.mediaArtFestivals"
                    }
                  }),
                  _c("MovieAdditionalFestivals", {
                    model: {
                      value: _vm.form.additionalFestivals,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "additionalFestivals", $$v)
                      },
                      expression: "form.additionalFestivals"
                    }
                  }),
                  _c("MovieAwards", {
                    model: {
                      value: _vm.form.awards,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "awards", $$v)
                      },
                      expression: "form.awards"
                    }
                  }),
                  _c("div", { staticClass: "form-item" }, [
                    _c("div", { staticClass: "form-item-input" }, [
                      _c("div", { staticClass: "s-mrgb-04" }, [
                        _c("label", { attrs: { for: "isan" } }, [
                          _vm._v("ISAN:")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.isan,
                              expression: "form.isan"
                            }
                          ],
                          attrs: { type: "text", id: "isan" },
                          domProps: { value: _vm.form.isan },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "isan", $event.target.value)
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("MovieRecordingFormat", {
                    model: {
                      value: _vm.form.recordingFormat,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "recordingFormat", $$v)
                      },
                      expression: "form.recordingFormat"
                    }
                  }),
                  _c("MovieScreeningFormat", {
                    model: {
                      value: _vm.form.screeningFormat,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "screeningFormat", $$v)
                      },
                      expression: "form.screeningFormat"
                    }
                  }),
                  _c("MovieImageFormat", {
                    model: {
                      value: _vm.form.imageFormat,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "imageFormat", $$v)
                      },
                      expression: "form.imageFormat"
                    }
                  }),
                  _c("MovieAudioFormat", {
                    model: {
                      value: _vm.form.audioFormat,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "audioFormat", $$v)
                      },
                      expression: "form.audioFormat"
                    }
                  }),
                  _c("MovieOriginalLanguage", {
                    attrs: { "language-list": _vm.languageList },
                    model: {
                      value: _vm.form.originalLang,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "originalLang", $$v)
                      },
                      expression: "form.originalLang"
                    }
                  }),
                  _c("MovieSubtitleLanguage", {
                    attrs: { "language-list": _vm.languageList },
                    model: {
                      value: _vm.form.subtitle,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "subtitle", $$v)
                      },
                      expression: "form.subtitle"
                    }
                  }),
                  _c("MovieDubbedLanguage", {
                    attrs: { "language-list": _vm.languageList },
                    model: {
                      value: _vm.form.dubbedLang,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "dubbedLang", $$v)
                      },
                      expression: "form.dubbedLang"
                    }
                  }),
                  _c("MovieWebsite", {
                    model: {
                      value: _vm.form.website,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "website", $$v)
                      },
                      expression: "form.website"
                    }
                  }),
                  _c("MovieProductionLinks", {
                    on: { setProductionLinks: _vm.setProductionLinks },
                    model: {
                      value: _vm.form.productionLinks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "productionLinks", $$v)
                      },
                      expression: "form.productionLinks"
                    }
                  }),
                  _c("MovieCoProductionLinks", {
                    on: { setCoProductionLinks: _vm.setCoProductionLinks },
                    model: {
                      value: _vm.form.coProductionLinks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "coProductionLinks", $$v)
                      },
                      expression: "form.coProductionLinks"
                    }
                  }),
                  _c("MovieRentalLink", {
                    model: {
                      value: _vm.form.rentalLink,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "rentalLink", $$v)
                      },
                      expression: "form.rentalLink"
                    }
                  }),
                  _c("MovieWorldSalesLink", {
                    model: {
                      value: _vm.form.worldSalesLink,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "worldSalesLink", $$v)
                      },
                      expression: "form.worldSalesLink"
                    }
                  }),
                  _c("MovieVODLink", {
                    model: {
                      value: _vm.form.vodLink,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "vodLink", $$v)
                      },
                      expression: "form.vodLink"
                    }
                  }),
                  _c("MovieDVDLink", {
                    model: {
                      value: _vm.form.dvdBlurayLink,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "dvdBlurayLink", $$v)
                      },
                      expression: "form.dvdBlurayLink"
                    }
                  }),
                  _c("MovieInCinema", {
                    model: {
                      value: _vm.form.inCinema,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "inCinema", $$v)
                      },
                      expression: "form.inCinema"
                    }
                  }),
                  _c("MovieSupporters", {
                    attrs: { "supporter-list": _vm.supporterList },
                    model: {
                      value: _vm.form.supporters,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "supporters", $$v)
                      },
                      expression: "form.supporters"
                    }
                  }),
                  _c("MovieTrailer", {
                    model: {
                      value: _vm.form.trailer,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "trailer", $$v)
                      },
                      expression: "form.trailer"
                    }
                  }),
                  _c("div", { staticClass: "form-item" }, [
                    _c("label", [
                      _c("span", { staticClass: "text-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Movie.form.labels.credits")) +
                            ": "
                        )
                      ])
                    ])
                  ]),
                  _c("MovieCredits", {
                    attrs: {
                      "movie-type": _vm.form.movieType,
                      companies: _vm.companies,
                      form: _vm.form
                    },
                    model: {
                      value: _vm.form.movieCredits,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "movieCredits", $$v)
                      },
                      expression: "form.movieCredits"
                    }
                  }),
                  _c("div", { staticClass: "flex justify-between s-mrgt-09" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button s-mrgr-05",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.cancel.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Movie.form.buttons.cancelMovie")) +
                            " "
                        )
                      ]
                    ),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "button s-mrgr-10",
                          attrs: {
                            type: "button",
                            title: _vm.$t(
                              "Movie.form.buttons.saveMovieTooltip"
                            ),
                            disabled:
                              !_vm.isMovieDraftFormValid ||
                              !_vm.isCreditFormValid ||
                              _vm.errorSynopsisToLong
                          },
                          on: {
                            click: function($event) {
                              return _vm.openModal()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("Movie.form.buttons.saveMovie")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: {
                            type: "button",
                            title: _vm.$t("Movie.form.buttons.addMovieTooltip"),
                            disabled:
                              !_vm.isMovieFormValid ||
                              !_vm.isCreditFormValid ||
                              _vm.errorSynopsisToLong
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.updateOrCreateMovie(false)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("Movie.form.buttons.addMovie")) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c(
                    "Modal",
                    {
                      attrs: { "is-open": _vm.isModalOpen },
                      on: {
                        closeModal: function($event) {
                          return _vm.closeModal()
                        }
                      }
                    },
                    [
                      _c("h1", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(_vm._s(_vm.$t("Modal.movie.header")))
                      ]),
                      _c("div", [
                        _c("p", [_vm._v(_vm._s(_vm.$t("Modal.movie.content")))])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "flex justify-between",
                          attrs: { slot: "footer" },
                          slot: "footer"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.closeModal()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Modal.movie.footer.cancel")) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.cacheMovie()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Modal.movie.footer.save")) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.adding ? _c("AddingSpinner") : _vm._e()
            ],
            1
          )
        : _c("loader")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }