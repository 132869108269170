var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "s-mrgb-06" }, [
        _vm._v(_vm._s(_vm.$t("MemberProfile.form.labels.movies")))
      ]),
      _vm._l(_vm.uniqueCreditsByMovie, function(credit, index) {
        return _c(
          "div",
          {
            key: `member-profile--movies---${index}`,
            staticClass: "flex align-center justify-between s-mrgb-05"
          },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: { name: "MovieDetail", params: { Mid: credit.movie } }
                }
              },
              [_c("h2", [_vm._v(_vm._s(credit.title))])]
            ),
            _c("div", [
              _c("input", {
                attrs: { type: "checkbox" },
                domProps: {
                  checked: _vm.hiddenMovies.indexOf(credit.movie) > -1
                },
                on: {
                  change: function($event) {
                    return _vm.setMovieHidden($event, credit.movie)
                  }
                }
              }),
              _c("label", [
                _vm._v(_vm._s(_vm.$t("MemberProfile.form.labels.hide")))
              ])
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }