var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "t-md-flex between-t-md" },
    [
      _c("h1", { staticClass: "movie-detail__title" }, [
        _vm._v(_vm._s(_vm.$t("Movie.detail.title")))
      ]),
      _c(
        "router-link",
        {
          staticClass: "h1",
          attrs: {
            to: {
              name: "MoviesListView"
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("Movie.detail.toOverview")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }