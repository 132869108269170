<template>
  <div class="form-item">
    <label for="completion-status"
      >{{ $t("Movie.form.labels.completionStatus") }}:
      <span class="error">*</span></label
    >
    <select v-model="completionStatus" id="completion-status">
      <option
        v-for="(status, key) in completionStatusesArray"
        :key="status"
        :value="key"
      >
        {{ status }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "MovieCompletionStatus",
  data() {
    return {
      completionStatusesArray: {
        1: `${this.$t("Movie.form.statuses.completed")}`,
        2: `${this.$t("Movie.form.statuses.inProduction")}`,
        3: `${this.$t("Movie.form.statuses.inDevelopment")}`
      }
    };
  },
  props: {
    value: {
      type: String
    }
  },
  computed: {
    completionStatus: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
