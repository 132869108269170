var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.groupList, function(group, key) {
      return _c(
        "div",
        { key: key, staticClass: "form-item" },
        [
          _c("h2", { staticClass: "h2 text-normal" }, [
            _vm._v(_vm._s(group.name))
          ]),
          _vm._l(_vm.getCreditByGroup(group.id), function(
            formCredit,
            creditIndex
          ) {
            return _c(
              "div",
              { key: formCredit.id, staticClass: "form-item-input" },
              [
                _vm.isCreditEditable
                  ? _c("p", { staticClass: "s-mrgt-03 s-mrgb-03 error" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("Movie.form.labels.creditCreation.error")
                          ) +
                          " "
                      )
                    ])
                  : _vm._e(),
                !_vm.isCreditValid(formCredit)
                  ? _c("p", { staticClass: "error" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("Movie.form.helpText.creditError")) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "s-mrgb-04" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Movie.form.labels.creditCreation.labels.occupations"
                        )
                      )
                    )
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: formCredit.occupation,
                          expression: "formCredit.occupation"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            formCredit,
                            "occupation",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: { disabled: "" },
                          domProps: {
                            value: _vm.$t(
                              "Movie.form.labels.creditCreation.select"
                            )
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "Movie.form.labels.creditCreation.select"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _vm._l(_vm.getOccupationByGroup(group.id), function(
                        credit,
                        key
                      ) {
                        return _c(
                          "option",
                          {
                            key: key,
                            attrs: { disabled: _vm.isCreditEditable },
                            domProps: { value: credit }
                          },
                          [_vm._v(_vm._s(credit.credit))]
                        )
                      })
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "s-mrgb-04 form-item" }, [
                  _c("label", { attrs: { for: "creditUser" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Movie.form.labels.creditCreation.labels.users")
                      )
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "multiselect-wrapper" },
                    [
                      !_vm.isServiceOccupation(formCredit)
                        ? _c("multiselect", {
                            attrs: {
                              id: "creditUser",
                              label: "fullName",
                              "track-by": "id",
                              placeholder: _vm.$t(
                                "Movie.form.labels.creditCreation.placeholder"
                              ),
                              "open-direction": "bottom",
                              options: _vm.users,
                              "clear-on-select": false,
                              "max-height": 600,
                              "show-no-results": false,
                              "hide-selected": true,
                              taggable: true,
                              "tag-placeholder": _vm.$t(
                                "Movie.form.labels.creditCreation.placeholder"
                              ),
                              "deselect-label": _vm.$t(
                                "Movie.form.labels.creditCreation.create"
                              ),
                              multiple: _vm.isMediaMovieType(formCredit),
                              max: 10,
                              disabled: !_vm.isOccupationSelected(formCredit)
                            },
                            on: {
                              tag: function($event) {
                                return _vm.addUnknownUser($event, formCredit)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "tag",
                                  fn: function({ option, remove }) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "custom__tag" },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(option.fullName))
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "custom__remove",
                                              on: {
                                                click: function($event) {
                                                  return remove(option)
                                                }
                                              }
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticClass: "icon icon--small",
                                                attrs: { name: "times" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: formCredit.user,
                              callback: function($$v) {
                                _vm.$set(formCredit, "user", $$v)
                              },
                              expression: "formCredit.user"
                            }
                          })
                        : _c("multiselect", {
                            attrs: {
                              id: "creditCopmany",
                              label: "name",
                              "track-by": "id",
                              placeholder: _vm.$t(
                                "Movie.form.labels.creditCreation.placeholder"
                              ),
                              "open-direction": "bottom",
                              options: _vm.companies,
                              "clear-on-select": false,
                              "max-height": 600,
                              "show-no-results": false,
                              "hide-selected": true,
                              taggable: true,
                              "tag-placeholder": _vm.$t(
                                "Movie.form.labels.creditCreation.placeholder"
                              ),
                              "deselect-label": _vm.$t(
                                "Movie.form.labels.creditCreation.create"
                              ),
                              multiple: false,
                              max: 10,
                              disabled: !_vm.isOccupationSelected(formCredit)
                            },
                            on: {
                              tag: function($event) {
                                return _vm.addUnknownCompany($event, formCredit)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "tag",
                                  fn: function({ option, remove }) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "custom__tag" },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(option.name))
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "custom__remove",
                                              on: {
                                                click: function($event) {
                                                  return remove(option)
                                                }
                                              }
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticClass: "icon icon--small",
                                                attrs: { name: "times" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: formCredit.company,
                              callback: function($$v) {
                                _vm.$set(formCredit, "company", $$v)
                              },
                              expression: "formCredit.company"
                            }
                          })
                    ],
                    1
                  )
                ]),
                _vm.movieCredits.length
                  ? _c(
                      "button",
                      {
                        staticClass: "button button--delete s-mrgb-06",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.removeCredit(creditIndex, group.id)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "Movie.form.labels.creditCreation.buttons.delete"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          }),
          _c(
            "button",
            {
              staticClass: "button",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.addCredit(group.id)
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("Movie.form.labels.creditCreation.buttons.add")
                  ) +
                  " "
              )
            ]
          )
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }