import { axiosBaseService } from "./axiosBase.service";
import store from "../store/index.js";

/**
 * Logs user in by issuing Access & Refresh tokens from API.
 * @param {string} payload - email and password
 */
const login = async payload => {
  try {
    const url = `/${store.state.locale}/usersapi/token/`;
    const response = await axiosBaseService.post(url, payload);
    if (response.status !== 200) {
      return response.statusText;
    }
    return response;
  } catch (error) {
    throw error;
  }
};
export { login };
