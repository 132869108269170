export default {
  namespaced: true,
  state: {
    resetCompleted: false,
    resetError: false,
    resetMatchError: false
  },
  mutations: {
    passwordResetSuccess(state) {
      state.resetCompleted = true;
      state.resetError = false;
    },
    passwordResetFailure(state) {
      state.resetError = true;
    },
    passwordResetMatchFailure(state, data) {
      state.resetMatchError = data;
    },
    clearResetStatus(state) {
      state.resetCompleted = false;
      state.resetError = false;
    }
  },
  actions: {
    setPasswordResetSuccess({ commit }) {
      commit("passwordResetSuccess");
    },
    setPasswordResetFailure({ commit }) {
      commit("passwordResetFailure");
    },
    setPasswordResetMatchFailure({ commit }, data) {
      commit("passwordResetMatchFailure", data);
    },
    clearResetStatus({ commit }) {
      commit("clearResetStatus");
    }
  }
};
