import { render, staticRenderFns } from "./Format.vue?vue&type=template&id=14159680&scoped=true&"
import script from "./Format.vue?vue&type=script&lang=js&"
export * from "./Format.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14159680",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('14159680')) {
      api.createRecord('14159680', component.options)
    } else {
      api.reload('14159680', component.options)
    }
    module.hot.accept("./Format.vue?vue&type=template&id=14159680&scoped=true&", function () {
      api.rerender('14159680', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MemberProfiles/MemberProfileEditComponents/MemberProfileCredits/Format.vue"
export default component.exports