var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", { attrs: { for: "screening-format" } }, [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.screeningFormat")) + ":")
    ]),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.movieScreeningFormat,
          expression: "movieScreeningFormat"
        }
      ],
      attrs: { type: "text", id: "screening-format" },
      domProps: { value: _vm.movieScreeningFormat },
      on: {
        input: function($event) {
          if ($event.target.composing) return
          _vm.movieScreeningFormat = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }