var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mounting-portal", { attrs: { append: "", "mount-to": "body" } }, [
    _c("div", { staticClass: "adding" }, [
      _c("div", [
        _c(
          "div",
          { staticClass: "icon" },
          [
            _c("svg-icon", {
              staticClass: "inline-block w-3 svg-icon svg-icon--chevron-left",
              attrs: { name: "movies-reel-1" }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }