<template>
  <div class="form-item">
    <label for="image-format">{{ $t("Movie.form.labels.imageFormat") }}:</label>
    <input type="text" v-model="movieImageFormat" id="image-format" />
  </div>
</template>

<script>
export default {
  name: "MovieImageFormat",
  props: {
    value: {
      type: String,
      default: "",
      required: false
    }
  },
  computed: {
    movieImageFormat: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
