var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c(
      "label",
      { staticClass: "typo__label", attrs: { for: "MemberCreditStyle" } },
      [
        _vm._v(_vm._s(_vm.$t("MemberProfile.form.credits.style")) + ": "),
        _c("span", { staticClass: "error" }, [_vm._v("*")])
      ]
    ),
    _c(
      "div",
      { staticClass: "multiselect-wrapper" },
      [
        _c(
          "multiselect",
          {
            attrs: {
              id: "MemberCreditStyle",
              "track-by": "id",
              label: "name",
              placeholder: "",
              "open-direction": "bottom",
              options: _vm.styles,
              multiple: true,
              searchable: false,
              "internal-search": false,
              "clear-on-select": false,
              "close-on-select": false,
              "max-height": 600,
              "show-no-results": false,
              "hide-selected": true
            },
            scopedSlots: _vm._u([
              {
                key: "tag",
                fn: function({ option, remove }) {
                  return [
                    _c("span", { staticClass: "custom__tag" }, [
                      _c("span", [_vm._v(" " + _vm._s(option.name))]),
                      _c(
                        "span",
                        {
                          staticClass: "custom__remove",
                          on: {
                            click: function($event) {
                              return remove(option)
                            }
                          }
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "icon icon--small",
                            attrs: { name: "times" }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.Style,
              callback: function($$v) {
                _vm.Style = $$v
              },
              expression: "Style"
            }
          },
          [
            !_vm.styles
              ? _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("MemberProfile.form.helpTexts.elementNotFound")
                    )
                  )
                ])
              : _vm._e()
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }