<template>
  <div class="form-item">
    <label for="movieType"
      >{{ $t("Movie.form.labels.categories.type") }}:<span class="error"
        >*</span
      ></label
    >
    <select id="movieType" v-model="movieType">
      <option
        v-for="(movieType, key) in movieTypeList"
        :value="movieType.id"
        :key="key"
      >
        {{ translateOrFallback(movieType, "name") }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MovieType",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState("CategoryStore", ["movieTypeList"]),
    movieType: {
      get() {
        return this.value.id;
      },
      set(newVal) {
        const type = this.movieTypeList.find(
          movieType => movieType.id === newVal
        );
        this.$emit("setMovieType", type);
      }
    }
  },
  methods: {
    translateOrFallback: function(model, field) {
      return model[field + `_${this.$store.state.locale}`] || model[field];
    }
  }
};
</script>
