var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { class: _vm.className, attrs: { xmlns: "http://www.w3.org/2000/svg" } },
    [
      _vm.title ? _c("title", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
      _c("use", {
        attrs: {
          "xlink:href": _vm.iconPath,
          "xmlns:xlink": "http://www.w3.org/1999/xlink"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }