<template>
  <mounting-portal append mount-to="body">
    <div class="adding">
      <div>
        <div class="icon">
          <svg-icon
            class="inline-block w-3 svg-icon svg-icon--chevron-left"
            name="movies-reel-1"
          />
        </div>
      </div>
    </div>
  </mounting-portal>
</template>
<script>
export default {
  name: "AddingSpinner",
  props: {
    importing: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
