var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("MovieEditForm", {
        attrs: { movieId: this.$route.params.Mid },
        on: { patchMovie: _vm.patchMovie, cancel: _vm.cancel }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }