var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoaded
    ? _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-t-md-12 s-mrgb-06" },
          [_c("Header")],
          1
        ),
        _c(
          "div",
          { staticClass: "col-t-md-4 col-xs-12" },
          [
            _c("Sidebar", {
              attrs: {
                movie: _vm.selectedMovie,
                hasCredits: _vm.hasCredits,
                hasLinks: _vm.hasLinks,
                hasFestivals: _vm.hasFestivals,
                hasInfo: _vm.hasInfo,
                hasAwards: _vm.hasAwards
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-t-md-8" },
          [_c("Main", { attrs: { movie: _vm.selectedMovie } })],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-t-md-12" },
          [
            _c("Bottom", {
              attrs: {
                movie: _vm.selectedMovie,
                hasCredits: _vm.hasCredits,
                hasFestivals: _vm.hasFestivals,
                hasInfo: _vm.hasInfo,
                hasAwards: _vm.hasAwards
              }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }