<template>
  <div class="form-item">
    <label for="alternativeTitle"
      >{{ $t("Movie.form.labels.alternativeTitles") }}:</label
    >
    <textarea
      v-model="alternativeTitle"
      id="alternativeTitle"
      name="alternativeTitle"
      rows="2"
    />
  </div>
</template>

<script>
export default {
  name: "MovieAlternativeTitle",
  props: {
    value: {
      default: "",
      required: true
    }
  },
  computed: {
    alternativeTitle: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
