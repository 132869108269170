var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("div", { staticClass: "s-mrgt-08" }, [
        _c("div", { staticClass: "row" }, [
          _vm.projects
            ? _c(
                "div",
                { staticClass: "col-xs-12" },
                _vm._l(_vm.projects, function(list, year) {
                  return _c(
                    "div",
                    { key: year },
                    _vm._l(list, function(project, key) {
                      return _c(
                        "div",
                        { key: key },
                        [
                          project.publishedYear || !project.year
                            ? _c("Movie", {
                                attrs: {
                                  movie: project,
                                  row: key,
                                  profile: _vm.profile,
                                  profileType: _vm.profileType
                                }
                              })
                            : _c("Credit", {
                                attrs: { row: key, "member-credit": project }
                              })
                        ],
                        1
                      )
                    }),
                    0
                  )
                }),
                0
              )
            : _vm._e()
        ])
      ])
    : _c("loader")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }