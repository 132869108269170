<template>
  <div>
    <MovieEditForm @postMovie="postMovie" @cancel="cancel" />
  </div>
</template>
<script>
import MovieEditForm from "@/components/movies/MovieForms/MovieEditForm";
import { postMovie } from "@/services/movie.service";
import { patchMoviePoster } from "@/services/movie.service";
import { mapMutations, mapState } from "vuex";
import { postMovieImages } from "@/services/movie.service";

export default {
  name: "MovieCreateView",
  components: {
    MovieEditForm
  },
  data() {
    return {
      movieImagesFailure: false
    };
  },
  computed: {
    ...mapState("MandateStore", ["mandate"])
  },
  methods: {
    ...mapMutations("Adding", ["addAddingState", "removeAddingState"]),
    async postMovie(movie, poster, movieImages) {
      this.addAddingState();
      movie.mandates = [this.mandate];
      let movieResponse = null;
      try {
        movieResponse = await postMovie(movie);
      } catch (e) {
        this.removeAddingState();
        this.$toaster.error(this.$i18n.t("Toaster.movieCreation.failure"));
      }

      // If the movie couldn't be uploaded we dont have to try to upload the images because there is no movie they can be uploaded to.
      if (movieResponse) {
        try {
          if (poster) {
            await patchMoviePoster(movieResponse.data.id, poster);
          }
          movieImages.append("update", false);
          movieImages.append("movieId", movieResponse.data.id);
          await postMovieImages(movieImages);
          this.movieImagesFailure = false;
        } catch (e) {
          this.removeAddingState();
          this.movieImagesFailure = true;
          await this.$router.push({
            name: "MovieEditView",
            params: { Mid: movieResponse.data.id }
          });
          this.$toaster.warning(e.response.data[0]);
        }
        if (!this.movieImagesFailure) {
          await this.$router
            .push({
              name: "UserMovieListView",
              params: { imageUploadFailure: this.movieImagesFailure }
            })
            .then(() => {
              this.removeAddingState();
              this.$toaster.success(
                this.$i18n.t("Toaster.movieCreation.success")
              );
            });
        }
      }
    },
    cancel() {
      this.$router.push({
        name: "MoviesListView"
      });
    }
  }
};
</script>
