<template>
  <div class="flex">
    <div
      v-for="(pageNumber, index) in pageNumbers"
      :key="`pagination-button--${index}`"
    >
      <input
        type="button"
        :value="pageNumber"
        class="page-button s-mrgr-05 cursor-pointer"
        :class="isActivePage(pageNumber) ? 'is-active' : ''"
        @click="setCurrentPage(pageNumber)"
      />
    </div>
    <span v-if="currentPage + 3 < pageCount" class="s-mrgr-05">...</span>
    <div>
      <input
        type="button"
        :value="pageCount"
        class="page-button s-mrgr-05 cursor-pointer"
        :class="isActivePage(pageCount) ? 'is-active' : ''"
        @click="setCurrentPage(pageCount)"
      />
    </div>
    <input
      type="button"
      :value="$t('MemberPagination.next')"
      :disabled="isNextButton"
      class="next-button cursor-pointer"
      @click="setCurrentPage(currentPage + 1)"
    />
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pageCount: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    pageNumbers() {
      const pageNumberList = [];
      if (this.currentPage >= 3 && this.pageCount >= 5) {
        for (let n = 0; n < 4; n++) {
          if (this.currentPage + n === this.pageCount) {
            for (
              let i = this.currentPage - (4 - n);
              i <= this.currentPage + (n - 1);
              i++
            ) {
              pageNumberList.push(i);
            }
            return pageNumberList;
          }
        }
        for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
          pageNumberList.push(i);
        }
        return pageNumberList;
      }
      if (this.pageCount > 3) {
        return 3;
      }
      return this.pageCount - 1;
    },
    isNextButton() {
      return this.currentPage === this.pageCount;
    }
  },
  methods: {
    setCurrentPage(page) {
      this.$emit("setCurrentPage", page);
    },
    isActivePage(page) {
      return this.currentPage === page;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-button,
.next-button {
  background-color: transparent;
  border: none;
}
.is-active {
  font-weight: bold;
  border-bottom: 2px solid black;
}
.next-button:disabled {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
