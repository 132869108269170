class User {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.firstName = payload.firstName || null;
    this.lastName = payload.lastName || null;
    this.email = payload.email || null;
    this.isStaff = payload.isStaff || null;
    this.fullName = payload.fullName || null;
    this.memberProfile = payload.memberProfile || null;
    this.isMember = payload.isMember || false;
    this.companies = payload.companies || [];
  }
}

export { User };
