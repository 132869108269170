<template>
  <div v-if="!loading" class="s-mrgt-08">
    <div class="row">
      <div v-if="projects" class="col-xs-12">
        <div v-for="(list, year) in projects" :key="year">
          <div v-for="(project, key) in list" :key="key">
            <Movie
              v-if="project.publishedYear || !project.year"
              :movie="project"
              :row="key"
              :profile="profile"
              :profileType="profileType"
            ></Movie>
            <Credit v-else :row="key" :member-credit="project"></Credit>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loader v-else></loader>
</template>

<script>
import Movie from "@/components/MemberProfiles/Movie";
import Credit from "@/components/MemberProfiles/Credit";
import { getCompanyProjects } from "../../services/Company.service";
import loader from "../../components/loader";

export default {
  components: {
    Movie,
    Credit,
    loader
  },
  name: "Projects",
  props: {
    movieProjects: {
      type: Array,
      required: false
    },
    profile: {
      type: Object,
      required: true
    },
    profileType: {
      type: String,
      required: false,
      default: "company"
    },
    memberCreditList: {
      type: Array,
      required: true
    }
  },
  computed: {
    projects() {
      let movies_without_year = [];
      let list = [];
      this.movieProjects?.forEach(movie => {
        if (!movie.publishedYear) {
          movies_without_year.push(movie);
        }
        if (!(movie.publishedYear in list)) {
          list[movie.publishedYear] = [];
        }
        list[movie.publishedYear].push(movie);
      });
      this.memberCreditList?.forEach(credit => {
        if (!(credit.year in list)) {
          list[credit.year] = [];
        }
        list[credit.year].push(credit);
      });
      list.push(movies_without_year);
      return list.reverse();
    }
  },
  data() {
    return {
      loading: false
    };
  },
  async mounted() {
    if (this.profileType === "company" && !this.movieProjects) {
      this.loading = true;
      this.movieProjects = await getCompanyProjects(this.profile.id);
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss"></style>
