var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "support" }, [
    _c("h2", [_vm._v("supported by:")]),
    _c("div", { staticClass: "logo" }, [
      _c(
        "ul",
        _vm._l(_vm.mandate.institution, function(logo) {
          return _c("li", { key: logo.website }, [
            _c("a", { attrs: { href: logo.website, target: "_blank" } }, [
              _c("img", {
                staticClass: "institution__logo",
                attrs: { src: logo.institutionLogo, alt: "Logo", title: "Home" }
              })
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }