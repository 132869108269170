var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", { attrs: { for: "trailer" } }, [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.trailer")) + ":")
    ]),
    _c("div", { staticClass: "form-item-input" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.movieTrailer,
            expression: "movieTrailer"
          }
        ],
        attrs: { type: "url", id: "trailer" },
        domProps: { value: _vm.movieTrailer },
        on: {
          input: function($event) {
            if ($event.target.composing) return
            _vm.movieTrailer = $event.target.value
          }
        }
      }),
      _c("p", { staticClass: "tip text-small" }, [
        _vm._v("http://vimeo.com/371138452")
      ]),
      _c("p", { staticClass: "tip text-small" }, [
        _vm._v(" http://www.youtube.com/watch?v=nAQPYJvxf4 ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }