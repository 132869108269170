<template>
  <form
    v-if="companyProfile"
    @submit.prevent="submitCompanyProfile"
    v-on:keydown.enter.prevent=""
  >
    <div class="form-item">
      <label for="companyName"
        >{{ $t("Company.form.labels.name") }}:
        <span class="error">*</span></label
      >
      <input type="text" v-model="form.name" id="companyName" />
    </div>

    <MemberProfileUploadPortrait
      @setPortrait="setPortrait"
      :value="form.portrait"
      :portrait-changed="portraitChanged"
    />

    <div class="form-item">
      <label for="profileText">
        {{ $t("Company.form.labels.description") }}:
      </label>
      <textarea
        :maxlength="700"
        v-model="form.description"
        id="profileText"
        rows="15"
      />
      <div class="flex justify-between w-100">
        <div class="tip text-small">
          {{ $t("Company.form.helpTexts.textTip") }}
        </div>
        <div class="tip text-small">{{ countCharacters }}/700</div>
      </div>
    </div>

    <div class="form-item">
      <label for="profileWebsite">
        {{ $t("Company.form.labels.website") }}:
      </label>
      <input type="url" v-model="form.website" id="profileWebsite" />
      <p class="tip text-small">
        {{ $t("Movie.form.helpText.correctFormat") }}
      </p>
    </div>

    <div class="form-item">
      <label for="profilePhone"> {{ $t("Company.form.labels.phone") }}: </label>
      <input type="tel" v-model="form.phone" id="profilePhone" />
      <p class="tip text-small">
        {{ $t("Generic.form.helpText.phoneNumber") }}
      </p>
    </div>

    <div class="form-item">
      <label for="profileAddress">
        {{ $t("Company.form.labels.address") }}:
      </label>
      <input type="text" v-model="form.address" id="profileAddress" />
    </div>

    <div class="form-item">
      <label for="profileZip"> {{ $t("Company.form.labels.zip") }}: </label>
      <input type="number" v-model="form.zip" id="profileZip" />
    </div>

    <div class="form-item">
      <label for="profilePlace"> {{ $t("Company.form.labels.place") }}: </label>
      <input type="text" v-model="form.place" id="profilePlace" />
    </div>

    <h2>{{ $t("Company.form.labels.employees") }}</h2>
    <fieldset
      class="form-item s-mrgt-04 w-100"
      v-bind:key="`employee-${index}`"
      v-for="(employee, index) in form.employees"
    >
      <legend>{{ `${employee.firstName} ${employee.lastName}` }}</legend>
      <label for="firstName"
        >{{ $t("Company.form.labels.firstName") }}:
        <span class="error">*</span></label
      >
      <input
        type="text"
        id="firstName"
        name="firstName"
        v-model="employee.firstName"
        required
      />
      <label for="lastName">{{ $t("Company.form.labels.lastName") }}: </label>
      <input
        type="text"
        id="lastName"
        name="lastName"
        v-model="employee.lastName"
      />
      <label for="email"
        >{{ $t("Company.form.labels.email") }}:
        <span class="error">*</span></label
      >
      <div class="multiselect-wrapper">
        <multiselect
          :value="employee.email ? employee : ''"
          :options="employees"
          type="email"
          id="employee_email"
          label="email"
          track-by="id"
          :placeholder="$t('Company.form.labels.employeeSelect')"
          open-direction="bottom"
          :clear-on-select="true"
          :max-height="600"
          :show-no-results="false"
          :close-on-select="true"
          :hide-selected="true"
          :taggable="true"
          :multiple="false"
          :tag-placeholder="$t('Company.form.labels.employeeSelect')"
          @tag="addUnknownUser($event, employee)"
          @select="addEmployeeEmail($event, employee)"
          :allow-empty="false"
          :deselect-label="$t('Movie.form.labels.creditCreation.create')"
        >
        </multiselect>
      </div>

      <label for="role">{{ $t("Company.form.labels.role") }}: </label>
      <input type="text" id="role" name="role" v-model="employee.role" />
      <div class="form-item-input flex--row s-mrgt-03 align-center">
        <label for="contactPerson"
          >{{ $t("Company.form.labels.contactPerson") }}:
        </label>
        <input
          type="checkbox"
          id="contactPerson"
          name="contactPerson"
          v-model="employee.contactPerson"
        />
      </div>
      <input type="hidden" name="id" v-model="employee.id" />
      <input
        class="form-button"
        type="button"
        :value="$t('Company.form.labels.remove')"
        @click="removeEmployee(index)"
      />
    </fieldset>

    <input
      class="form-button"
      @click="addEmployee()"
      type="button"
      :value="$t('Company.form.labels.add')"
    />

    <MemberProfileCredits
      v-if="form.companyCredits"
      v-model="form.companyCredits"
    />

    <h2 class="s-mrgb-05 s-mrgt-07">
      {{ $t("Company.form.labels.serviceSelect") }}
    </h2>
    <div v-if="form.availableServices" class="form-item">
      <div class="multiselect-wrapper">
        <multiselect
          v-model="form.services"
          :placeholder="
            $t('MemberProfile.form.memberOccupations.chooseOccupation')
          "
          :custom-label="getService"
          open-direction="bottom"
          :options="availableServicesFormatted"
          :multiple="true"
          :clear-on-select="false"
          :close-on-select="false"
          :max-height="600"
          :show-no-results="false"
          :hide-selected="true"
          track-by="id"
        >
          <template slot="tag" slot-scope="{ option }">
            <span class="custom__tag">
              <span v-if="option.occupation">{{
                option.occupation.description
              }}</span>
              <span
                class="custom__remove"
                @click="deleteService(form.services.indexOf(option))"
              >
                <svg-icon name="times" class="icon icon--small" />
              </span>
            </span>
          </template>
          <template slot="maxElements">
            <span class="multiselect__content">
              <span>{{
                $t("MemberProfile.form.memberOccupations.maxElements")
              }}</span>
            </span>
          </template>
        </multiselect>
        <p class="tip text-small">
          {{ $t("Company.form.helpTexts.adminCheck") }}
        </p>
      </div>
    </div>
    <div>
      <p class="text-bold">
        {{ $t("Company.form.labels.serviceDisplay") }}
      </p>
      <p class="text-small">
        {{ $t("MemberProfile.form.memberOccupations.tip") }}
      </p>
      <div class="flex flex--column around-md col-t-md-6 s-pdg-00">
        <table class="table s-mrgt-06 s-mrgb-08">
          <thead class="thead-dark text-left">
            <tr>
              <th>
                {{ $t("MemberProfile.form.memberOccupations.table.sort") }}
              </th>
              <th>
                {{ $t("Company.form.services.service") }}
              </th>
              <th>
                {{ $t("MemberProfile.form.memberOccupations.table.delete") }}
              </th>
            </tr>
          </thead>
          <draggable
            v-model="form.services"
            tag="tbody"
            @change="updateServicesListSortOrder"
            class="drag"
          >
            <tr v-for="(service, index) in form.services" :key="index">
              <td>
                <svg-icon name="arrow-move" class="icon icon--small" />
              </td>
              <td>
                {{ service.occupation ? service.occupation.description : null }}
              </td>
              <td @click="deleteService(index)">
                <svg-icon name="times" class="icon icon--small" />
              </td>
            </tr>
          </draggable>
        </table>
      </div>

      <Credits
        v-if="form.publishedMovieCredits && form.publishedMovieCredits.length"
        :movie-credits="form.publishedMovieCredits"
        :hidden-movies="form.hiddenMovies"
        @hideMovie="updateHiddenMovies"
      />
    </div>

    <div class="flex justify-between s-mrgt-09">
      <button type="button" class="button s-mrgr-05" @click.prevent="cancel">
        {{ $t("Company.form.buttons.cancel") }}
      </button>
      <button
        class="button"
        type="submit"
        :disabled="!isFormValid || isTextTooLong"
      >
        {{ $t("Company.form.buttons.submit") }}
      </button>
    </div>
  </form>
</template>

<script>
import MemberProfileUploadPortrait from "../../MemberProfiles/MemberProfileEditComponents/MemberProfilePortrait/MemberProfileUploadPortrait";
import MemberProfileCredits from "../../MemberProfiles/MemberProfileEditComponents/MemberProfileCredits/MemberProfileCredits";
import Credits from "../../MemberProfiles/MemberProfileEditComponents/Credits";
import { Employee } from "../../../models/Employee.models";
import draggable from "vuedraggable";
import { getEmployees } from "@/services/User.service.js";

export default {
  name: "CompanyEditForm",
  components: {
    MemberProfileUploadPortrait,
    MemberProfileCredits,
    Credits,
    draggable
  },
  data() {
    return {
      form: {},
      cleanedForm: {},
      portrait: null,
      portraitChanged: false,
      employees: []
    };
  },
  props: {
    companyProfile: null
  },
  computed: {
    isUpdate() {
      return !!this.form.id;
    },
    isFormValid() {
      return (
        this.form.name !== "" && this.companyCreditsValid && this.employeesValid
      );
    },
    isTextTooLong() {
      return this.form.description ? this.form.description.length > 700 : false;
    },
    countCharacters() {
      return this.form.description ? this.form.description.length : 0;
    },
    companyCreditsValid() {
      let creditsValid = true;
      if (this.form.companyCredits?.length) {
        this.form.companyCredits.forEach(credit => {
          creditsValid =
            credit.title !== "" &&
            credit.year !== "" &&
            credit.memberType &&
            credit.memberFormat &&
            credit.memberStyles &&
            credit.memberStyles.length &&
            credit.credit;
        });
      }
      return creditsValid;
    },
    availableServicesFormatted() {
      return this.form.availableServices.map(service => {
        return {
          id: service.id ? service.id : null,
          isUnlocked: false,
          occupation: service
        };
      });
    },
    employeesValid() {
      let employeesValid = true;
      if (this.form.employees?.length) {
        this.form.employees.forEach(employee => {
          employeesValid = employee.firstName !== "" && employee.email !== "";
        });
      }
      return employeesValid;
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    cleanCompanyCreditData(companyCredits) {
      return companyCredits.map(credit => {
        return {
          id: credit.id ? credit.id : null,
          title: credit.title,
          year: credit.year,
          direction: credit.direction,
          production: credit.production,
          credit: credit.credit,
          memberType: credit.memberType.id,
          memberFormat: credit.memberFormat.id,
          memberStyles: credit.memberStyles.map(style => style.id),
          company: this.companyProfile ? this.companyProfile.id : null
        };
      });
    },
    cleanCompanyServices(services) {
      return services.map(service => {
        return {
          id: service.occupation.id,
          credit: service.occupation.credit,
          group: service.occupation.group,
          movie_format: service.occupation.movie_format,
          movie_type: service.occupation.movie_type,
          movie_style: service.occupation.movie_style
        };
      });
    },
    updateOccupationsListSortOrder() {
      this.form.occupations = [...this.form.occupations].map((item, index) => {
        let newSort = index;
        item.hasChanged = item.order !== newSort;
        if (item.hasChanged) {
          item.order = newSort;
          newSort = index + 1;
        }
        return item;
      });
    },
    updateServicesListSortOrder() {
      this.form.services = [...this.form.services].map((item, index) => {
        let newSort = index;
        item.hasChanged = item.order !== newSort;
        if (item.hasChanged) {
          item.order = newSort;
          newSort = index + 1;
        }
        return item;
      });
    },
    deleteService(index) {
      this.form.services.splice(index, 1);
    },
    getService(option) {
      return `${option.occupation ? option.occupation.description : null}`;
    },
    addUnknownUser(newUser, employee) {
      employee.email = newUser;
    },
    addEmployee() {
      this.form.employees.push(new Employee());
    },
    addEmployeeEmail(newEmployee, employee) {
      employee.email = newEmployee.email;
      employee.firstName = newEmployee.firstName;
      employee.lastName = newEmployee.lastName;
    },
    removeEmployee(index) {
      this.form.employees.splice(index, 1);
    },
    submitCompanyProfile() {
      this.cleanedForm = JSON.parse(JSON.stringify(this.form));
      if (this.cleanedForm.companyCredits.length) {
        this.cleanedForm.companyCredits = this.cleanCompanyCreditData(
          this.form.companyCredits
        );
      }
      this.cleanedForm.services = this.cleanCompanyServices(this.form.services);
      this.$emit(
        "patchCompanyProfile",
        this.cleanedForm,
        this.portrait,
        this.portraitChanged
      );
    },
    setPortrait(portrait) {
      this.portrait = portrait;
      this.portraitChanged = true;
    },
    updateHiddenMovies(movieId, is_hidden) {
      if (is_hidden === true) {
        this.form.hiddenMovies.push(movieId);
      } else {
        let index = this.form.hiddenMovies.indexOf(movieId);
        if (index !== -1) {
          this.form.hiddenMovies.splice(index, 1);
        }
      }
    }
  },
  async mounted() {
    this.form = this.companyProfile;
    this.employees = await getEmployees();
  }
};
</script>
