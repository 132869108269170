import { MovieImage } from "./MovieImage.model";
import { Festival } from "./Festival.model";
import { Language } from "./Language.model";
import { Link } from "./Link.model";
import { Agency } from "./Agency.model";
import { Credit } from "./Credit.model";
import { Company } from "@/models/Company.models";

class Movie {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.title = payload.title || "";
    this.alternativeTitles = payload.alternativeTitles || "";
    this.synopsis = payload.synopsis || "";
    this.synopsisFr = payload.synopsisFr || "";
    this.poster = payload.poster || null;
    this.movieFormat = payload.movieFormat || {};
    this.movieType = payload.movieType || {};
    this.styles = payload.styles || [];
    this.completionStatus = payload.completionStatus || "";
    this.movieImages = payload.movieImages
      ? payload.movieImages.map(movieImage => {
          return new MovieImage(movieImage);
        })
      : [];
    this.duration = payload.duration || null;
    this.published = payload.published;
    this.publishedYear = payload.publishedYear || null;
    this.festivals = payload.festivals
      ? payload.festivals.map(festival => {
          return new Festival(festival);
        })
      : [];
    this.additionalFestivals = payload.additionalFestivals || "";
    this.awards = payload.awards || null;
    this.recordingFormat = payload.recordingFormat || "";
    this.screeningFormat = payload.screeningFormat || "";
    this.imageFormat = payload.imageFormat || "";
    this.audioFormat = payload.audioFormat || "";
    this.originalLang = payload.originalLang
      ? payload.originalLang.map(originalLang => {
          return new Language(originalLang);
        })
      : [];

    this.subtitle = payload.subtitle
      ? payload.subtitle.map(subtitleLang => {
          return new Language(subtitleLang);
        })
      : [];
    this.dubbedLang = payload.dubbedLang
      ? payload.dubbedLang.map(dubbedLang => {
          return new Language(dubbedLang);
        })
      : [];
    this.website = payload.website || "";
    this.productionLinks = payload.productionLinks
      ? payload.productionLinks.map(company => {
          return new Company(company);
        })
      : [];
    this.coProductionLinks = payload.coProductionLinks
      ? payload.coProductionLinks.map(company => {
          return new Company(company);
        })
      : [];
    this.rentalLink = new Link(payload.rentalLink) || null;
    this.worldSalesLink = new Link(payload.worldSalesLink) || null;
    this.vodLink = payload.vodLink || "";
    this.dvdBlurayLink = payload.dvdBlurayLink || "";
    this.inCinema = payload.inCinema || "";
    this.supporters = payload.supporters
      ? payload.supporters.map(supporter => {
          return new Agency(supporter);
        })
      : [];

    this.trailer = payload.trailer || "";
    this.mediaArtFestivals = payload.mediaArtFestivals || "";
    if (payload.movieCredits) {
      this.movieCredits =
        payload.movieCredits.map(credit => {
          return new Credit(credit);
        }) || null;
    } else {
      this.movieCredits = [];
    }
    if (payload.mainCredits) {
      this.mainCredits =
        payload.mainCredits.map(credit => {
          return new Credit(credit);
        }) || null;
    }
    this.createdBy = payload.createdBy || null;
    this.mandates = payload.mandates || null;
    if (payload.castMainRoleCredits) {
      this.castMainRoleCredits =
        payload.castMainRoleCredits.map(credit => {
          return new Credit(credit);
        }) || null;
    }
    this.draft = payload.draft;
    this.isan = payload.isan;
  }

  /**
   * this method is used to set the categories.
   * @param categories
   */
  setCategories(categories) {
    this.movieType = categories.type;
    this.movieFormat = categories.format.data ? categories.format.data : null;
    this.styles = categories.styles || [];
  }
}

export { Movie };
