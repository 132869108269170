var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.groupOccupationList, function(group, groupIndex) {
        return _c(
          "div",
          { key: `occupationGroup--${groupIndex}`, staticClass: "filterGroup" },
          [
            group.occupations.length
              ? _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "align-center flex",
                      on: {
                        click: function($event) {
                          return _vm.setFilterToggled(groupIndex)
                        }
                      }
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "icon icon--small s-mrgr-03",
                        attrs: { name: "chevron-down" }
                      }),
                      _c("h2", [_vm._v(_vm._s(group.name))])
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "filter-container visually-hidden" },
              _vm._l(group.occupations, function(occupation, occupationIndex) {
                return _c("div", { key: `occupation--${occupationIndex}` }, [
                  _c("div", { staticClass: "align-center flex s-mrgl-10" }, [
                    occupation.description !== ""
                      ? _c("div", { staticClass: "custom-checkbox" }, [
                          _c("input", {
                            attrs: {
                              id: `filter-checkbox--${groupIndex}--${occupationIndex}`,
                              type: "checkbox",
                              name: "filter-checkbox"
                            },
                            on: {
                              change: function($event) {
                                return _vm.setSelectedFilterList(occupation)
                              }
                            }
                          }),
                          _c("label", {
                            attrs: {
                              for: `filter-checkbox--${groupIndex}--${occupationIndex}`
                            }
                          })
                        ])
                      : _vm._e(),
                    _c("p", [_vm._v(_vm._s(occupation.description))])
                  ])
                ])
              }),
              0
            )
          ]
        )
      }),
      _c(
        "div",
        { staticClass: "align-center flex s-mrgt-05" },
        [
          _c("svg-icon", {
            staticClass: "icon filter-icon",
            attrs: { name: "times" }
          }),
          _c("a", { attrs: { href: "#" }, on: { click: _vm.resetFilter } }, [
            _vm._v(_vm._s(_vm.$t("MemberProfile.filters.deleteAll")))
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }