<template>
  <div v-if="hasMovieUpdatePermission(movie.createdBy)">
    <button
      class="button"
      type="button"
      @click="
        $router.push({
          name: 'MovieEditView',
          params: { Mid: movie.id }
        })
      "
    >
      {{ $t("Movie.detail.buttons.editMovie") }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "MovieEditButton",
  props: {
    movie: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("UserStore", ["hasMovieUpdatePermission"]),
    ...mapState("UserStore", ["authUser"])
  }
};
</script>
