<template>
  <div class="main col-xs-12 col-t-md-4">
    <!-- search-->
    <search-input @submitted="getInpit" />
    <button
      class="movie-list__filters-toggle flex align-center"
      @click="toggleFilters"
    >
      <svg-icon name="sliders-v" class="icon icon--small" />
      <span class="s-mrgl-02">Filter</span>
    </button>
    <div class="movie-list__filters">
      <div class="filters__header flex justify-between align-center s-mrgb-05">
        <p class="filters__title h1">Filter</p>
        <button class="filters__close align-center" @click="toggleFilters">
          <svg-icon name="times" class="icon icon--big" />
        </button>
      </div>
      <!-- year filter-->
      <div class="filters__year s-mrgb-1">
        <v-select
          :options="yearFilters"
          class="select"
          :placeholder="$t('Movie.list.yearSelection')"
          :item="item"
          :value="$store.state.selected"
          v-model="filters.selectedYear"
        >
          <template>
            <svg-icon name="times" class="icon icon--big" />
          </template>
        </v-select>
      </div>
      <br />
      <div class="filters__category s-mrgb-05">
        <span class="visually-hidden">{{ $t("Movie.list.categories") }}</span>
        <!-- Movie type filter-->
        <div
          v-for="(movieType, index) in movieTypeList"
          :key="`movie-type--filter---${index}-${movieType.id}`"
        >
          <label>
            <input
              type="checkbox"
              :value="movieType.id"
              v-model="filters.checkedMovieTypes"
            />
            {{ translateOrFallback(movieType, "name") }}</label
          >
        </div>
        <br />
        <!-- Movie format filter -->
        <div
          v-for="(movieFormat, index) in movieFormatList"
          :key="`movie-format--filter---${index}-${movieFormat.id}`"
        >
          <label>
            <input
              type="checkbox"
              :value="movieFormat.id"
              v-model="filters.checkedMovieFormats"
            />
            {{ translateOrFallback(movieFormat, "name") }}</label
          >
        </div>
        <br />
        <!-- Movie style filter -->
        <div
          v-for="(movieStyle, index) in movieStyleList"
          :key="`movie-style--filter---${index}-${movieStyle.id}`"
        >
          <label>
            <input
              type="checkbox"
              :value="movieStyle.id"
              v-model="filters.checkedMovieStyles"
            />
            {{ translateOrFallback(movieStyle, "name") }}</label
          >
        </div>
      </div>

      <!-- completion status filter-->
      <div class="filters__completion-status s-mrgb-05">
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              :value="1"
              v-model="filters.checkedCompletionStatuses"
            />
            {{ $t("Movie.list.completionStatus.completed") }}</label
          >
        </div>
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              :value="2"
              v-model="filters.checkedCompletionStatuses"
            />
            {{ $t("Movie.list.completionStatus.inProduction") }}</label
          >
        </div>
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              :value="3"
              v-model="filters.checkedCompletionStatuses"
            />
            {{ $t("Movie.list.completionStatus.inDevelopment") }}</label
          >
        </div>
      </div>
    </div>
    <div class="align-center flex s-mrgb-05">
      <svg-icon name="times" class="icon filter-icon" />
      <a @click="resetFilter" href="#">{{
        $t("MemberProfile.filters.deleteAll")
      }}</a>
    </div>
    <Support />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SearchInput from "../../SearchInput.vue";
import Support from "../../Support.vue";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

export default {
  components: { Support, SearchInput, vSelect },
  name: "MovieFilters",
  data() {
    return {
      searchQuery: "",
      params: {
        page: 1
      },
      filters: {
        checkedCompletionStatuses: [],
        checkedMovieTypes: [],
        checkedMovieFormats: [],
        checkedMovieStyles: [],
        selectedYear: "",
        submittedSearchQuery: ""
      }
    };
  },
  computed: {
    ...mapState("CategoryStore", [
      "movieTypeList",
      "movieFormatList",
      "movieStyleList"
    ]),
    ...mapState("MoviesStore", ["moviesList", "yearFilters"]),
    ...mapState("MandateStore", ["mandate"])
  },
  methods: {
    ...mapActions("CategoryStore", ["setCategories"]),
    toggleFilters() {
      const filters = document.querySelector(".movie-list__filters");
      filters.classList.toggle("is-open");
    },
    getInpit(value) {
      this.filters.submittedSearchQuery = value;
    },
    setFilters: async function(filters) {
      let mappedFilters = {
        page: this.params.page,
        completionStatuses: [],
        movieTypes: [],
        movieFormats: [],
        movieStyles: [],
        mandateParam: this.mandate.name,
        yearParam: "",
        searchParam: ""
      };
      if (filters) {
        mappedFilters.completionStatuses = this.filters.checkedCompletionStatuses.join(
          ","
        );
        mappedFilters.movieTypes = this.filters.checkedMovieTypes.join(",");
        mappedFilters.movieFormats = this.filters.checkedMovieFormats.join(",");
        mappedFilters.movieStyles = this.filters.checkedMovieStyles.join(",");
        mappedFilters.yearParam = this.filters.selectedYear;
        mappedFilters.searchParam = this.filters.submittedSearchQuery;
        this.$emit("setFilters", mappedFilters);
      }
    },
    /**
     * Returns the field with the current lang
     * Or falls back to the default langauge (DE)
     * example: movie[title_fr] || movie[title]
     */
    translateOrFallback: function(model, field) {
      return model[field + `_${this.$store.state.locale}`] || model[field];
    },
    resetFilter() {
      this.filters.checkedCompletionStatuses = [];
      this.filters.checkedMovieTypes = [];
      this.filters.checkedMovieFormats = [];
      this.filters.checkedMovieStyles = [];
      this.filters.selectedYear = "";
      this.filters.submittedSearchQuery = "";
    }
  },
  async mounted() {
    await this.setCategories(false);
  },
  watch: {
    filters: {
      async handler(value) {
        await this.setFilters(value);
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/base.scss";

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.visually-hidden + div {
  @extend .s-mrgt-03;
}
.movie-list__search {
  display: flex;
  margin-bottom: 1em;
  height: 4.2vh;
  .button {
    border-left: none;
  }
}
.filters__title {
  display: none;
}
.select {
  width: 215px;

  &::v-deep .vs__dropdown-toggle {
    border-radius: 0;
    border: 1px solid black;
  }
}
.select::v-deep .vs__clear {
  margin-right: 16px;
}
.movie-list__filters-toggle {
  display: none !important;
  margin-bottom: 10px;
  padding: 0.2em 0;
}
.filters__close {
  display: none !important;
}
.filters__header {
  z-index: 2;
}
.filter-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
@media only screen and (max-width: 850px) {
  .select {
    width: 100%;
  }
  .select::v-deep .vs__clear {
    margin-right: 20px;
  }
  .movie-list__filters {
    transform: translateY(-100%);
    position: fixed;
    overflow: scroll;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    transition: 0.4s transform ease-in-out;
    top: 0;
    left: 0;
    padding: 2rem;
    // margin-top: 4em;
    z-index: 200;
    &.is-open {
      transform: translateY(0);
    }
    .filters__title {
      display: block;
    }
  }
  .movie-list__filters-toggle {
    display: block !important;
    background: transparent;
    border: 1px solid $color-black;
  }
  .filters__close {
    display: flex !important;
    background: transparent;
    border: none;
  }
  .movie-list__search {
    display: flex;
    margin-bottom: 0.3em;
    .search_box {
      width: 100%;
    }
  }
}
</style>
