var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.companyProfile
    ? _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitCompanyProfile.apply(null, arguments)
            },
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
            }
          }
        },
        [
          _c("div", { staticClass: "form-item" }, [
            _c("label", { attrs: { for: "companyName" } }, [
              _vm._v(_vm._s(_vm.$t("Company.form.labels.name")) + ": "),
              _c("span", { staticClass: "error" }, [_vm._v("*")])
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name"
                }
              ],
              attrs: { type: "text", id: "companyName" },
              domProps: { value: _vm.form.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "name", $event.target.value)
                }
              }
            })
          ]),
          _c("MemberProfileUploadPortrait", {
            attrs: {
              value: _vm.form.portrait,
              "portrait-changed": _vm.portraitChanged
            },
            on: { setPortrait: _vm.setPortrait }
          }),
          _c("div", { staticClass: "form-item" }, [
            _c("label", { attrs: { for: "profileText" } }, [
              _vm._v(
                " " + _vm._s(_vm.$t("Company.form.labels.description")) + ": "
              )
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.description,
                  expression: "form.description"
                }
              ],
              attrs: { maxlength: 700, id: "profileText", rows: "15" },
              domProps: { value: _vm.form.description },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "description", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "flex justify-between w-100" }, [
              _c("div", { staticClass: "tip text-small" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("Company.form.helpTexts.textTip")) + " "
                )
              ]),
              _c("div", { staticClass: "tip text-small" }, [
                _vm._v(_vm._s(_vm.countCharacters) + "/700")
              ])
            ])
          ]),
          _c("div", { staticClass: "form-item" }, [
            _c("label", { attrs: { for: "profileWebsite" } }, [
              _vm._v(" " + _vm._s(_vm.$t("Company.form.labels.website")) + ": ")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.website,
                  expression: "form.website"
                }
              ],
              attrs: { type: "url", id: "profileWebsite" },
              domProps: { value: _vm.form.website },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "website", $event.target.value)
                }
              }
            }),
            _c("p", { staticClass: "tip text-small" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("Movie.form.helpText.correctFormat")) + " "
              )
            ])
          ]),
          _c("div", { staticClass: "form-item" }, [
            _c("label", { attrs: { for: "profilePhone" } }, [
              _vm._v(" " + _vm._s(_vm.$t("Company.form.labels.phone")) + ": ")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.phone,
                  expression: "form.phone"
                }
              ],
              attrs: { type: "tel", id: "profilePhone" },
              domProps: { value: _vm.form.phone },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "phone", $event.target.value)
                }
              }
            }),
            _c("p", { staticClass: "tip text-small" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("Generic.form.helpText.phoneNumber")) + " "
              )
            ])
          ]),
          _c("div", { staticClass: "form-item" }, [
            _c("label", { attrs: { for: "profileAddress" } }, [
              _vm._v(" " + _vm._s(_vm.$t("Company.form.labels.address")) + ": ")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.address,
                  expression: "form.address"
                }
              ],
              attrs: { type: "text", id: "profileAddress" },
              domProps: { value: _vm.form.address },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "address", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-item" }, [
            _c("label", { attrs: { for: "profileZip" } }, [
              _vm._v(" " + _vm._s(_vm.$t("Company.form.labels.zip")) + ": ")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.zip,
                  expression: "form.zip"
                }
              ],
              attrs: { type: "number", id: "profileZip" },
              domProps: { value: _vm.form.zip },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "zip", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-item" }, [
            _c("label", { attrs: { for: "profilePlace" } }, [
              _vm._v(" " + _vm._s(_vm.$t("Company.form.labels.place")) + ": ")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.place,
                  expression: "form.place"
                }
              ],
              attrs: { type: "text", id: "profilePlace" },
              domProps: { value: _vm.form.place },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "place", $event.target.value)
                }
              }
            })
          ]),
          _c("h2", [_vm._v(_vm._s(_vm.$t("Company.form.labels.employees")))]),
          _vm._l(_vm.form.employees, function(employee, index) {
            return _c(
              "fieldset",
              {
                key: `employee-${index}`,
                staticClass: "form-item s-mrgt-04 w-100"
              },
              [
                _c("legend", [
                  _vm._v(_vm._s(`${employee.firstName} ${employee.lastName}`))
                ]),
                _c("label", { attrs: { for: "firstName" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("Company.form.labels.firstName")) + ": "
                  ),
                  _c("span", { staticClass: "error" }, [_vm._v("*")])
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: employee.firstName,
                      expression: "employee.firstName"
                    }
                  ],
                  attrs: {
                    type: "text",
                    id: "firstName",
                    name: "firstName",
                    required: ""
                  },
                  domProps: { value: employee.firstName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(employee, "firstName", $event.target.value)
                    }
                  }
                }),
                _c("label", { attrs: { for: "lastName" } }, [
                  _vm._v(_vm._s(_vm.$t("Company.form.labels.lastName")) + ": ")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: employee.lastName,
                      expression: "employee.lastName"
                    }
                  ],
                  attrs: { type: "text", id: "lastName", name: "lastName" },
                  domProps: { value: employee.lastName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(employee, "lastName", $event.target.value)
                    }
                  }
                }),
                _c("label", { attrs: { for: "email" } }, [
                  _vm._v(_vm._s(_vm.$t("Company.form.labels.email")) + ": "),
                  _c("span", { staticClass: "error" }, [_vm._v("*")])
                ]),
                _c(
                  "div",
                  { staticClass: "multiselect-wrapper" },
                  [
                    _c("multiselect", {
                      attrs: {
                        value: employee.email ? employee : "",
                        options: _vm.employees,
                        type: "email",
                        id: "employee_email",
                        label: "email",
                        "track-by": "id",
                        placeholder: _vm.$t(
                          "Company.form.labels.employeeSelect"
                        ),
                        "open-direction": "bottom",
                        "clear-on-select": true,
                        "max-height": 600,
                        "show-no-results": false,
                        "close-on-select": true,
                        "hide-selected": true,
                        taggable: true,
                        multiple: false,
                        "tag-placeholder": _vm.$t(
                          "Company.form.labels.employeeSelect"
                        ),
                        "allow-empty": false,
                        "deselect-label": _vm.$t(
                          "Movie.form.labels.creditCreation.create"
                        )
                      },
                      on: {
                        tag: function($event) {
                          return _vm.addUnknownUser($event, employee)
                        },
                        select: function($event) {
                          return _vm.addEmployeeEmail($event, employee)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c("label", { attrs: { for: "role" } }, [
                  _vm._v(_vm._s(_vm.$t("Company.form.labels.role")) + ": ")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: employee.role,
                      expression: "employee.role"
                    }
                  ],
                  attrs: { type: "text", id: "role", name: "role" },
                  domProps: { value: employee.role },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(employee, "role", $event.target.value)
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-item-input flex--row s-mrgt-03 align-center"
                  },
                  [
                    _c("label", { attrs: { for: "contactPerson" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("Company.form.labels.contactPerson")) +
                          ": "
                      )
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: employee.contactPerson,
                          expression: "employee.contactPerson"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "contactPerson",
                        name: "contactPerson"
                      },
                      domProps: {
                        checked: Array.isArray(employee.contactPerson)
                          ? _vm._i(employee.contactPerson, null) > -1
                          : employee.contactPerson
                      },
                      on: {
                        change: function($event) {
                          var $$a = employee.contactPerson,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  employee,
                                  "contactPerson",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  employee,
                                  "contactPerson",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(employee, "contactPerson", $$c)
                          }
                        }
                      }
                    })
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: employee.id,
                      expression: "employee.id"
                    }
                  ],
                  attrs: { type: "hidden", name: "id" },
                  domProps: { value: employee.id },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(employee, "id", $event.target.value)
                    }
                  }
                }),
                _c("input", {
                  staticClass: "form-button",
                  attrs: {
                    type: "button",
                    value: _vm.$t("Company.form.labels.remove")
                  },
                  on: {
                    click: function($event) {
                      return _vm.removeEmployee(index)
                    }
                  }
                })
              ]
            )
          }),
          _c("input", {
            staticClass: "form-button",
            attrs: { type: "button", value: _vm.$t("Company.form.labels.add") },
            on: {
              click: function($event) {
                return _vm.addEmployee()
              }
            }
          }),
          _vm.form.companyCredits
            ? _c("MemberProfileCredits", {
                model: {
                  value: _vm.form.companyCredits,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "companyCredits", $$v)
                  },
                  expression: "form.companyCredits"
                }
              })
            : _vm._e(),
          _c("h2", { staticClass: "s-mrgb-05 s-mrgt-07" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("Company.form.labels.serviceSelect")) + " "
            )
          ]),
          _vm.form.availableServices
            ? _c("div", { staticClass: "form-item" }, [
                _c(
                  "div",
                  { staticClass: "multiselect-wrapper" },
                  [
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          placeholder: _vm.$t(
                            "MemberProfile.form.memberOccupations.chooseOccupation"
                          ),
                          "custom-label": _vm.getService,
                          "open-direction": "bottom",
                          options: _vm.availableServicesFormatted,
                          multiple: true,
                          "clear-on-select": false,
                          "close-on-select": false,
                          "max-height": 600,
                          "show-no-results": false,
                          "hide-selected": true,
                          "track-by": "id"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "tag",
                              fn: function({ option }) {
                                return [
                                  _c("span", { staticClass: "custom__tag" }, [
                                    option.occupation
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              option.occupation.description
                                            )
                                          )
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "custom__remove",
                                        on: {
                                          click: function($event) {
                                            _vm.deleteService(
                                              _vm.form.services.indexOf(option)
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "icon icon--small",
                                          attrs: { name: "times" }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3357994505
                        ),
                        model: {
                          value: _vm.form.services,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "services", $$v)
                          },
                          expression: "form.services"
                        }
                      },
                      [
                        _c("template", { slot: "maxElements" }, [
                          _c("span", { staticClass: "multiselect__content" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "MemberProfile.form.memberOccupations.maxElements"
                                  )
                                )
                              )
                            ])
                          ])
                        ])
                      ],
                      2
                    ),
                    _c("p", { staticClass: "tip text-small" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("Company.form.helpTexts.adminCheck")) +
                          " "
                      )
                    ])
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "div",
            [
              _c("p", { staticClass: "text-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("Company.form.labels.serviceDisplay")) +
                    " "
                )
              ]),
              _c("p", { staticClass: "text-small" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("MemberProfile.form.memberOccupations.tip")) +
                    " "
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "flex flex--column around-md col-t-md-6 s-pdg-00"
                },
                [
                  _c(
                    "table",
                    { staticClass: "table s-mrgt-06 s-mrgb-08" },
                    [
                      _c("thead", { staticClass: "thead-dark text-left" }, [
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "MemberProfile.form.memberOccupations.table.sort"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("th", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("Company.form.services.service")
                                ) +
                                " "
                            )
                          ]),
                          _c("th", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "MemberProfile.form.memberOccupations.table.delete"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ])
                      ]),
                      _c(
                        "draggable",
                        {
                          staticClass: "drag",
                          attrs: { tag: "tbody" },
                          on: { change: _vm.updateServicesListSortOrder },
                          model: {
                            value: _vm.form.services,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "services", $$v)
                            },
                            expression: "form.services"
                          }
                        },
                        _vm._l(_vm.form.services, function(service, index) {
                          return _c("tr", { key: index }, [
                            _c(
                              "td",
                              [
                                _c("svg-icon", {
                                  staticClass: "icon icon--small",
                                  attrs: { name: "arrow-move" }
                                })
                              ],
                              1
                            ),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    service.occupation
                                      ? service.occupation.description
                                      : null
                                  ) +
                                  " "
                              )
                            ]),
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.deleteService(index)
                                  }
                                }
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "icon icon--small",
                                  attrs: { name: "times" }
                                })
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm.form.publishedMovieCredits &&
              _vm.form.publishedMovieCredits.length
                ? _c("Credits", {
                    attrs: {
                      "movie-credits": _vm.form.publishedMovieCredits,
                      "hidden-movies": _vm.form.hiddenMovies
                    },
                    on: { hideMovie: _vm.updateHiddenMovies }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "flex justify-between s-mrgt-09" }, [
            _c(
              "button",
              {
                staticClass: "button s-mrgr-05",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.cancel.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("Company.form.buttons.cancel")) + " "
                )
              ]
            ),
            _c(
              "button",
              {
                staticClass: "button",
                attrs: {
                  type: "submit",
                  disabled: !_vm.isFormValid || _vm.isTextTooLong
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("Company.form.buttons.submit")) + " "
                )
              ]
            )
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }