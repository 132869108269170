var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h2", { staticClass: "s-mrgb-05" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$t("MemberProfile.form.labels.memberOccupations")) +
          " "
      )
    ]),
    _c("div", { staticClass: "form-item" }, [
      _c(
        "div",
        { staticClass: "multiselect-wrapper" },
        [
          _c(
            "multiselect",
            {
              attrs: {
                "custom-label": _vm.getOccupation,
                "track-by": "occupation",
                placeholder: _vm.$t(
                  "MemberProfile.form.memberOccupations.chooseOccupation"
                ),
                "open-direction": "bottom",
                options: _vm.memberOccupationsList,
                multiple: true,
                taggable: true,
                "clear-on-select": false,
                "close-on-select": false,
                "max-height": 600,
                "show-no-results": false,
                "hide-selected": true,
                max: 7,
                "group-values": "occupations",
                "group-label": "title",
                "group-select": false,
                "tag-placeholder": _vm.$t(
                  "Movie.form.labels.creditCreation.placeholder"
                )
              },
              on: { close: _vm.updateListSortOrder, remove: _vm.remove },
              scopedSlots: _vm._u([
                {
                  key: "tag",
                  fn: function({ option, remove }) {
                    return [
                      _c("span", { staticClass: "custom__tag" }, [
                        _c("span", [
                          _vm._v(_vm._s(option.occupation.description))
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "custom__remove",
                            on: {
                              click: function($event) {
                                return remove(option)
                              }
                            }
                          },
                          [
                            _c("svg-icon", {
                              staticClass: "icon icon--small",
                              attrs: { name: "times" }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.memberOccupations,
                callback: function($$v) {
                  _vm.memberOccupations = $$v
                },
                expression: "memberOccupations"
              }
            },
            [
              _c("template", { slot: "maxElements" }, [
                _c("span", { staticClass: "multiselect__content" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "MemberProfile.form.memberOccupations.maxElements"
                        )
                      )
                    )
                  ])
                ])
              ])
            ],
            2
          ),
          _c("p", { staticClass: "tip text-small" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("MemberProfile.form.memberOccupations.helpText")
                ) +
                " "
            )
          ])
        ],
        1
      )
    ]),
    _c("div", [
      _c("p", { staticClass: "text-bold" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("MemberProfile.form.memberOccupations.title")) +
            " "
        )
      ]),
      _c("p", { staticClass: "text-small" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("MemberProfile.form.memberOccupations.tip")) + " "
        )
      ]),
      _c(
        "div",
        { staticClass: "flex flex--column around-md col-t-md-6 s-pdg-00" },
        [
          _c(
            "table",
            { staticClass: "table s-mrgt-06 s-mrgb-08" },
            [
              _c("thead", { staticClass: "thead-dark text-left" }, [
                _c("tr", [
                  _c("th", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "MemberProfile.form.memberOccupations.table.sort"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("th", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "MemberProfile.form.memberOccupations.table.occupation"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("th", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "MemberProfile.form.memberOccupations.table.unlocked"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("th", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "MemberProfile.form.memberOccupations.table.delete"
                          )
                        ) +
                        " "
                    )
                  ])
                ])
              ]),
              _c(
                "draggable",
                {
                  staticClass: "drag",
                  attrs: { tag: "tbody" },
                  on: { change: _vm.updateListSortOrder },
                  model: {
                    value: _vm.memberOccupations,
                    callback: function($$v) {
                      _vm.memberOccupations = $$v
                    },
                    expression: "memberOccupations"
                  }
                },
                _vm._l(_vm.memberOccupations, function(credit, index) {
                  return _c("tr", { key: index }, [
                    _c(
                      "td",
                      [
                        _c("svg-icon", {
                          staticClass: "icon icon--small",
                          attrs: { name: "arrow-move" }
                        })
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(credit.occupation.description))]),
                    credit.isUnlocked
                      ? _c(
                          "td",
                          [
                            _c("svg-icon", {
                              staticClass: "icon icon--small",
                              attrs: { name: "yes-symbol" }
                            })
                          ],
                          1
                        )
                      : _c(
                          "td",
                          [
                            _c("svg-icon", {
                              staticClass: "icon icon--small",
                              attrs: { name: "times" }
                            })
                          ],
                          1
                        ),
                    _c(
                      "td",
                      {
                        on: {
                          click: function($event) {
                            return _vm.deleteCredit(index)
                          }
                        }
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "icon icon--small",
                          attrs: { name: "times" }
                        })
                      ],
                      1
                    )
                  ])
                }),
                0
              )
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }