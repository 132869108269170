var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.isLoading
      ? _c("div", { staticClass: "movie-list" }, [
          _c(
            "div",
            { staticClass: "row s-mrgb-10" },
            [
              _c("div", { staticClass: "col-xs-12 col-t-md-4" }, [
                _c("h1", { staticClass: "s-mrgb-05" }, [
                  _vm._v(_vm._s(_vm.$t("Movie.userMovieList")))
                ])
              ]),
              _c("div", { staticClass: "col-xs-12 col-t-md-8" }),
              _c("MoviesList", {
                staticClass: "col-xs-12",
                attrs: {
                  "is-user-list": true,
                  "movie-list": _vm.moviesListForPage
                }
              }),
              _vm.isMoviesListPaged
                ? _c(
                    "div",
                    { staticClass: "col-xs-12 col-t-md-8 s-mrgt-05" },
                    [
                      _c("Pagination", {
                        attrs: {
                          "current-page": _vm.page,
                          "page-count": _vm.pagedMoviesList.length
                        },
                        on: { setCurrentPage: _vm.setCurrentPage }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.draftMoviesListForPage.length
            ? _c(
                "div",
                { staticClass: "flex flex--column" },
                [
                  _c("div", { staticClass: "s-mrgb-05" }, [
                    _c("h1", [_vm._v(_vm._s(_vm.$t("Movie.draftMovies")))])
                  ]),
                  _c("MoviesList", {
                    attrs: {
                      "movie-list": _vm.draftMoviesListForPage,
                      draft: true
                    }
                  }),
                  _vm.isMoviesDraftListPaged
                    ? _c(
                        "div",
                        { staticClass: "col-xs-12 col-t-md-8 s-mrgt-05" },
                        [
                          _c("Pagination", {
                            attrs: {
                              "current-page": _vm.pageDraft,
                              "page-count": _vm.pagedMoviesDraftList.length
                            },
                            on: { setCurrentPage: _vm.setCurrentDraftPage }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }