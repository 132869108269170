<template>
  <div>
    <label class="typo__label" :for="id">{{ labelText }}:</label>
    <div class="multiselect-wrapper">
      <multiselect
        v-model="values"
        :id="id"
        :label="displayValueKey"
        track-by="id"
        :placeholder="placeholder"
        open-direction="bottom"
        :options="options"
        :multiple="true"
        :searchable="true"
        :close-on-select="false"
        :clear-on-select="false"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        :taggable="true"
        @tag="addUnknownOption($event)"
      >
        <template slot="tag" slot-scope="{ option, remove }"
          ><span class="custom__tag"
            ><span>{{ option[displayValueKey] }}</span
            ><span class="custom__remove" @click="remove(option)"
              ><svg-icon name="times" class="icon icon--small"/></span></span
        ></template>
        <span v-if="!values.length" slot="noResult">{{
          elementNotFoundText
        }}</span>
      </multiselect>
      <p class="tip text-small">
        {{ liveSearchText }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "multi-select-form",
  props: {
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    liveSearchText: {
      type: String,
      required: true
    },
    elementNotFoundText: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    },
    displayValueKey: {
      type: String,
      required: true
    }
  },
  methods: {
    addUnknownOption(newOption) {
      let obj = {};
      obj[this.displayValueKey] = newOption;
      this.options.push(obj);
      this.values.push(obj);
      this.$emit("input", this.values);
    }
  },
  computed: {
    values: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
