<template>
  <!-- Format -->
  <div class="form-item">
    <label for="movieFormat"
      >{{ $t("Movie.form.labels.categories.format") }}:<span
        v-if="!isRequired"
        class="error"
        >*</span
      ></label
    >
    <select
      :multiple="false"
      id="movieFormat"
      v-model="movieFormat"
      :disabled="!isEditable"
    >
      <option
        v-for="(movieFormat, key) in movieFormatList"
        :value="movieFormat.id"
        :key="key"
      >
        {{ translateOrFallback(movieFormat, "name") }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MovieFormat",
  props: {
    value: null,
    formObject: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState("CategoryStore", ["movieFormatList"]),
    movieFormat: {
      get() {
        return this.value.id;
      },
      set(newVal) {
        if (newVal) {
          this.$emit(
            "input",
            this.movieFormatList.find(movieFormat => movieFormat.id === newVal)
          );
        } else {
          this.$emit("input", {});
        }
      }
    },
    isEditable() {
      if (this.formObject.movieType.name === "Medienkunst") {
        this.clearMovieFormat();
        return false;
      }
      return true;
    },
    isRequired() {
      return this.formObject.movieType.name === "Medienkunst";
    }
  },
  methods: {
    translateOrFallback: function(model, field) {
      return model[field + `_${this.$store.state.locale}`] || model[field];
    },
    clearMovieFormat() {
      this.movieFormat = null;
    }
  }
};
</script>
