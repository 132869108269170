import { getCompanies } from "@/services/Company.service.js";

export default {
  namespaced: true,
  state: {
    productionCompanyList: []
  },
  mutations: {
    updateProductionCompanyList(state, data) {
      state.productionCompanyList = data;
    }
  },
  actions: {
    async setProductionCompanyList({ commit }) {
      const productionCompanies = await getCompanies();
      commit("updateProductionCompanyList", productionCompanies);
    }
  }
};
