var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "movie-website form-item" }, [
    _c("label", { attrs: { for: "website" } }, [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.website")) + ":")
    ]),
    _c("div", { staticClass: "form-item-input flex--col" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.movieWebsite,
            expression: "movieWebsite"
          }
        ],
        attrs: { type: "url", id: "website" },
        domProps: { value: _vm.movieWebsite },
        on: {
          input: function($event) {
            if ($event.target.composing) return
            _vm.movieWebsite = $event.target.value
          }
        }
      }),
      _c("p", { staticClass: "tip text-small" }, [
        _vm._v(" " + _vm._s(_vm.$t("Movie.form.helpText.correctFormat")) + " ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }