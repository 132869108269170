var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", { attrs: { for: "movieFormat" } }, [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.categories.format")) + ":"),
      !_vm.isRequired
        ? _c("span", { staticClass: "error" }, [_vm._v("*")])
        : _vm._e()
    ]),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.movieFormat,
            expression: "movieFormat"
          }
        ],
        attrs: {
          multiple: false,
          id: "movieFormat",
          disabled: !_vm.isEditable
        },
        on: {
          change: function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.movieFormat = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          }
        }
      },
      _vm._l(_vm.movieFormatList, function(movieFormat, key) {
        return _c("option", { key: key, domProps: { value: movieFormat.id } }, [
          _vm._v(
            " " + _vm._s(_vm.translateOrFallback(movieFormat, "name")) + " "
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }