var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-layout" },
    [
      !_vm.isLoading
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xs-12 col-t-md-4" }, [
              _c("h1", { staticClass: "s-mrgb-06" }, [
                _vm._v(_vm._s(_vm.$t("Company.title")))
              ])
            ]),
            _c(
              "div",
              { staticClass: "col-xs-12 col-t-md-8 flex t-md-justify-end" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "h1 s-mrgb-05",
                    attrs: {
                      to: {
                        name: "Companies"
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("Movie.detail.toOverview")) + " ")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "col-xs-12 col-t-md-8" }, [
              _c("h2", { staticClass: "h1" }, [
                _vm._v(_vm._s(_vm.company.name))
              ]),
              _c("div", { staticClass: "row col-xs-12" }, [
                _vm.company.services.length
                  ? _c("div", { staticClass: "s-mrgt-02" }, [
                      _c("p", { staticClass: "s-mrgr-02" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Company.services")) +
                            ": " +
                            _vm._s(_vm.serviceNameList) +
                            " "
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "text-large s-mrgt-04 s-mrgb-06" }, [
                _c("p", [_vm._v(_vm._s(_vm.company.description))])
              ]),
              _c("div", [
                _c("div", { staticClass: "s-mrgt-05" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.$t("Company.contact")))]),
                  _vm.company.website
                    ? _c(
                        "div",
                        { staticClass: "flex align-center" },
                        [
                          _c("svg-icon", {
                            staticClass: "icon icon--small",
                            attrs: { name: "arrow-long-right" }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "s-mrgl-02",
                              attrs: {
                                href: _vm.company.website,
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Company.website")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.company.email
                    ? _c(
                        "div",
                        { staticClass: "flex align-center" },
                        [
                          _c("svg-icon", {
                            staticClass: "icon icon--small",
                            attrs: { name: "arrow-long-right" }
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "s-mrgl-02",
                              attrs: {
                                href: `mailto:${_vm.company.email}`,
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Company.email")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", [
                    _c("p", [_vm._v(_vm._s(_vm.company.address))]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.company.zip) +
                          " " +
                          _vm._s(_vm.company.place)
                      )
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.company.phone))])
                  ])
                ]),
                _vm.company.employees.length
                  ? _c(
                      "div",
                      { staticClass: "s-mrgt-05" },
                      [
                        _c("h2", [_vm._v(_vm._s(_vm.$t("Company.employees")))]),
                        _vm._l(_vm.company.employees, function(
                          employee,
                          index
                        ) {
                          return _c(
                            "div",
                            {
                              key: `employee--${index}---${employee.id}`,
                              staticClass: "flex align-center"
                            },
                            [
                              employee.user && employee.user.memberProfile
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass: "flex align-center",
                                      attrs: {
                                        to: {
                                          name: "MemberProfileDetailView",
                                          params: {
                                            MpId: employee.user.memberProfile
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "icon icon--small",
                                        attrs: { name: "arrow-long-right" }
                                      }),
                                      _c("p", { staticClass: "s-mrg-02" }, [
                                        _vm._v(
                                          " " + _vm._s(employee.name) + " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _c("p", { staticClass: "s-mrg-02" }, [
                                    _vm._v(" " + _vm._s(employee.name) + " ")
                                  ]),
                              _c(
                                "a",
                                { attrs: { href: `mailto:${employee.email}` } },
                                [_vm._v(_vm._s(employee.email))]
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ])
            ]),
            _vm.company.portrait
              ? _c("div", { staticClass: "col-xs-12 col-t-md-4" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.company.portrait,
                      alt: "Company portrait"
                    },
                    on: { error: _vm.usePlaceholder }
                  })
                ])
              : _vm._e(),
            _vm.isOwnerOfProfile
              ? _c(
                  "div",
                  { staticClass: "col-xs-12" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "CompanyEditView"
                          }
                        }
                      },
                      [
                        _c(
                          "button",
                          { staticClass: "button", attrs: { type: "button" } },
                          [_vm._v(" " + _vm._s(_vm.$t("Company.edit")) + " ")]
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-xs-12" },
              [
                _c(
                  "div",
                  { staticClass: "col-t-md-8" },
                  [
                    _c("Projects", {
                      attrs: {
                        profile: _vm.company,
                        "profile-type": "company",
                        "member-credit-list": this.company.profileCredits
                      }
                    })
                  ],
                  1
                ),
                _c("ProductionCredits", {
                  attrs: {
                    "production-credit-list": _vm.company.productionCredits
                  }
                })
              ],
              1
            )
          ])
        : _c("loader")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }