<template>
  <div class="form-item">
    <label for="trailer">{{ $t("Movie.form.labels.trailer") }}:</label>
    <div class="form-item-input">
      <input type="url" v-model="movieTrailer" id="trailer" />
      <p class="tip text-small">http://vimeo.com/371138452</p>
      <p class="tip text-small">
        http://www.youtube.com/watch?v=nAQPYJvxf4
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovieTrailer",
  props: {
    value: {
      type: String,
      default: "",
      required: true
    }
  },
  computed: {
    movieTrailer: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
