<template>
  <div class="row">
    <!--  -->
    <div class="col-xs-12 col-t-md-2" v-if="row === 0">
      <h3 class="h1">{{ movie.publishedYear }}</h3>
    </div>
    <div v-else class="col-xs-12 col-t-md-2">
      <h3 class="h1" />
    </div>
    <div class="col-xs-12 col-t-md-4 s-mrgb-10 movie__poster">
      <a @click="goToMovieLink(movie)">
        <img @error="usePlaceholder" :src="movieImage(movie)" :alt="`${movie.title} Plakat`" />
      </a>
    </div>

    <div class="col-xs-12 col-t-md-6 s-mrgb-10">
      <h3 class="h1 s-mrgb-05 ">
        <a @click="goToMovieLink(movie)">
          {{ movie.title }}
        </a>
      </h3>
      <div v-if="directorNameList" class="row col-xs-12 align-start">
        <div class="flex align-baseline align-center">
          <svg-icon name="regie_path" class="icon icon--small" />
        </div>
        <div>
          <div class="s-mrgl-04">
            {{ directorNameList }}
          </div>
        </div>
      </div>
      <div class="row col-xs-12 align-start s-mrgl-03 s-mrgt-03">
        <div class="s-mrgl-04">
          <div
            v-for="productionLink in movie.productionLinks"
            :key="productionLink.id"
          >
            <p v-if="displayProductionLink(productionLink)">
              {{ $t("MemberProfile.detail.production") }}:
              {{ $t("MemberProfile.detail.productionCompany") }}
            </p>
          </div>
          <div
            v-for="productionLink in movie.coProductionLinks"
            :key="productionLink.id"
          >
            <p v-if="displayProductionLink(productionLink)">
              {{ $t("MemberProfile.detail.production") }}:
              {{ $t("MemberProfile.detail.coProductionCompany") }}
            </p>
          </div>
          <div v-for="credit in movie.movieCredits" :key="credit.id">
            <p v-if="displayRole(credit)">
              {{ $t("MemberProfile.detail.credit") }}:
              {{ credit.occupationName || credit.occupation.credit }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Movie",

  data() {
    return {
      placeholderProfile: "https://via.placeholder.com/200x300/808080/FFFFFF/?text=Movie+Poster+Placeholder"
    };
  },
  props: {
    movie: {
      type: Object,
      required: true
    },
    row: {
      type: Number,
      required: true
    },
    profile: {
      type: Object,
      required: true
    },
    profileType: {
      type: String,
      required: false,
      default: "company"
    }
  },
  computed: {
    profileName() {
      if (this.profileType === "company") {
        return this.profile.name;
      } else {
        return `${this.profile.firstName} ${this.profile.lastName}`;
      }
    },
    directorNameList() {
      return this.movie.movieCredits
        .filter(credit => credit.occupationName === "Regie")
        .map(director => director.user.fullName)
        .join(", ");
    }
  },
  methods: {
    ...mapState("MemberProfileStore", ["memberProfile"]),
    /**
     * Return true if credit belongs to given profile
     * @param credit
     * @returns {boolean}
     */
    displayRole(credit) {
      if (this.profileType === "member") {
        if (!credit.user) return false; // ignore company credits
        return credit.user.memberProfile === this.profile.id;
      } else if (credit.company) {
        if (!credit.company) return false; // ignore user credits
        return credit.company.id === this.profile.id;
      }

      return false;
    },
    /**
     * Return true if productionLink belongs to given company profile
     * @param productionLink
     * @returns {boolean}
     */
    displayProductionLink(productionLink) {
      if (this.profileType === "company") {
        return productionLink.id === this.profile.id;
      }

      return false;
    },
    usePlaceholder(e) {
      e.target.src = this.placeholderProfile;
    },
    goToMovieLink(movie) {
      if (movie.published) {
        this.$router.push({
          name: "MovieDetail",
          params: { Mid: movie.id }
        });
      } else {
        this.$toaster.warning(this.$i18n.t("Toaster.movie.linkWarning"));
      }
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    movieImage(movie) {
      let image = null;
      if (movie.poster) {
        image = movie.poster;
      } else if (movie.movieImages.length) {
        image = movie.movieImages[0].screenshot;
      } else {
        return null;
      }
      return this.isValidHttpUrl(image)
        ? image
        : `${process.env.VUE_APP_API_URL}${image}`;
    }
  }
};
</script>
