var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.links.length
    ? _c("div", { staticClass: "s-mrgb-06" }, [
        _c("h2", { staticClass: "h2" }, [_vm._v("Links")]),
        _c(
          "ul",
          { staticClass: "links" },
          _vm._l(_vm.links, function(link) {
            return _c("li", { key: link.label }, [
              _c(
                "a",
                { attrs: { href: link.to } },
                [
                  _c("svg-icon", {
                    staticClass: "icon icon--small",
                    attrs: { name: "arrow-long-right" }
                  }),
                  _vm._v(_vm._s(link.label))
                ],
                1
              )
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }