class Festival {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.name = payload.name || null;
    this.address = payload.address || null;
  }
}

export { Festival };
