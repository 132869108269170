var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.isLoading
      ? _c(
          "div",
          [
            _c("h1", { staticClass: "s-mrgb-05 s-mrgt-08" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("MemberProfile.form.edit.title")) + " "
              )
            ]),
            _c("MemberProfileEditForm", {
              attrs: { "member-profile": _vm.memberProfile },
              on: {
                patchMemberProfile: _vm.patchMemberProfile,
                cancel: _vm.cancel
              }
            })
          ],
          1
        )
      : _c("div", [
          _vm._v(_vm._s(_vm.$t("MemberProfile.form.helpTexts.fetchingData")))
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }