class MovieImage {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.image = payload.screenshot || null;
    this.new = false;
  }
}

export { MovieImage };
