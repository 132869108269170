<template>
  <div class="form-item-input flex--row s-mrgt-03 align-center">
    <label for="showPhone">{{
      $t("MemberProfile.form.labels.showPhone")
    }}</label>
    <input type="checkbox" id="showPhone" v-model="memberProfileShowPhone" />
  </div>
</template>

<script>
export default {
  name: "MemberProfileShowPhone",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    memberProfileShowPhone: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-item-input {
  label {
    width: 30%;
  }
  input {
    width: initial;
  }
}
</style>
