<template>
  <div>
    <!-- Page title -->
    <h1 class="s-mrgb-05">{{ $t("MemberProfile.list.title") }}</h1>
    <!-- Search -->
    <search-input @submitted="getInpit" />
    <div class="row">
      <!-- Filters -->
      <div class="col-xs-12 col-t-md-4">
        <Filters
          class="filter"
          :group-occupation-list="groupOccupationList"
          @setSelectedFilters="setSelectedFilterList"
          @resetSearch="resetSearch"
        />
        <div>
          <p class="s-mrgt-05 col-t-md-9 s-pdgl-00 s-mrgb-05 text-small">
            <i18n path="MemberProfile.list.searchHelp.text">
              <template #mandate>
                <span>{{ mandate.name }}</span>
              </template>
              <template #registrationLink>
                <a :href="mandate.registrationLink">{{
                  $t("MemberProfile.list.searchHelp.linkText")
                }}</a>
              </template>
            </i18n>
          </p>
        </div>
        <Support />
      </div>
      <!-- Member list -->
      <div class="col-xs-12 col-t-md-8">
        <MemberProfilesList v-if="!loading" :members-list="memberListForPage" />
        <loader v-else></loader>
        <!-- Pagination -->
        <div v-if="isMemberListPaged && isPagedListLength" class="col-xs-12">
          <Pagination
            :current-page="page"
            :page-count="pagedMemberList.length"
            @setCurrentPage="setCurrentPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MemberProfilesList from "../../components/MemberProfiles/MemberProfilesList";
import Filters from "../../components/MemberProfiles/Filters";
import Pagination from "../../components/Pagination";
import Support from "../../components/Support";
import { getFilteredMemberProfileList } from "../../services/memberProfile.service";
import { getGroupOccupationList } from "../../services/Occupation.service";
import chunk from "lodash/chunk";
import { mapState } from "vuex";
import loader from "../../components/loader";
import SearchInput from "../../components/SearchInput.vue";

export default {
  name: "MemberProfileListView",
  components: {
    Filters,
    Pagination,
    loader,
    MemberProfilesList,
    Support,
    SearchInput
  },
  data() {
    return {
      loading: false,
      pagedMemberList: null,
      memberList: [],
      groupOccupationList: [],
      filters: [],
      page: 1,
      submittedSearchTerm: "",
      memberOccupations: []
    };
  },
  methods: {
    setSelectedFilterList(filters) {
      this.filters = filters;
    },
    getInpit(value) {
      this.submittedSearchTerm = value;
    },
    resetSearch() {
      this.submittedSearchTerm = "";
    },
    setCurrentPage(page) {
      this.page = page;
    },
    filterOccupations() {
      this.groupOccupationList.forEach(group => {
        let groupOccupations = [];
        group.occupations.forEach(occupation => {
          if (!this.memberOccupations.some(occ => occ.id === occupation.id)) {
            groupOccupations.push(occupation);
          }
        });
        groupOccupations.forEach(occ => {
          let index = group.occupations.indexOf(occ);
          group.occupations.splice(index, 1);
        });
      });
    },
    addOccupationIfCurrentMandate(member) {
      if (member.user.mandates[0].name === this.mandate.name) {
        member.memberOccupations.forEach(occupation => {
          if (occupation.isUnlocked) {
            this.memberOccupations.push(occupation.occupation);
          }
        });
      }
    }
  },
  computed: {
    ...mapState("MandateStore", ["mandate"]),
    getMemberPage() {
      return this.page - 1;
    },
    isMemberListPaged() {
      return this.pagedMemberList;
    },
    memberListForPage() {
      if (
        this.isMemberListPaged &&
        this.memberList.length >= process.env.VUE_APP_MEMBER_LIST_PAGING
      ) {
        return this.pagedMemberList[this.getMemberPage];
      }
      return this.memberList;
    },
    isPagedListLength() {
      return this.memberList.length >= process.env.VUE_APP_MEMBER_LIST_PAGING;
    }
  },
  asyncComputed: {
    async setMemberList() {
      this.loading = true;
      this.memberList = await getFilteredMemberProfileList(
        this.submittedSearchTerm,
        this.filters,
        this.mandate
      );
      this.memberList.forEach(member => {
        if (member.user.mandates.length) {
          this.addOccupationIfCurrentMandate(member);
        }
      });

      if (this.memberList.length >= process.env.VUE_APP_MEMBER_LIST_PAGING) {
        this.pagedMemberList = chunk(
          this.memberList,
          process.env.VUE_APP_MEMBER_LIST_PAGING
        );
      }
      this.loading = false;
    }
  },
  async updated() {
    if (!this.groupOccupationList.length) {
      this.groupOccupationList = await getGroupOccupationList();
      this.filterOccupations();
    }
  }
};
</script>
