import { axiosBaseService } from "./axiosBase.service";
import store from "../store";

const getPagedMoviesList = async (page, mandate, filters) => {
  try {
    const response = await axiosBaseService.get(
      `${store.state.locale}/moviesapi/movies/`,
      {
        params: {
          page: page,
          mandates: mandate ? mandate.name : "",
          movie_type: filters.movieTypes,
          movie_format: filters.movieFormats,
          styles: filters.movieStyles,
          completion_status: filters.completionStatuses,
          published_year: filters.yearParam,
          search: filters.searchParam
        }
      }
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export { getPagedMoviesList };
