import { OccupationGroup } from "./OccupationGroup.model";

class Occupation {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.credit = payload.credit || null;
    this.description = payload.description || "";
    this.isAutomaticallyUnlocked = payload.isAutomaticallyUnlocked || false;
    this.group = new OccupationGroup(payload.group) || null;
    this.movieFormat = payload.movieFormat || null;
    this.movieType = payload.movieType || null;
    this.movieStyle = payload.movieStyle || null;
    this.isService = payload.isService || false;
    this.isCreditOnly = payload.isCreditOnly || false;
  }
}

class Group {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.name = payload.name || null;
    this.occupations = payload.occupations.map(occupation => {
      return new Occupation(occupation);
    });
  }
}

export { Occupation, Group };
