import { Occupation } from "./Occupation.model";

class MemberOccupation {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.isUnlocked = payload.isUnlocked || false;
    this.occupation = new Occupation(payload.occupation) || null;
    this.memberProfile = payload.memberProfile || null;
    this.order = payload.order || 0;
  }
}

export { MemberOccupation };
