import { axiosBaseService } from "./axiosBase.service";
import { User } from "../models/User.model";

const getUserList = async () => {
  try {
    const response = await axiosBaseService.get(`/usersapi/users/`);
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.map(user => {
      return new User(user);
    });
  } catch (err) {
    throw err;
  }
};

const getUserBySearch = async (query = "") => {
  try {
    const url = `/usersapi/users/?search=${query}`;
    const response = await axiosBaseService.get(url);
    if (response.status === 200) {
      return response.data.results.map(user => {
        return new User(user);
      });
    } else {
      return response.statusText;
    }
  } catch (error) {
    throw error;
  }
};

const getEmployees = async () => {
  try {
    const response = await axiosBaseService.get(`/usersapi/users/employees`);
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.map(user => {
      return new User(user);
    });
  } catch (err) {
    throw err;
  }
};

export { getUserList, getUserBySearch, getEmployees };
