var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("label", { staticClass: "typo__label", attrs: { for: _vm.id } }, [
      _vm._v(_vm._s(_vm.labelText) + ":")
    ]),
    _c(
      "div",
      { staticClass: "multiselect-wrapper" },
      [
        _c(
          "multiselect",
          {
            attrs: {
              id: _vm.id,
              label: _vm.displayValueKey,
              "track-by": "id",
              placeholder: _vm.placeholder,
              "open-direction": "bottom",
              options: _vm.options,
              multiple: true,
              searchable: true,
              "close-on-select": false,
              "clear-on-select": false,
              "max-height": 600,
              "show-no-results": false,
              "hide-selected": true,
              taggable: true
            },
            on: {
              tag: function($event) {
                return _vm.addUnknownOption($event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "tag",
                fn: function({ option, remove }) {
                  return [
                    _c("span", { staticClass: "custom__tag" }, [
                      _c("span", [_vm._v(_vm._s(option[_vm.displayValueKey]))]),
                      _c(
                        "span",
                        {
                          staticClass: "custom__remove",
                          on: {
                            click: function($event) {
                              return remove(option)
                            }
                          }
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "icon icon--small",
                            attrs: { name: "times" }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.values,
              callback: function($$v) {
                _vm.values = $$v
              },
              expression: "values"
            }
          },
          [
            !_vm.values.length
              ? _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                  _vm._v(_vm._s(_vm.elementNotFoundText))
                ])
              : _vm._e()
          ]
        ),
        _c("p", { staticClass: "tip text-small" }, [
          _vm._v(" " + _vm._s(_vm.liveSearchText) + " ")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }