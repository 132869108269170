import { MemberCredit } from "./MemberCredit";
import { MemberOccupation } from "./MemberOccupation";

class MemberProfile {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.user = payload.user || null;
    this.firstName = payload.firstName || "";
    this.lastName = payload.lastName || "";
    this.address = payload.address || "";
    this.showAddress = payload.showAddress || false;
    this.phone = payload.phone || "";
    this.showPhone = payload.showPhone || false;
    this.website = payload.website || "";
    this.text = payload.text || "";
    this.textFr = payload.textFr || "";
    this.portrait = payload.portrait || null;
    this.pdf = payload.pdf || null;
    this.pdfFilename = payload.pdfFilename || "";
    this.fullname = payload.fullname || "";
    this.memberCredits = payload.memberCredits
      ? payload.memberCredits.map(memberCredit => {
          return new MemberCredit(memberCredit);
        })
      : [];
    this.movies = payload.movies || [];
    this.productionCredits = payload.productionCredits || [];
    this.profileCredits = payload.profileCredits || [];
    this.occupations = payload.occupations || [];
    this.hiddenMovies = payload.hiddenMovies || [];
    this.memberOccupations = payload.memberOccupations
      ? payload.memberOccupations.map(occupation => {
          return new MemberOccupation(occupation);
        })
      : [];
  }
}

export { MemberProfile };
