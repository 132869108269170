var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.imageUpload.poster")))
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showThumbanil || _vm.value,
            expression: "showThumbanil || value"
          }
        ],
        staticClass: "image-thumbnail s-mrgb-04"
      },
      [
        _c("img", {
          attrs: {
            id: "poster-thumbnail",
            src: _vm.value,
            alt: _vm.$t("Movie.form.edit.movieImages.alt")
          }
        }),
        _c("input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.value,
              expression: "!value"
            }
          ],
          attrs: { type: "file", name: "imageUpload", accept: "image/*" },
          on: { change: _vm.submitPoster }
        }),
        _c(
          "button",
          {
            staticClass: "button s-mrgt-02",
            attrs: { type: "button" },
            on: { click: _vm.removePoster }
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("Movie.form.edit.movieImages.buttons.removePoster")
                ) +
                " "
            )
          ]
        )
      ]
    ),
    !_vm.showThumbanil && (_vm.hasNewPoster || !_vm.value)
      ? _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: { click: _vm.addPoster }
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("Movie.form.edit.movieImages.buttons.addPoster")
                ) +
                " "
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }