<template>
  <div class="form-item">
    <div class="multiselect-wrapper">
      <multiselect-form
        :elementNotFoundText="$t('Movie.form.errors.elementNotFound')"
        :placeholder="$t('Movie.form.labels.search.typeToSearch')"
        :liveSearchText="$t('Movie.form.labels.search.liveSearch')"
        :labelText="$t('Movie.form.labels.supporters')"
        id="movieSupporters"
        :options="movieSupporterMultiSelect.supporters"
        @input="addSupporter"
        displayValueKey="agency"
        :value="value"
      />
    </div>
  </div>
</template>

<script>
import MultiselectForm from "@/components/movies/MovieEditComponents/MultiselectForm.vue";

export default {
  name: "MovieSupporters",
  components: { MultiselectForm },
  data() {
    return {
      movieSupporterMultiSelect: {
        supporters: this.supporterList,
        isLoading: false
      }
    };
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    supporterList: {
      type: Array,
      required: true
    }
  },
  computed: {
    movieSupporters: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  },
  methods: {
    addSupporter(newVal) {
      this.$emit("input", newVal);
    }
  }
};
</script>
