var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "movies__item s-mrgb-08" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-t-md-4" }, [
        _c("div", { staticClass: "movie__poster s-mrgb-04 t-md-s-mrgb-00" }, [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  return _vm.goToMovieLink(_vm.movieListItem)
                }
              }
            },
            [
              _vm.hasMoviePoster
                ? _c("img", {
                    attrs: {
                      src: _vm.movieListItem.poster,
                      alt: `${_vm.movieListItem.title} Plakat`
                    },
                    on: { error: _vm.usePlaceholder }
                  })
                : _vm.hasMovieImages
                ? _c("img", {
                    attrs: {
                      src: _vm.movieListItem.movieImages[0].image,
                      alt: `${_vm.movieListItem.title} Plakat`
                    },
                    on: { error: _vm.usePlaceholder }
                  })
                : _c("img", { attrs: { src: _vm.placeholderPoster } })
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "col-xs-12 col-t-md-8" }, [
        _c("div", { staticClass: "movie__info" }, [
          _c("h2", { staticClass: "h1 s-mrgb-05" }, [
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    return _vm.goToMovieLink(_vm.movieListItem)
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.translateOrFallback(_vm.movieListItem, "title")
                    ) +
                    " "
                )
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "info__details flex align-baseline s-mrgb-05" },
            [
              _c("svg-icon", {
                staticClass: "icon icon--small",
                attrs: { name: "regie_path" }
              }),
              _c("ul", { staticClass: "list-style--none s-mrgl-04" }, [
                _vm.movieDirectorsString
                  ? _c("span", [
                      _vm._v(" " + _vm._s(_vm.movieDirectorsString) + " ")
                    ])
                  : _vm._e(),
                _c("li", { staticClass: "details__category" }, [
                  _vm.movieListItem.movieType
                    ? _c("span", { staticClass: "list-item--separator" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.translateOrFallback(
                                _vm.movieListItem.movieType,
                                "name"
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ],
            1
          ),
          _c("div", { staticClass: "info__format" }, [
            _c("ul", { staticClass: "list-style--none" }, [
              _vm.movieListItem.vodLink
                ? _c(
                    "li",
                    { staticClass: "flex align-baseline" },
                    [
                      _c("svg-icon", {
                        staticClass: "icon icon--small s-mrgr-04",
                        attrs: { name: "yes-symbol" }
                      }),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `${_vm.movieListItem.vodLink}`,
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Movie.detail.links.VOD")))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.movieListItem.dvdBlurayLink
                ? _c(
                    "li",
                    { staticClass: "flex align-baseline" },
                    [
                      _c("svg-icon", {
                        staticClass: "icon icon--small s-mrgr-04",
                        attrs: { name: "yes-symbol" }
                      }),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `${_vm.movieListItem.dvdBlurayLink}`,
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Movie.detail.links.bluray"))
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.movieListItem.trailer
                ? _c(
                    "li",
                    { staticClass: "flex align-center" },
                    [
                      _c("svg-icon", {
                        staticClass: "icon icon--small s-mrgr-04",
                        attrs: { name: "arrow-long-right" }
                      }),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `${_vm.movieListItem.trailer}`,
                            target: "_blank"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Movie.detail.trailer")))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }