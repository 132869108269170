<template>
  <div>
    <div class="movie-list__movies">
      <p class="h2" v-if="!movieList.length && !isLoading">
        {{ $t("Movie.list.noData") }}
      </p>
      <MoviesListItem
        v-for="movie in movieList"
        :movieListItem="movie"
        :key="movie.id"
      >
      </MoviesListItem>
    </div>
    <div class="s-mrgt-05" v-if="isUserList">
      <router-link
        :to="{
          name: 'MovieCreateView'
        }"
        ><p>{{ $t("Movie.list.links.createMovie") }}</p></router-link
      >
    </div>
  </div>
</template>

<script>
// import pagination from "../vue_pagination";
import MoviesListItem from "./MoviesListItem";

export default {
  name: "MoviesList",
  components: {
    // pagination,
    MoviesListItem
  },
  props: {
    movieList: {
      type: Array,
      required: true
    },
    isUserList: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    draft: {
      type: Boolean,
      default: false
    }
  }
};
</script>
