<template>
  <div class="form-item">
    <label
      >{{ $t("Movie.form.labels.imageUpload.movieImages") }}
      <span class="error">*</span></label
    >
    <div class="form-item-input">
      <div class="s-mrgb-04 row">
        <div
          class="image-thumbnail col-xs-12 col-t-md-4 s-mrgb-04"
          v-for="(movieImage, key) in movieImages"
          :key="key"
        >
          <img
            :id="key"
            v-show="!movieImage.showThumbnail"
            class="image-thumbnail--thumbnail"
            :src="movieImage.image"
            :alt="$t('Movie.form.edit.movieImages.alt')"
          />
          <p v-if="movieImage.showThumbnail" class="s-pdg-04">
            {{ $t("Movie.form.edit.movieImages.noImage") }}
          </p>
          <div class="image-thumbnail--meta s-pdg-02">
            <input
              v-if="!movieImage.image"
              type="file"
              class="no-border image-thumbnail__upload"
              accept="image/*"
              name="multipleImageUpload"
              @change="uploadMovieImage($event, movieImage)"
            />
            <button
              v-if="movieImage.image && movieImages.length > 1"
              type="button"
              class="button"
              @click="removeMovieImage(movieImage.id)"
            >
              {{ $t("Movie.form.edit.movieImages.buttons.removeImage") }}
            </button>
          </div>
        </div>
        <div class="col-xs-12 col-t-md-12">
          <button type="button" class="button" @click="addMovieImage">
            {{ $t("Movie.form.edit.movieImages.buttons.addImage") }}
          </button>
        </div>
      </div>
      <p class="tip text-small">
        {{ $t("Movie.form.labels.imageUpload.filmStills") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovieImages",
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    movieImages() {
      return this.value;
    }
  },
  methods: {
    async uploadMovieImage(event, movieImage) {
      await this.updateMovieImageThumbnails(
        event.target.files[0],
        movieImage.id
      );
      if (movieImage.new) {
        this.$emit("setMovieImages", event.target.files[0]);
      }
    },
    updateMovieImageThumbnails(file, movieImageId) {
      const fileReader = new FileReader();
      const movieImageElements = document.querySelectorAll(
        ".image-thumbnail--thumbnail"
      );
      const movieImageIndex = this.movieImages.findIndex(
        movieImage => movieImage.id === movieImageId
      );
      movieImageElements[movieImageIndex].title = file.name;
      let vm = this;
      fileReader.onload = function(event) {
        movieImageElements[movieImageIndex].src = event.target.result;
        vm.movieImages[movieImageIndex].image = event.target.result;
      };
      this.movieImages[movieImageIndex].showThumbnail = false;
      this.movieImages[movieImageIndex].new = true;
      fileReader.readAsDataURL(file);
    },
    removeMovieImage(index) {
      const movieImageIndex = this.movieImages.findIndex(
        movieImage => movieImage.id === index
      );
      this.movieImages.splice(movieImageIndex, 1);
    },
    addMovieImage() {
      this.movieImages.push({
        id: this.generateUniqueID(),
        image: null,
        showThumbnail: true,
        new: true
      });
    },
    generateUniqueID() {
      if (this.movieImages.length) {
        return (
          this.movieImages.reduce(
            (max, movieImage) => (movieImage.id > max ? movieImage.id : max),
            this.movieImages[0].id
          ) + 1
        );
      }
      return 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base.scss";
.image-thumbnail {
  p {
    border: 1px solid $color-black;
  }
}
.image-thumbnail--thumbnail {
  border: 1px solid $color-black;
}
.image-thumbnail--meta {
  border-left: 1px solid $color-black;
  border-right: 1px solid $color-black;
  border-bottom: 1px solid $color-black;
  .image-thumbnail__upload {
    padding: 0 0 0.25rem 0;
  }
}
</style>
