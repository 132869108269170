<template>
  <div class="form-item">
    <label for="published-year"
      >{{ $t("Movie.form.labels.publishedYear") }}:</label
    >
    <input
      type="number"
      v-model="moviePublishedYear"
      id="published-year"
      :max="getMaxYear"
    />
  </div>
</template>

<script>
export default {
  name: "MoviePublishedYear",
  props: {
    value: null
  },
  computed: {
    moviePublishedYear: {
      get() {
        return this.value;
      },
      set(newVal) {
        let cleanedNewVal = newVal ? newVal : null;
        this.$emit("input", cleanedNewVal);
      }
    },
    getMaxYear() {
      let today = new Date();
      today.getFullYear();
      return today.getFullYear() + 2;
    }
  }
};
</script>
