var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "s-mrgb-06" },
    [
      _c("h2", { staticClass: "h2" }, [
        _vm._v(_vm._s(_vm.$t("Movie.detail.credits.title")))
      ]),
      _vm._l(_vm.creditEntries, function({ label, values }) {
        return _c(
          "div",
          { key: label },
          [
            _vm._v(" " + _vm._s(label) + ": "),
            _c("CreditEntry", { attrs: { label: label, values: values } })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }