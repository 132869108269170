<template>
  <div class="form-item">
    <p class="s-mrgb-02">
      {{ $t("Movie.form.labels.rentalLink.rentalLink") }}:
    </p>

    <div class="form-item-input">
      <div class="s-mrgb-04">
        <label for="rental-title"
          >{{ $t("Movie.form.labels.rentalLink.title") }}:</label
        >

        <input type="text" v-model="movieRentalLink.title" id="rental-title" />
      </div>

      <div>
        <label for="rental-url"
          >{{ $t("Movie.form.labels.rentalLink.url") }}:</label
        >

        <input type="url" v-model="movieRentalLink.url" id="rental-url" />
        <p class="tip text-small">
          {{ $t("Movie.form.helpText.correctFormat") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MovieRentalLink",
  data() {
    return {
      defaultRentalLink: {
        title: "",
        url: ""
      }
    };
  },
  props: {
    value: null
  },
  computed: {
    movieRentalLink: {
      get() {
        if (this.value) {
          return this.value;
        }
        return this.defaultRentalLink;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
