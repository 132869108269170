var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c(
      "div",
      { staticClass: "multiselect-wrapper" },
      [
        _c("multiselect-form", {
          attrs: {
            elementNotFoundText: _vm.$t("Movie.form.errors.elementNotFound"),
            placeholder: _vm.$t("Movie.form.labels.search.typeToSearch"),
            liveSearchText: _vm.$t("Movie.form.labels.search.liveSearch"),
            labelText: _vm.$t("Movie.form.labels.supporters"),
            id: "movieSupporters",
            options: _vm.movieSupporterMultiSelect.supporters,
            displayValueKey: "agency",
            value: _vm.value
          },
          on: { input: _vm.addSupporter }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }