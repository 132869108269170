<template>
  <div>
    <div
      class="filterGroup"
      v-for="(group, groupIndex) in groupOccupationList"
      :key="`occupationGroup--${groupIndex}`"
    >
      <div v-if="group.occupations.length">
        <a class="align-center flex" @click="setFilterToggled(groupIndex)">
          <svg-icon name="chevron-down" class="icon icon--small s-mrgr-03" />
          <h2>{{ group.name }}</h2>
        </a>
      </div>
      <div class="filter-container visually-hidden">
        <div
          v-for="(occupation, occupationIndex) in group.occupations"
          :key="`occupation--${occupationIndex}`"
        >
          <div class="align-center flex s-mrgl-10">
            <div class="custom-checkbox" v-if="occupation.description !== ''">
              <input
                :id="`filter-checkbox--${groupIndex}--${occupationIndex}`"
                type="checkbox"
                name="filter-checkbox"
                @change="setSelectedFilterList(occupation)"
              />
              <label
                :for="`filter-checkbox--${groupIndex}--${occupationIndex}`"
              />
            </div>
            <p>{{ occupation.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="align-center flex s-mrgt-05">
      <svg-icon name="times" class="icon filter-icon" />
      <a @click="resetFilter" href="#">{{
        $t("MemberProfile.filters.deleteAll")
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Filters",
  props: {
    groupOccupationList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedFilterList: []
    };
  },
  methods: {
    setFilterToggled(groupIndex) {
      document
        .querySelectorAll(".filterGroup")
        [groupIndex].querySelector(".filter-container")
        .classList.toggle("visually-hidden");
    },
    setSelectedFilterList(occupation) {
      if (!this.selectedFilterList.includes(occupation)) {
        this.selectedFilterList.push(occupation);
      } else {
        const selectedFilterIndex = this.selectedFilterList.indexOf(occupation);
        this.selectedFilterList.splice(selectedFilterIndex, 1);
      }
      this.$emit("setSelectedFilters", this.selectedFilterList);
    },
    resetFilter() {
      this.selectedFilterList = [];
      document.getElementsByName("filter-checkbox").forEach(element => {
        element.checked = false;
      });
      this.$emit("setSelectedFilters", this.selectedFilterList);
      this.$emit("resetSearch");
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
</style>
