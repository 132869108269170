import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Root from "./Root";

import MoviesListView from "@/views/MoviesListView.vue";
import MovieDetail from "@/views/MovieDetailView";
import { languages } from "@/plugins/i18n";

import PasswordResetConfirm from "@/views/PasswordResetConfirm";
import Login from "@/views/Login";
import Logout from "@/views/Logout";
import PasswordResetEmail from "@/views/PasswordResetEmail";

import MemberProfileDetailView from "@/views/MemberProfiles/MemberProfileDetailView";
import MemberProfileEditView from "@/views/MemberProfiles/MemberProfileEditView";
import MemberProfileCreateView from "@/views/MemberProfiles/MemberProfileCreateView";
import MemberProfileListView from "@/views/MemberProfiles/MemberProfileListView";

import NotFoundView from "@/views/NotFoundView";
import MovieCreateView from "@/views/MovieCreateView";
import MovieEditView from "@/views/MovieEditView";
import UserMovieListView from "@/views/UserMovieListView";

import Company from "@/views/Companies/Company";
import CompanyEditView from "@/views/Companies/CompanyEditView";
import Companies from "@/views/Companies/Companies";
import { getMandate } from "@/services/mandate.service";

Vue.use(VueRouter);

async function configureMandate() {
  const mandate = await getMandate(window.location.hostname);
  document.title = mandate[0].name;
  if (mandate[0].fontFamily) {
    document.body.style.fontFamily = mandate[0].fontFamily;
  }
  return mandate[0];
}

const routes = [
  {
    //redirects the request of root URL (/) to /:lang
    // by passing the current locale next(store.state.locale).
    path: "/",
    name: "root",
    beforeEnter(to, from, next) {
      next(store.state.locale);
    }
  },
  {
    path: "/:lang",
    component: Root,
    async beforeEnter(to, from, next) {
      const mandate = await configureMandate();
      store.dispatch("MandateStore/setMandate", mandate);
      let lang = to.params.lang;
      if (languages.includes(lang)) {
        if (store.state.locale !== lang) {
          store.dispatch("changeLocale", lang);
        }
        return next();
      }
      return next({ path: store.state.locale });
    },
    children: [
      {
        path: "",
        name: "MoviesListView",
        component: MoviesListView,
        props: true
      },
      {
        path: "movie/:Mid",
        name: "MovieDetail",
        component: MovieDetail
      },
      {
        path: "create",
        name: "MovieCreateView",
        component: MovieCreateView,
        beforeEnter(to, from, next) {
          if (store.state.UserStore.authUser) {
            next();
          } else {
            next({
              name: "Login",
              params: { lang: store.state.locale }
            });
          }
        }
      },
      {
        path: "/:lang/password_reset/:token/",
        name: "PasswordResetConfirm",
        component: PasswordResetConfirm
      },
      {
        path: "/:lang/forgot-password/",
        name: "PasswordResetEmail",
        component: PasswordResetEmail
      },
      {
        path: "login",
        name: "Login",
        component: Login,
        beforeEnter(to, from, next) {
          if (
            store.state.UserStore.authUser &&
            store.state.AuthStore.isLoggedIn &&
            store.state.UserStore.authUser.isMember
          ) {
            next({
              name: "root",
              params: { lang: store.state.locale }
            });
          } else {
            next();
          }
        }
      },
      {
        path: "logout",
        name: "Logout",
        component: Logout
      },
      {
        path: "members/create",
        name: "MemberProfileCreateView",
        component: MemberProfileCreateView,
        beforeEnter(to, from, next) {
          if (
            store.state.UserStore.authUser &&
            store.state.AuthStore.isLoggedIn &&
            store.state.UserStore.authUser.isMember
          ) {
            next();
          } else {
            next({
              name: "MoviesListView",
              params: { lang: store.state.locale }
            });
          }
        }
      },
      {
        path: "members/:MpId",
        name: "MemberProfileDetailView",
        component: MemberProfileDetailView
      },
      {
        path: "members/",
        name: "MemberProfileListView",
        component: MemberProfileListView
      },
      {
        path: "members/:MpId/edit",
        name: "MemberProfileEditView",
        component: MemberProfileEditView,
        beforeEnter(to, from, next) {
          if (
            store.state.UserStore.authUser &&
            store.state.AuthStore.isLoggedIn &&
            store.state.UserStore.authUser.isMember &&
            store.state.UserStore.authUser.memberProfile ===
              parseInt(to.params.MpId)
          ) {
            next();
          } else {
            next({
              name: "MoviesListView",
              params: { lang: store.state.locale }
            });
          }
        }
      },
      {
        path: "movie/:Mid/edit",
        name: "MovieEditView",
        component: MovieEditView
      },
      {
        path: "user/movies",
        name: "UserMovieListView",
        component: UserMovieListView
      },
      {
        path: "company/",
        name: "Companies",
        component: Companies
      },
      {
        path: "company/:Cid",
        name: "Company",
        component: Company
      },
      {
        path: "company/:Cid/edit",
        name: "CompanyEditView",
        component: CompanyEditView,
        props: true
      },
      {
        path: "*",
        name: "NotFoundView",
        component: NotFoundView
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// This makes sure create movie is only for logged in users
router.beforeEach((to, from, next) => {
  if (to.fullPath === `/${store.state.locale}/create`) {
    if (store.state.AuthStore.accessToken === null) {
      next(`/${store.state.locale}/login`);
    }
  }
  next();
});
export default router;
