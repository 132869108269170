<template>
  <div class="form-item">
    <div class="form-item-input">
      <multiselect-form
        :elementNotFoundText="$t('Movie.form.errors.elementNotFound')"
        :placeholder="$t('Movie.form.labels.search.typeToSearch')"
        :liveSearchText="$t('Movie.form.labels.search.liveSearch')"
        :labelText="
          $t('Movie.form.labels.productionLinkCreation.labels.productionLink')
        "
        id="production_company"
        :options="productionCompanyList"
        @input="addCompany"
        displayValueKey="name"
        :value="value"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import MultiselectForm from "@/components/movies/MovieEditComponents/MultiselectForm.vue";

export default {
  name: "MovieProductionLinks",
  components: { MultiselectForm },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState("CompanyStore", ["productionCompanyList"])
  },
  mounted() {
    this.$store.dispatch("CompanyStore/setProductionCompanyList");
  },
  methods: {
    addCompany(newVal) {
      this.$emit("input", newVal);
    }
  }
};
</script>
