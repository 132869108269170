class Employee {
  constructor() {
    this.id = undefined;
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.role = "";
    this.contactPerson = false;
  }
}

export { Employee };
