<template>
  <div class="form-item">
    <label for="awards">{{ $t("Movie.form.labels.awards") }}:</label>
    <textarea v-model="movieAwards" id="awards" name="awards" rows="2" />
    <p class="tip text-small">
      {{ $t("Movie.form.helpText.awards") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "MovieAwards",
  props: {
    value: {
      type: String,
      default: "",
      required: false
    }
  },
  computed: {
    movieAwards: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>
