var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "movie-list__movies" },
      [
        !_vm.movieList.length && !_vm.isLoading
          ? _c("p", { staticClass: "h2" }, [
              _vm._v(" " + _vm._s(_vm.$t("Movie.list.noData")) + " ")
            ])
          : _vm._e(),
        _vm._l(_vm.movieList, function(movie) {
          return _c("MoviesListItem", {
            key: movie.id,
            attrs: { movieListItem: movie }
          })
        })
      ],
      2
    ),
    _vm.isUserList
      ? _c(
          "div",
          { staticClass: "s-mrgt-05" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "MovieCreateView"
                  }
                }
              },
              [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("Movie.list.links.createMovie")))
                ])
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }