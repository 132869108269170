<template>
  <div class="flex flex--column align-center">
    <div class="forgot-password-form">
      <h2 class="h2">{{ $t("ResetPassword.forgotPassword") }}</h2>
      <p class="s-mrgt-05">{{ $t("ResetPassword.helpText") }}</p>
      <form @submit.prevent="submitResetEmail">
        <div class="s-mrgt-08 form-item">
          <label for="email">{{ $t("ResetPassword.email") }} *</label>
          <input
            id="email"
            @input="validate"
            v-model="email"
            class="s-mrgt-05"
            type="email"
            name="email"
            required
          />
        </div>
      </form>
      <p v-if="emailError" class="">{{ $t("ResetPassword.emailError") }}</p>
      <div class="flex flex--row between-xs s-mrgt-05">
        <button @click="submitResetEmail" class="button" :disabled="isDisabled">
          {{ $t("ResetPassword.sendButton") }}
        </button>
        <router-link
          class="dp-block"
          :to="{
            name: 'Login'
          }"
          >{{ $t("ResetPassword.backToLogin") }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { resetPasswordEmail } from "../services/ResetPassword.service.js";
export default {
  name: "PasswordResetEmail",
  data() {
    return {
      email: "",
      btnDisabled: false,
      emailError: false
    };
  },
  computed: {
    isDisabled: function() {
      return !this.btnDisabled;
    }
  },
  methods: {
    submitResetEmail: async function() {
      await resetPasswordEmail(this.email)
        .then(() => {
          this.$toaster.success(this.$i18n.t("ResetPassword.success"));
        })
        .catch(() => {
          this.$toaster.error(this.$i18n.t("ResetPassword.error"));
        });
    },
    validate: function() {
      let reg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      this.btnDisabled = this.email != null && reg.test(this.email);
      this.emailError = !reg.test(this.email);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base.scss";
.forgot-password-form {
  max-width: 600px;
}
</style>
