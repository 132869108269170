<template>
  <div class="s-mrgt-05">
    <div class="row">
      <h2 class="h1 col-xs-12 s-mrgb-08" v-if="productionCredits.length">
        {{ $t("MemberProfile.detail.productions") }}
      </h2>
      <div class="col-xs-12 col-t-md-12">
        <div v-for="memberCredit in productionCredits" :key="memberCredit.id">
          <div class="row s-mrgb-09">
            <h3 class="h1 col-xs-12 col-t-md-1">{{ memberCredit.year }}</h3>
            <div class="col-xs-12 col-t-md-3 s-mrgb-03">
              <h3>{{ memberCredit.title }}</h3>
            </div>
            <div class="col-xs-12 col-t-md-8">
              <div class="row col-xs-12">
                <div class="col-md-02">
                  <svg-icon name="regie_path" class="icon icon--small" />
                </div>
                <div class="col-xs-9 s-mrgl-04">
                  <div class="row">
                    <div>{{ memberCredit.direction }},</div>
                    <div>
                      <p class="s-mrgl-02">Rolle: {{ memberCredit.credits }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div>{{ memberCredit.production }},</div>
                    <div class="s-mrgl-02">
                      {{ memberCredit.memberFormat.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductionCredits",

  data() {
    return {
      productionCredits: this.productionCreditList
    };
  },
  props: {
    productionCreditList: {
      type: Array,
      required: true
    }
  }
};
</script>
