<template>
  <div>
    <div v-if="!isLoading" class="form-item s-mrgt-03">
      <h2>{{ $t("MemberProfile.form.credits.name") }}</h2>

      <div
        class="form-item-input"
        v-for="(credit, creditIndex) in creditList"
        :key="credit.id"
      >
        <label class="s-mrgt-06 s-mrgb-06" for="memberProfileCreditTitle"
          >{{ $t("MemberProfile.form.credits.title") }}:
          <span class="error">*</span></label
        >
        <div>
          <input
            type="text"
            class="s-mrgb-04"
            v-model="credit.title"
            required
            id="memberProfileCreditTitle"
          />
          <label for="memberProfileCreditYear"
            >{{ $t("MemberProfile.form.credits.year") }}:
            <span class="error">*</span></label
          >
          <input
            type="number"
            min="1800"
            max="2099"
            step="1"
            class="s-mrgb-04"
            v-model="credit.year"
            id="memberProfileCreditYear"
            required
          />

          <label for="memberCreditDirection">
            {{ $t("MemberProfile.form.credits.direction") }}
            <span class="error">*</span>
          </label>
          <input
            type="text"
            id="memberCreditDirection"
            v-model="credit.direction"
            class="s-mrgb-02"
            required
          />

          <label for="memberCreditProduction">
            {{ $t("MemberProfile.form.credits.production") }}
            <span class="error">*</span>
          </label>
          <input
            type="text"
            id="memberCreditProduction"
            v-model="credit.production"
            class="s-mrgb-02"
            required
          />

          <label for="memberCreditCredits">
            {{ $t("MemberProfile.form.credits.credits") }}
            <span class="error">*</span>
          </label>
          <input
            type="text"
            id="memberCreditCredits"
            v-model="credit.credit"
            class="s-mrgb-02"
            required
          />

          <Type v-model="credit.memberType" :types="typeList" />

          <Format v-model="credit.memberFormat" :formats="formatList" />

          <Style v-model="credit.memberStyles" :styles="styleList" />
        </div>
        <input
          class="form-button s-mrgt-02 s-mrgb-05"
          type="button"
          value="Credit löschen"
          @click="removeMemberCredit(creditIndex)"
        />
      </div>
      <input
        @click="addMemberCredit"
        type="button"
        class="form-button s-mrgt-02"
        :value="$t('MemberProfile.form.credits.addButton')"
      />
    </div>
    <div v-else>
      <p>{{ $t("MemberProfile.form.helpTexts.fetchingData") }}</p>
    </div>
  </div>
</template>

<script>
import Type from "./Type";
import Format from "./Format";
import Style from "./Style";
import { MemberCredit } from "@/models/MemberCredit";
import {
  getMovieTypes,
  getMovieFormats,
  getMovieStyles
} from "@/services/Category.service";

export default {
  name: "MemberProfileCredits",
  components: {
    Type,
    Format,
    Style
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    creditList: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  },
  data() {
    return {
      typeList: [],
      formatList: [],
      styleList: [],
      isLoading: true
    };
  },
  async mounted() {
    this.isLoading = true;
    this.typeList = await getMovieTypes();
    this.formatList = await getMovieFormats();
    this.styleList = await getMovieStyles();
    this.isLoading = false;
  },
  methods: {
    addMemberCredit() {
      this.creditList.push(new MemberCredit({}));
    },
    removeMemberCredit(index) {
      this.creditList.splice(index, 1);
    }
  }
};
</script>
