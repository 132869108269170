var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "reset-password-confirm-view flex flex--column align-center"
    },
    [
      !_vm.resetCompleted
        ? _c("div", { staticClass: "reset-form" }, [
            _c("h2", [
              _vm._v(_vm._s(_vm.$t("ResetPassword.resetPasswordConfirmTitle")))
            ]),
            _c("p", { staticClass: "s-mrgb-03 s-mrgt-05 text-small" }, [
              _vm._v(" " + _vm._s(_vm.$t("ResetPassword.rules.info")) + " ")
            ]),
            !_vm.isPasswordValid
              ? _c(
                  "p",
                  { staticClass: "s-mrgb-03 s-mrgt-05 password-invalid" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("ResetPassword.rules.error")) + " "
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.resetMatchError,
                    expression: "resetMatchError"
                  }
                ],
                staticClass: "error s-mrgt-05 password-invalid"
              },
              [_vm._v(" " + _vm._s(_vm.$t("ResetPassword.noMatch")) + " ")]
            ),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.resetError,
                    expression: "resetError"
                  }
                ],
                staticClass: "error s-mrgt-05 password-invalid"
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("ResetPassword.errorOccurred")) + " "
                )
              ]
            ),
            _c("form", { staticClass: "form-item s-mrgt-05" }, [
              _c("label", { attrs: { for: "password" } }, [
                _vm._v(" " + _vm._s(_vm.$t("ResetPassword.password")) + ": ")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputs.password,
                    expression: "inputs.password"
                  }
                ],
                attrs: { type: "password", id: "password" },
                domProps: { value: _vm.inputs.password },
                on: {
                  keyup: _vm.passwordValid,
                  input: function($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.inputs, "password", $event.target.value)
                  }
                }
              }),
              _c(
                "label",
                {
                  staticClass: "s-mrgt-05",
                  attrs: { for: "password-confirm" }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("ResetPassword.passwordConfirm")) + ": "
                  )
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputs.passwordConfirm,
                    expression: "inputs.passwordConfirm"
                  }
                ],
                attrs: { type: "password", id: "password-confirm" },
                domProps: { value: _vm.inputs.passwordConfirm },
                on: {
                  keyup: [
                    _vm.validate,
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.submitResetPassword.apply(null, arguments)
                    }
                  ],
                  input: function($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.inputs, "passwordConfirm", $event.target.value)
                  }
                }
              })
            ]),
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { disabled: _vm.isDisabled },
                on: { click: _vm.submitResetPassword }
              },
              [_vm._v(" " + _vm._s(_vm.$t("ResetPassword.reset")) + " ")]
            )
          ])
        : _c("div", [
            _c("p", { staticClass: "success" }, [
              _vm._v(_vm._s(_vm.$t("ResetPassword.yourPassSetSuccess")))
            ]),
            _c(
              "p",
              [
                _vm._v(" " + _vm._s(_vm.$t("ResetPassword.pleaseGo")) + " "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Login"
                      }
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.clearResetStatus.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("ResetPassword.login")))]
                )
              ],
              1
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }