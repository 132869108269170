<template>
  <div class="row">
    <div class="col-xs-12 col-t-md-2" v-if="row == 0">
      <h3 class="h1">{{ memberCredit.year }}</h3>
    </div>
    <div v-else class="col-xs-12 col-t-md-2">
      <h3 class="h1" />
    </div>

    <div class="col-xs-12 col-t-md-4 s-mrgb-10">
      <div class="credit_poster"></div>
    </div>

    <div class="col-xs-12 col-t-md-6 s-mrgb-10">
      <h3 class="h1 s-mrgb-05 ">{{ memberCredit.title }}</h3>

      <div class="row col-xs-12 align-start">
        <div class="flex align-baseline align-center">
          <svg-icon name="regie_path" class="icon icon--small" />
        </div>
        <div>
          <div class="s-mrgl-04">
            {{ memberCredit.direction }}
          </div>
        </div>
      </div>
      <div class="row col-xs-12 align-start s-mrgl-03 s-mrgt-03">
        <div>
          <div class="s-mrgl-04">
            {{ memberCredit.production }}
          </div>
          <div class="s-mrgl-04 s-mrgt-04">
            Rolle: {{ memberCredit.credits || memberCredit.credit }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Credits",

  data() {
    return {
      memberCredits: this.memberCreditList
    };
  },
  props: {
    memberCredit: {
      type: Object,
      required: true
    },
    row: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base.scss";
.credit_poster {
  height: 340px;
  background-color: #bdbdbd;
  margin: 0 1.5em;
  opacity: 0.3;
  box-shadow: 1.5em 0 0.7em #bdbdbd, -1.5em 0 0.7em #bdbdbd;
}
</style>
