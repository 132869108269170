class Language {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.language = payload.language || null;
    this.abbr = payload.abbr || null;
  }
}

export { Language };
