var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", { attrs: { for: "alternativeTitle" } }, [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.alternativeTitles")) + ":")
    ]),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.alternativeTitle,
          expression: "alternativeTitle"
        }
      ],
      attrs: { id: "alternativeTitle", name: "alternativeTitle", rows: "2" },
      domProps: { value: _vm.alternativeTitle },
      on: {
        input: function($event) {
          if ($event.target.composing) return
          _vm.alternativeTitle = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }