import { axiosBaseService } from "./axiosBase.service";
import { OccupationGroup } from "../models/OccupationGroup.model";
import { Group, Occupation } from "../models/Occupation.model";

const getOccupations = async () => {
  try {
    const response = await axiosBaseService.get(`/moviesapi/occupations/`);
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.map(occupation => {
      return new Occupation(occupation);
    });
  } catch (err) {
    throw err;
  }
};

const getOccupationGroups = async () => {
  try {
    const response = await axiosBaseService.get(
      `/moviesapi/occupation-groups/`
    );
    if (response.status !== 200) {
      return response.statusText;
    }
    return response.data.map(occupationGroup => {
      return new OccupationGroup(occupationGroup);
    });
  } catch (err) {
    throw err;
  }
};

const getGroupOccupationList = async () => {
  const response = await axiosBaseService.get(
    `usersapi/member_profiles/group_occupation/`
  );
  return response.data.map(groupOccupation => {
    return new Group(groupOccupation);
  });
};

const getMediaArtGroup = async () => {
  const response = await axiosBaseService.get(
    `moviesapi/occupation-groups/Medienkunst/`
  );
  return new OccupationGroup(response.data);
};

export {
  getOccupations,
  getOccupationGroups,
  getGroupOccupationList,
  getMediaArtGroup
};
