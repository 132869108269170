var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _vm.row === 0
      ? _c("div", { staticClass: "col-xs-12 col-t-md-2" }, [
          _c("h3", { staticClass: "h1" }, [
            _vm._v(_vm._s(_vm.movie.publishedYear))
          ])
        ])
      : _c("div", { staticClass: "col-xs-12 col-t-md-2" }, [
          _c("h3", { staticClass: "h1" })
        ]),
    _c("div", { staticClass: "col-xs-12 col-t-md-4 s-mrgb-10 movie__poster" }, [
      _c(
        "a",
        {
          on: {
            click: function($event) {
              return _vm.goToMovieLink(_vm.movie)
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: _vm.movieImage(_vm.movie),
              alt: `${_vm.movie.title} Plakat`
            },
            on: { error: _vm.usePlaceholder }
          })
        ]
      )
    ]),
    _c("div", { staticClass: "col-xs-12 col-t-md-6 s-mrgb-10" }, [
      _c("h3", { staticClass: "h1 s-mrgb-05" }, [
        _c(
          "a",
          {
            on: {
              click: function($event) {
                return _vm.goToMovieLink(_vm.movie)
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.movie.title) + " ")]
        )
      ]),
      _vm.directorNameList
        ? _c("div", { staticClass: "row col-xs-12 align-start" }, [
            _c(
              "div",
              { staticClass: "flex align-baseline align-center" },
              [
                _c("svg-icon", {
                  staticClass: "icon icon--small",
                  attrs: { name: "regie_path" }
                })
              ],
              1
            ),
            _c("div", [
              _c("div", { staticClass: "s-mrgl-04" }, [
                _vm._v(" " + _vm._s(_vm.directorNameList) + " ")
              ])
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "row col-xs-12 align-start s-mrgl-03 s-mrgt-03" },
        [
          _c(
            "div",
            { staticClass: "s-mrgl-04" },
            [
              _vm._l(_vm.movie.productionLinks, function(productionLink) {
                return _c("div", { key: productionLink.id }, [
                  _vm.displayProductionLink(productionLink)
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("MemberProfile.detail.production")) +
                            ": " +
                            _vm._s(
                              _vm.$t("MemberProfile.detail.productionCompany")
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ])
              }),
              _vm._l(_vm.movie.coProductionLinks, function(productionLink) {
                return _c("div", { key: productionLink.id }, [
                  _vm.displayProductionLink(productionLink)
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("MemberProfile.detail.production")) +
                            ": " +
                            _vm._s(
                              _vm.$t("MemberProfile.detail.coProductionCompany")
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ])
              }),
              _vm._l(_vm.movie.movieCredits, function(credit) {
                return _c("div", { key: credit.id }, [
                  _vm.displayRole(credit)
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("MemberProfile.detail.credit")) +
                            ": " +
                            _vm._s(
                              credit.occupationName || credit.occupation.credit
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ])
              })
            ],
            2
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }