import { axiosBaseService } from "./axiosBase.service";
import store from "../store/index.js";

/**
 * Resets the password
 */

const ResetPassword = async inputs => {
  try {
    const url = `/${store.state.locale}/api/password_reset/confirm/`;
    const response = await axiosBaseService.post(url, {
      password: inputs.password,
      token: inputs.token
    });
    if (response.status !== 200) {
      return response.statusText;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const resetPasswordEmail = async email => {
  try {
    const url = `/${store.state.locale}/usersapi/reset_password/email/`;
    const response = await axiosBaseService.post(url, { email: email });
    if (response.status !== 204) {
      return response.statusText;
    }
    return response;
  } catch (error) {
    throw error;
  }
};
export { ResetPassword, resetPasswordEmail };
