/**
 * @file Filmdatenbank
 * Ein Plattform für Mitglieder & Firmen um ihre Filme zu veröffentlichen.
 * @author: Liip.ch
 * @version 1.0
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import store from "./store";
import "@/assets/styles/base.scss";
import i18n from "./plugins/i18n";
import Embed from "v-video-embed";
import Multiselect from "vue-multiselect";
import "@/plugins/toaster";
import "./plugins/async-computed";
import "./plugins/vuePortal";

// Svg sprite support for IE11
import "svgxuse";

import "./globals/svgIcon";

Vue.config.productionTip = false;

// video player
Vue.use(Embed);

// vue-multiselect
Vue.component("multiselect", Multiselect);

const MONITORED_ENVIRONMENTS = ["staging", "production"];
const SENTRY_DSN =
  "https://bcf9701c28b94b58ac0e985f71632a9f@o198669.ingest.sentry.io/6078947";
if (MONITORED_ENVIRONMENTS.includes(process.env.NODE_ENV)) {
  Sentry.attachErrorHandler(Vue, {
    logErrors: true
  });

  Sentry.init({
    Vue,
    environment: process.env.NODE_ENV,
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_API_URL]
      })
    ],
    tracesSampleRate: 1.0,
    release: process.env.VUE_APP_RELEASE_TAG
  });
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
