<template>
  <div v-if="links.length" class="s-mrgb-06">
    <h2 class="h2">Links</h2>
    <ul class="links">
      <li v-for="link in links" :key="link.label">
        <a :href="link.to">
          <svg-icon name="arrow-long-right" class="icon icon--small" />{{
            link.label
          }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Links",
  props: {
    links: {
      type: Array,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
.links {
  list-style: none;
  padding: 0;
}
</style>
