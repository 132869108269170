import { Occupation } from "./Occupation.model";

class Credit {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.occupation = new Occupation(payload.occupation) || null;
    this.occupationName = payload.occupationName || null;
    this.user = payload.user || {};
    this.company = payload.company || {};
    this.hideInProfile = payload.hideInProfile;
  }
}

export { Credit };
