var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "mounting-portal",
        { attrs: { append: "", "mount-to": _vm.mountPoint } },
        [
          _c(
            "transition",
            {
              attrs: {
                "enter-active-class": "transition-opacity transition-ease-out",
                "leave-active-class": "transition-opacity transition-ease-in",
                "enter-class": "opacity-0",
                "enter-to-class": "opacity-100",
                "leave-class": "opacity-100",
                "leave-to-class": "opacity-0"
              }
            },
            [
              _vm.isOpen
                ? _c(
                    "div",
                    {
                      staticClass: "modal",
                      class: _vm.classModal,
                      attrs: {
                        role: "dialog",
                        "aria-hidden": _vm.isOpen ? "false" : "true",
                        "aria-labelledby": "modal-header",
                        "aria-describedby": "modal-body"
                      },
                      on: {
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape"
                            ])
                          )
                            return null
                          return _vm.hideModal.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "modal__backdrop",
                        class: _vm.classBackdrop,
                        on: { click: _vm.hideModal }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "modal__container",
                          class: _vm.classContainer,
                          attrs: { id: "modal-container" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "modal__header",
                              class: _vm.classHeader,
                              attrs: { id: "modal-header" }
                            },
                            [
                              _vm._t("header", function() {
                                return [_vm._v("   ")]
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "modal__btn-close",
                                  class: _vm.classBtnClose,
                                  attrs: { "aria-label": "Close Modal" },
                                  on: { click: _vm.hideModal }
                                },
                                [
                                  _c("svg-icon", {
                                    staticClass: "svg-icon icon icon--small",
                                    attrs: { name: "close", focusable: "false" }
                                  })
                                ],
                                1
                              )
                            ],
                            2
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "modal__body",
                              class: _vm.classBody,
                              attrs: { id: "modal-body" }
                            },
                            [_vm._t("default")],
                            2
                          ),
                          _vm.hasFooterSlot
                            ? _c(
                                "div",
                                {
                                  staticClass: "modal__footer",
                                  class: _vm.classFooter
                                },
                                [_vm._t("footer")],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }