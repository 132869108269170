var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex flex--column" },
      [
        _vm._l(_vm.movie.movieImages.slice(1), function(movieImage, key) {
          return _c("div", { key: key, staticClass: "s-mrgb-06" }, [
            _c("img", {
              staticClass: "screenshot",
              attrs: { src: movieImage.image, alt: "Film Screenshots" }
            })
          ])
        }),
        _c("div", [
          _c("div", [
            _c("h2", { staticClass: "h1 s-mrgb-01" }, [
              _vm._v(_vm._s(_vm.movie.title))
            ]),
            _vm.direction
              ? _c("h2", { staticClass: "h1 s-mrgb-06" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("Movie.detail.movieBy")) +
                      " " +
                      _vm._s(_vm.direction) +
                      " "
                  )
                ])
              : _vm._e()
          ]),
          _vm.movie.movieCredits
            ? _c(
                "div",
                { attrs: { id: "allCredits" } },
                [
                  _c("MovieMainCastCredits", {
                    attrs: { "credit-list": _vm.movie.movieCredits }
                  }),
                  _vm.hasCredits
                    ? _c("MovieCredits", {
                        attrs: { "credit-list": _vm.movie.movieCredits }
                      })
                    : _vm._e(),
                  _vm.hasInfo
                    ? _c("FurtherInfos", { attrs: { movie: _vm.movie } })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }