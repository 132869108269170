var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "form-item s-mrgt-03" },
          [
            _c("h2", [
              _vm._v(_vm._s(_vm.$t("MemberProfile.form.credits.name")))
            ]),
            _vm._l(_vm.creditList, function(credit, creditIndex) {
              return _c(
                "div",
                { key: credit.id, staticClass: "form-item-input" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "s-mrgt-06 s-mrgb-06",
                      attrs: { for: "memberProfileCreditTitle" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("MemberProfile.form.credits.title")) +
                          ": "
                      ),
                      _c("span", { staticClass: "error" }, [_vm._v("*")])
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: credit.title,
                            expression: "credit.title"
                          }
                        ],
                        staticClass: "s-mrgb-04",
                        attrs: {
                          type: "text",
                          required: "",
                          id: "memberProfileCreditTitle"
                        },
                        domProps: { value: credit.title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(credit, "title", $event.target.value)
                          }
                        }
                      }),
                      _c(
                        "label",
                        { attrs: { for: "memberProfileCreditYear" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("MemberProfile.form.credits.year")) +
                              ": "
                          ),
                          _c("span", { staticClass: "error" }, [_vm._v("*")])
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: credit.year,
                            expression: "credit.year"
                          }
                        ],
                        staticClass: "s-mrgb-04",
                        attrs: {
                          type: "number",
                          min: "1800",
                          max: "2099",
                          step: "1",
                          id: "memberProfileCreditYear",
                          required: ""
                        },
                        domProps: { value: credit.year },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(credit, "year", $event.target.value)
                          }
                        }
                      }),
                      _c("label", { attrs: { for: "memberCreditDirection" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("MemberProfile.form.credits.direction")
                            ) +
                            " "
                        ),
                        _c("span", { staticClass: "error" }, [_vm._v("*")])
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: credit.direction,
                            expression: "credit.direction"
                          }
                        ],
                        staticClass: "s-mrgb-02",
                        attrs: {
                          type: "text",
                          id: "memberCreditDirection",
                          required: ""
                        },
                        domProps: { value: credit.direction },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(credit, "direction", $event.target.value)
                          }
                        }
                      }),
                      _c(
                        "label",
                        { attrs: { for: "memberCreditProduction" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("MemberProfile.form.credits.production")
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "error" }, [_vm._v("*")])
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: credit.production,
                            expression: "credit.production"
                          }
                        ],
                        staticClass: "s-mrgb-02",
                        attrs: {
                          type: "text",
                          id: "memberCreditProduction",
                          required: ""
                        },
                        domProps: { value: credit.production },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(credit, "production", $event.target.value)
                          }
                        }
                      }),
                      _c("label", { attrs: { for: "memberCreditCredits" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("MemberProfile.form.credits.credits")
                            ) +
                            " "
                        ),
                        _c("span", { staticClass: "error" }, [_vm._v("*")])
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: credit.credit,
                            expression: "credit.credit"
                          }
                        ],
                        staticClass: "s-mrgb-02",
                        attrs: {
                          type: "text",
                          id: "memberCreditCredits",
                          required: ""
                        },
                        domProps: { value: credit.credit },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(credit, "credit", $event.target.value)
                          }
                        }
                      }),
                      _c("Type", {
                        attrs: { types: _vm.typeList },
                        model: {
                          value: credit.memberType,
                          callback: function($$v) {
                            _vm.$set(credit, "memberType", $$v)
                          },
                          expression: "credit.memberType"
                        }
                      }),
                      _c("Format", {
                        attrs: { formats: _vm.formatList },
                        model: {
                          value: credit.memberFormat,
                          callback: function($$v) {
                            _vm.$set(credit, "memberFormat", $$v)
                          },
                          expression: "credit.memberFormat"
                        }
                      }),
                      _c("Style", {
                        attrs: { styles: _vm.styleList },
                        model: {
                          value: credit.memberStyles,
                          callback: function($$v) {
                            _vm.$set(credit, "memberStyles", $$v)
                          },
                          expression: "credit.memberStyles"
                        }
                      })
                    ],
                    1
                  ),
                  _c("input", {
                    staticClass: "form-button s-mrgt-02 s-mrgb-05",
                    attrs: { type: "button", value: "Credit löschen" },
                    on: {
                      click: function($event) {
                        return _vm.removeMemberCredit(creditIndex)
                      }
                    }
                  })
                ]
              )
            }),
            _c("input", {
              staticClass: "form-button s-mrgt-02",
              attrs: {
                type: "button",
                value: _vm.$t("MemberProfile.form.credits.addButton")
              },
              on: { click: _vm.addMemberCredit }
            })
          ],
          2
        )
      : _c("div", [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("MemberProfile.form.helpTexts.fetchingData")))
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }