var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login flex flex--column align-center" }, [
    _c("h1", { staticClass: "s-mrgb-05" }, [
      _vm._v(_vm._s(_vm.$t("Login.title")))
    ]),
    _c("form", [
      _c("div", { staticClass: "flex flex--column align-start s-mrgb-04" }, [
        _c("label", { attrs: { for: "email" } }, [
          _vm._v(" " + _vm._s(_vm.$t("Login.email")) + ": ")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputs.email,
              expression: "inputs.email"
            }
          ],
          attrs: { type: "text", id: "email" },
          domProps: { value: _vm.inputs.email },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.inputs, "email", $event.target.value)
            }
          }
        })
      ]),
      _c("div", { staticClass: "flex flex--column align-start s-mrgb-04" }, [
        _c("label", { attrs: { for: "password" } }, [
          _vm._v(" " + _vm._s(_vm.$t("Login.password")) + ": ")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputs.password,
              expression: "inputs.password"
            }
          ],
          attrs: { type: "password", id: "password" },
          domProps: { value: _vm.inputs.password },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.inputs, "password", $event.target.value)
              },
              _vm.validate
            ],
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.submitLogin.apply(null, arguments)
            }
          }
        })
      ])
    ]),
    _c(
      "p",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loginError,
            expression: "loginError"
          }
        ],
        staticClass: "error"
      },
      [_vm._v(" " + _vm._s(_vm.$t("Login.errorOccurred")) + " ")]
    ),
    _c(
      "button",
      {
        staticClass: "button button--primary s-mrgb-05",
        attrs: { disabled: _vm.isDisabled },
        on: { click: _vm.submitLogin }
      },
      [_vm._v(" " + _vm._s(_vm.$t("Login.login")) + " ")]
    ),
    _c(
      "div",
      { staticClass: "flex flex--column" },
      [
        _c(
          "router-link",
          {
            attrs: {
              to: {
                name: "PasswordResetEmail",
                params: { lang: this.$store.state.locale }
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("Login.resetPassword")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }