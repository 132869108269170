<template>
  <div class="login flex flex--column align-center">
    <h1 class="s-mrgb-05">{{ $t("Login.title") }}</h1>
    <form>
      <div class="flex flex--column align-start s-mrgb-04">
        <label for="email"> {{ $t("Login.email") }}: </label>
        <input v-model="inputs.email" type="text" id="email" />
      </div>

      <div class="flex flex--column align-start s-mrgb-04">
        <label for="password"> {{ $t("Login.password") }}: </label>
        <input
          v-model="inputs.password"
          type="password"
          id="password"
          @input="validate"
          @keyup.enter="submitLogin"
        />
      </div>
    </form>
    <p class="error" v-show="loginError">
      {{ $t("Login.errorOccurred") }}
    </p>
    <button
      class="button button--primary s-mrgb-05"
      @click="submitLogin"
      :disabled="isDisabled"
    >
      {{ $t("Login.login") }}
    </button>

    <div class="flex flex--column">
      <router-link
        :to="{
          name: 'PasswordResetEmail',
          params: { lang: this.$store.state.locale }
        }"
        >{{ $t("Login.resetPassword") }}</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { login } from "../services/Login.service.js";

export default {
  name: "Login",
  data() {
    return {
      inputs: {
        email: "",
        password: ""
      },
      btnDisabled: false
    };
  },
  computed: {
    ...mapState("AuthStore", ["loginError"]),
    isDisabled: function() {
      return !this.btnDisabled;
    }
  },
  methods: {
    ...mapActions("AuthStore", [
      "setSessionStorage",
      "setTokenState",
      "setLoginFailure",
      "setLoginSuccess",
      "clearLoginStatus",
      "setIsLoggedIn"
    ]),
    ...mapActions("UserStore", ["setLocalStorage", "setUserState"]),
    ...mapActions("MemberProfileStore", ["setMemberProfile"]),
    /**
     * Submits the new password to auth-rest
     */
    submitLogin: async function() {
      // call the api to reset the password
      await login(this.inputs)
        .then(response => {
          this.setSessionStorage(response.data);
          this.setTokenState(response.data);
          this.setLoginSuccess();
          this.setIsLoggedIn(true);
          this.setLocalStorage(response.data.user);
          this.setUserState(response.data.user);
          if (response.data.user.memberProfile) {
            this.setMemberProfile(response.data.user.memberProfile);
          }
          this.$router.push({ name: "MoviesListView" });
        })
        .catch(() => {
          this.setLoginFailure(true);
        });
    },
    validate: function() {
      if (this.inputs.password != null && this.inputs.email) {
        // remove error message then enable login button
        this.setLoginFailure(false);
        this.btnDisabled = true;
      } else {
        // activate error message then disable login button
        this.btnDisabled = false;
        this.setLoginFailure(true); // there is an error
      }
    }
  },
  mounted() {
    this.clearLoginStatus();
  }
};
</script>
