var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.companies, function(company, index) {
      return _c(
        "div",
        { key: `company--${index}`, staticClass: "row col-xs-12" },
        [
          _c("div", { staticClass: "col-t-md-8" }, [
            _c("div", { staticClass: "s-mrgb-06" }, [
              _c(
                "h2",
                {
                  staticClass: "h1",
                  on: {
                    mouseover: function($event) {
                      return _vm.setSelectedCompany(company)
                    },
                    mouseout: function($event) {
                      return _vm.setSelectedCompany(null)
                    }
                  }
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "Company",
                          params: { Cid: company.id }
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(company.name) + " ")]
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "col-t-md-4 portrait-container" }, [
            _c("div", { staticClass: "portrait" }, [
              _vm.selectedCompany && _vm.selectedCompany.id === company.id
                ? _c("p", [
                    company.portrait
                      ? _c("img", {
                          attrs: { src: company.portrait, alt: "" },
                          on: { error: _vm.usePlaceholder }
                        })
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }