<template>
  <div class="form-item">
    <label for="phone"
      >{{ $t("MemberProfile.form.labels.phone") }}:
      <span class="error">*</span></label
    >
    <input type="tel" v-model="memberProfilePhone" id="phone" />
    <p class="tip text-small">{{ $t("Generic.form.helpText.phoneNumber") }}</p>
    <MemberProfileShowPhone v-model="memberProfileShowPhone" />
  </div>
</template>

<script>
import MemberProfileShowPhone from "./MemberProfileShowPhone";
export default {
  name: "MemberProfilePhone",
  components: {
    MemberProfileShowPhone
  },
  props: {
    value: {
      type: String,
      required: true
    },
    showPhone: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    memberProfilePhone: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    },
    memberProfileShowPhone: {
      get() {
        return this.showPhone;
      },
      set(newVal) {
        this.$emit("setMemberProfileShowPhone", newVal);
      }
    }
  }
};
</script>
