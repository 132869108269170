<template>
  <div class="s-mrgb-10">
    <img
      class="s-mrgb-06"
      v-if="movie.movieImages.length > 0"
      :src="movie.movieImages[0].image"
      alt="Filmposter"
      @error="usePlaceholder"
    />
    <h2 class="h1">{{ movie.title }}</h2>
    <div>
      <span v-if="movieDirectorsString">{{ movieDirectorsString }} – </span>
      <span
        >{{
          [
            movie.publishedYear,
            movie.duration
              ? `${movie.duration} ${$t("Movie.list.duration.minutes")}`
              : null,
            movie.movieType.name,
            completionStatus
          ]
            .filter(t => !!t)
            .join(", ")
        }}
      </span>
    </div>
    <div v-if="movie.trailer" class="flex align-center s-mrgb-06">
      <a href="#trailer">
        <svg-icon name="long-arrow-down" class="icon icon--small" />
        {{ $t("Movie.detail.trailer") }}
      </a>
    </div>
    <div class="text-large s-mrgb-06">
      <h2>{{ $t("Movie.form.labels.Synopsis") }}:</h2>
      <p>{{ translateObj(movie, "synopsis") }}</p>
    </div>
    <video-embed id="trailer" :src="movie.trailer"></video-embed>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Main",
  data() {
    return {
      placeholderPoster: "https://via.placeholder.com/660x1000/808080/FFFFFF/?text=Movie+Poster+Placeholder"
    };
  },
  props: ["movie"],
  computed: {
    completionStatus() {
      const status = {
        2: this.$i18n.t("Movie.list.completionStatus.inProduction"),
        3: this.$i18n.t("Movie.list.completionStatus.inDevelopment")
      };
      return status[this.movie.completionStatus];
    },
    movieDirectorsString() {
      return this.movie.movieCredits
        .filter(credit => credit.occupation.credit === "Regie")
        .map(director => director.user.fullName)
        .join(", ");
    }
  },
  methods: {
    usePlaceholder(e) {
      e.target.src = this.placeholderPoster;
    },
    translateObj: function(object, key) {
      // this will evaluate to movie[title_code] || movie[title]
      return object[key + "_" + this.$store.state.locale] || object[key];
    }
  }
});
</script>

<style lang="scss" scoped></style>
