<template>
  <div>
    <div class="s-mrgb-05 s-mrgt-08">
      <h1>{{ $t("MemberProfile.form.create.title") }}</h1>
    </div>
    <MemberProfileEditForm
      @postMemberProfile="postMemberProfile"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import MemberProfileEditForm from "@/components/MemberProfiles/Forms/MemberProfileEditForm";
import { postMemberProfile } from "@/services/memberProfile.service";
import { uploadMemberProfileMedia } from "@/services/memberProfile.service";
import { mapActions, mapState } from "vuex";

export default {
  name: "MemberProfileCreateView",
  components: {
    MemberProfileEditForm
  },
  computed: {
    ...mapState("UserStore", ["authUser"])
  },
  methods: {
    ...mapActions("MemberProfileStore", ["setMemberProfileDetail"]),
    ...mapActions("UserStore", ["setLocalStorage"]),
    async postMemberProfile(memberProfile, portrait, portraitChanged, pdf) {
      await postMemberProfile(memberProfile).then(response => {
        this.setMemberProfileDetail(response.data.id)
          .then(() => {
            let data = this.authUser;
            data["memberProfile"] = response.data.id;
            this.setLocalStorage(data);
            if (portrait) {
              portrait.append("pdf", pdf);
              uploadMemberProfileMedia(response.data.id, portrait).then(() => {
                this.onFormSuccess(response);
              });
            } else {
              this.onFormSuccess(response);
            }
          })
          .catch(() => {
            this.$toaster.error(
              this.$i18n.t("Toaster.memberProfileCreation.failure")
            );
          });
      });
    },
    onFormSuccess(response) {
      this.$router
        .push({
          name: "MemberProfileDetailView",
          params: { MpId: response.data.id }
        })
        .then(() => {
          this.$toaster.success(
            this.$i18n.t("Toaster.memberProfileCreation.success")
          );
        });
    },
    cancel() {
      this.$router.push({ name: "MoviesListView" });
    }
  }
};
</script>
