<template>
  <div class="detail-layout">
    <div class="row" v-if="!isLoading">
      <!-- Company header -->
      <div class="col-xs-12 col-t-md-4">
        <h1 class="s-mrgb-06">{{ $t("Company.title") }}</h1>
      </div>
      <div class="col-xs-12 col-t-md-8 flex t-md-justify-end">
        <router-link
          class="h1 s-mrgb-05"
          :to="{
            name: 'Companies'
          }"
          >{{ $t("Movie.detail.toOverview") }}
        </router-link>
      </div>
      <div class="col-xs-12 col-t-md-8">
        <h2 class="h1">{{ company.name }}</h2>
        <div class="row col-xs-12">
          <!-- Services -->
          <div v-if="company.services.length" class="s-mrgt-02">
            <p class="s-mrgr-02">
              {{ $t("Company.services") }}: {{ serviceNameList }}
            </p>
          </div>
        </div>
        <div class="text-large s-mrgt-04 s-mrgb-06">
          <p>{{ company.description }}</p>
        </div>
        <div>
          <!-- Contact -->
          <div class="s-mrgt-05">
            <h2>{{ $t("Company.contact") }}</h2>
            <div v-if="company.website" class="flex align-center">
              <svg-icon name="arrow-long-right" class="icon icon--small" />
              <a :href="company.website" target="_blank" class="s-mrgl-02">
                {{ $t("Company.website") }}
              </a>
            </div>
            <div v-if="company.email" class="flex align-center">
              <svg-icon name="arrow-long-right" class="icon icon--small" />
              <a
                :href="`mailto:${company.email}`"
                target="_blank"
                class="s-mrgl-02"
              >
                {{ $t("Company.email") }}
              </a>
            </div>
            <div>
              <p>{{ company.address }}</p>
              <p>{{ company.zip }} {{ company.place }}</p>
              <p>{{ company.phone }}</p>
            </div>
          </div>
          <!-- Employees -->
          <div v-if="company.employees.length" class="s-mrgt-05">
            <h2>{{ $t("Company.employees") }}</h2>
            <div
              v-for="(employee, index) in company.employees"
              :key="`employee--${index}---${employee.id}`"
              class="flex align-center"
            >
              <router-link
                v-if="employee.user && employee.user.memberProfile"
                class="flex align-center"
                :to="{
                  name: 'MemberProfileDetailView',
                  params: { MpId: employee.user.memberProfile }
                }"
              >
                <svg-icon name="arrow-long-right" class="icon icon--small" />
                <p class="s-mrg-02">
                  {{ employee.name }}
                </p>
              </router-link>
              <p v-else class="s-mrg-02">
                {{ employee.name }}
              </p>
              <a :href="`mailto:${employee.email}`">{{ employee.email }}</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="company.portrait" class="col-xs-12 col-t-md-4">
        <img
          @error="usePlaceholder"
          :src="company.portrait"
          alt="Company portrait"
        />
      </div>
      <div v-if="isOwnerOfProfile" class="col-xs-12">
        <router-link
          :to="{
            name: 'CompanyEditView'
          }"
        >
          <button type="button" class="button">
            {{ $t("Company.edit") }}
          </button>
        </router-link>
      </div>

      <!-- Projects -->
      <div class="col-xs-12">
        <div class="col-t-md-8">
          <Projects
            :profile="company"
            :profile-type="'company'"
            :member-credit-list="this.company.profileCredits"
          />
        </div>
        <ProductionCredits
          :production-credit-list="company.productionCredits"
        />
      </div>
    </div>
    <loader v-else></loader>
  </div>
</template>

<script>
import { getCompany } from "@/services/Company.service";
import { mapState } from "vuex";
import Projects from "../../components/MemberProfiles/Projects";
import loader from "../../components/loader";
import ProductionCredits from "../../components/MemberProfiles/ProductionCredits";

export default {
  name: "Company",
  components: {
    Projects,
    ProductionCredits,
    loader
  },
  data() {
    return {
      isLoading: true,
      company: null,
      placeholderProfile:
        "https://via.placeholder.com/200x300/808080/FFFFFF/?text=User+Profile+Placeholder"
    };
  },
  async mounted() {
    this.isLoading = true;
    this.company = await getCompany(this.$route.params.Cid, "companydetail");
    this.isLoading = false;
  },
  computed: {
    ...mapState("UserStore", ["authUser"]),
    isOwnerOfProfile() {
      if (!this.authUser) return false;

      return this.authUser.companies.includes(this.company.id);
    },
    serviceNameList() {
      return this.company.services
        .filter(service => service.isUnlocked)
        .map(service => service.occupation.description)
        .join(", ");
    }
  },
  methods: {
    usePlaceholder(e) {
      e.target.src = this.placeholderProfile;
    }
  },
  watch: {
    "$route.params": {
      async handler() {
        if (!this.isLoading) {
          this.company = await getCompany(
            this.$route.params.Cid,
            "companydetail"
          );
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped></style>
