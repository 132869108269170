var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "s-mrgt-05" }, [
    _c("div", { staticClass: "row" }, [
      _vm.productionCredits.length
        ? _c("h2", { staticClass: "h1 col-xs-12 s-mrgb-08" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("MemberProfile.detail.productions")) + " "
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-xs-12 col-t-md-12" },
        _vm._l(_vm.productionCredits, function(memberCredit) {
          return _c("div", { key: memberCredit.id }, [
            _c("div", { staticClass: "row s-mrgb-09" }, [
              _c("h3", { staticClass: "h1 col-xs-12 col-t-md-1" }, [
                _vm._v(_vm._s(memberCredit.year))
              ]),
              _c("div", { staticClass: "col-xs-12 col-t-md-3 s-mrgb-03" }, [
                _c("h3", [_vm._v(_vm._s(memberCredit.title))])
              ]),
              _c("div", { staticClass: "col-xs-12 col-t-md-8" }, [
                _c("div", { staticClass: "row col-xs-12" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-02" },
                    [
                      _c("svg-icon", {
                        staticClass: "icon icon--small",
                        attrs: { name: "regie_path" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-xs-9 s-mrgl-04" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", [_vm._v(_vm._s(memberCredit.direction) + ",")]),
                      _c("div", [
                        _c("p", { staticClass: "s-mrgl-02" }, [
                          _vm._v("Rolle: " + _vm._s(memberCredit.credits))
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", [
                        _vm._v(_vm._s(memberCredit.production) + ",")
                      ]),
                      _c("div", { staticClass: "s-mrgl-02" }, [
                        _vm._v(
                          " " + _vm._s(memberCredit.memberFormat.name) + " "
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }