<template>
  <div class="form-item">
    <label class="typo__label" for="ajax"
      >{{ $t("Movie.form.labels.originalLang") }}:
      <span class="error">*</span></label
    >
    <div class="multiselect-wrapper">
      <multiselect
        v-model="movieOriginalLanguages"
        id="ajax"
        label="language"
        track-by="id"
        :placeholder="$t('Movie.form.labels.search.typeToSearch')"
        open-direction="bottom"
        :options="originalLangMultiSelect.languages"
        :multiple="true"
        :searchable="true"
        :loading="originalLangMultiSelect.isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        @search-change="searchLanguageList"
      >
        <template slot="tag" slot-scope="{ option, remove }"
          ><span class="custom__tag"
            ><span>{{ option.language }}</span
            ><span class="custom__remove" @click="remove(option)"
              ><svg-icon name="times" class="icon icon--small"/></span></span
        ></template>
        <span v-if="originalLangMultiSelect.languages" slot="noResult">{{
          $t("Movie.form.errors.elementNotFound")
        }}</span>
      </multiselect>
      <p class="tip text-small">
        {{ $t("Movie.form.labels.search.liveSearch") }}
      </p>
    </div>
  </div>
</template>

<script>
import { getLanguageListBySearch } from "@/services/language.service";

export default {
  name: "OriginalLanguageMultiSelect",
  data() {
    return {
      originalLangMultiSelect: {
        languages: this.languageList,
        isLoading: false
      }
    };
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    languageList: {
      type: Array,
      required: true
    }
  },
  computed: {
    movieOriginalLanguages: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  },
  methods: {
    async searchLanguageList(searchQuery) {
      this.originalLangMultiSelect.isLoading = true;
      this.originalLangMultiSelect.languages = await getLanguageListBySearch(
        searchQuery
      );
      this.originalLangMultiSelect.isLoading = false;
    }
  }
};
</script>
