<template>
  <div>
    <h2 class="s-mrgb-06">{{ $t("MemberProfile.form.labels.movies") }}</h2>
    <div
      class="flex align-center justify-between s-mrgb-05"
      v-for="(credit, index) in uniqueCreditsByMovie"
      :key="`member-profile--movies---${index}`"
    >
      <router-link :to="{ name: 'MovieDetail', params: { Mid: credit.movie } }">
        <h2>{{ credit.title }}</h2>
      </router-link>
      <div>
        <input
          type="checkbox"
          @change="setMovieHidden($event, credit.movie)"
          :checked="hiddenMovies.indexOf(credit.movie) > -1"
        />
        <label>{{ $t("MemberProfile.form.labels.hide") }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Credits",
  props: {
    movieCredits: {
      type: Array,
      required: true
    },
    hiddenMovies: {
      type: Array,
      required: true
    }
  },
  methods: {
    setMovieHidden(e, movieId) {
      this.$emit("hideMovie", movieId, e.target.checked);
    }
  },
  computed: {
    uniqueCreditsByMovie() {
      return this.movieCredits.filter(
        (v, i, a) => a.findIndex(t => t.movie === v.movie) === i
      );
    }
  }
};
</script>

<style scoped></style>
