<template>
  <div>
    <div
      class="row col-xs-12"
      v-for="(company, index) in companies"
      :key="`company--${index}`"
    >
      <div class="col-t-md-8">
        <div class="s-mrgb-06">
          <h2
            class="h1"
            @mouseover="setSelectedCompany(company)"
            @mouseout="setSelectedCompany(null)"
          >
            <router-link
              :to="{
                name: 'Company',
                params: { Cid: company.id }
              }"
            >
              {{ company.name }}
            </router-link>
          </h2>
        </div>
      </div>
      <div class="col-t-md-4 portrait-container">
        <div class="portrait">
          <p v-if="selectedCompany && selectedCompany.id === company.id">
            <img
              @error="usePlaceholder"
              v-if="company.portrait"
              :src="company.portrait"
              alt=""
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompaniesList",
  data() {
    return {
      selectedCompany: null,
      placeholderLogo:
        "https://via.placeholder.com/200x150/808080/FFFFFF/?text=Company+Logo+Placeholder"
    };
  },
  props: {
    companies: {
      type: Array,
      required: true
    }
  },
  methods: {
    usePlaceholder(e) {
      e.target.src = this.placeholderLogo;
    },
    setSelectedCompany(company) {
      this.selectedCompany = company;
    },
    getOnlyUnlockedServices(company) {
      return company.services.filter(service => service.isUnlocked === true);
    }
  }
};
</script>

<style lang="scss">
.occupations:not(:last-of-type) {
  .credit:after {
    content: ",";
  }
}
.portrait-container {
  position: relative;
}
.portrait {
  position: absolute;
  img {
    width: 100%;
  }
}
</style>
