var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c(
      "div",
      { staticClass: "form-item-input" },
      [
        _c("multiselect-form", {
          attrs: {
            elementNotFoundText: _vm.$t("Movie.form.errors.elementNotFound"),
            placeholder: _vm.$t("Movie.form.labels.search.typeToSearch"),
            liveSearchText: _vm.$t("Movie.form.labels.search.liveSearch"),
            labelText: _vm.$t(
              "Movie.form.labels.coProductionLinkCreation.labels.coProductionLink"
            ),
            id: "coproduction_company",
            options: _vm.productionCompanyList,
            displayValueKey: "name"
          },
          on: { input: _vm.addCompany },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }