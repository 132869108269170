<template>
  <div>
    <h1>Page Not Found</h1>
    <p>oops! the page you're looking for doesn't exist or moved</p>
    <router-link :to="{ name: 'MoviesListView' }">
      why don't you go home instead?
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NotFoundView"
};
</script>
