var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.imageUpload.poster")))
    ]),
    _c("div", { staticClass: "form-item-input" }, [
      _c("input", {
        attrs: { type: "file", name: "imageUpload", accept: "image/*" },
        on: { change: _vm.submitImage }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }