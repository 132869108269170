var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Page Not Found")]),
      _c("p", [
        _vm._v("oops! the page you're looking for doesn't exist or moved")
      ]),
      _c("router-link", { attrs: { to: { name: "MoviesListView" } } }, [
        _vm._v(" why don't you go home instead? ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }