<template>
  <div v-if="mainCast.length || supportingCast.length" class="s-mrgb-06">
    <h2 class="h1 s-mrgr-02">{{ $t("Movie.detail.credits.cast") }}</h2>
    <div v-if="mainCast.length" class="row s-mrgb-04">
      <div class="s-mrgr-02 col-xs-12 col-t-md-4">
        <p>{{ $t("Movie.detail.credits.mainCast") }}</p>
      </div>
      <div class="s-mrgr-02 col-xs-12 col-t-md-4">
        <p v-for="{ name, to } in mainCast" :key="name">
          <router-link v-if="to" :to="to">
            <svg-icon name="arrow-long-right" class="icon icon--small" />
            {{ name }}
          </router-link>
          <span v-else>{{ name }}</span>
        </p>
      </div>
    </div>
    <div v-if="supportingCast.length" class="row s-mrgb-04">
      <div class="s-mrgr-02 col-xs-12 col-t-md-4">
        <p>{{ $t("Movie.detail.credits.supportingCast") }}</p>
      </div>
      <div class="s-mrgr-02 col-xs-12 col-t-md-6">
        <p v-for="{ name, to } in supportingCast" :key="name">
          <router-link v-if="to" :to="to">
            <svg-icon name="arrow-long-right" class="icon icon--small" />
            {{ name }}
          </router-link>
          <span v-else>{{ name }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MovieMainCastCredits",
  props: {
    creditList: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapState("MandateStore", ["mandate"]),
    mainCast() {
      return this.creditList
        .filter(credit =>
          credit.occupation.credit.includes(
            this.$t("Movie.detail.occupations.mainCast")
          )
        )
        .map(credit => ({
          name: credit.user.fullName,
          to: credit.user?.memberProfile
            ? {
                name: "MemberProfileDetailView",
                params: { MpId: credit.user?.memberProfile }
              }
            : ""
        }));
    },
    supportingCast() {
      return this.creditList
        .filter(credit =>
          credit.occupation.credit.includes(
            this.$t("Movie.detail.occupations.supportingCast")
          )
        )
        .map(credit => ({
          name: credit.user.fullName,
          to: credit.user?.memberProfile
            ? {
                name: "MemberProfileDetailView",
                params: { MpId: credit.user?.memberProfile }
              }
            : ""
        }));
    }
  },
  methods: {
    getFullName(user) {
      return `${user.firstName} ${user.lastName}`;
    },
    isCurrentMandate(credit) {
      const mandates = credit.user.mandates;
      let isCurrentMandate = false;
      mandates.forEach(mandate => {
        if (mandate.name === this.mandate.name) {
          isCurrentMandate = true;
        }
      });
      return isCurrentMandate;
    }
  }
};
</script>
