<template>
  <div class="row" v-if="isLoaded">
    <div class="col-xs-12 col-t-md-12 s-mrgb-06">
      <Header />
    </div>
    <div class="col-t-md-4 col-xs-12">
      <Sidebar
        :movie="selectedMovie"
        :hasCredits="hasCredits"
        :hasLinks="hasLinks"
        :hasFestivals="hasFestivals"
        :hasInfo="hasInfo"
        :hasAwards="hasAwards"
      />
    </div>
    <div class="col-xs-12 col-t-md-8">
      <Main :movie="selectedMovie" />
    </div>
    <div class="col-xs-12 col-t-md-12">
      <Bottom
        :movie="selectedMovie"
        :hasCredits="hasCredits"
        :hasFestivals="hasFestivals"
        :hasInfo="hasInfo"
        :hasAwards="hasAwards"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Sidebar from "@/components/movies/MovieDetail/Sidebar";
import Main from "@/components/movies/MovieDetail/Main";
import Header from "@/components/movies/MovieDetail/Header";
import Bottom from "@/components/movies/MovieDetail/Bottom";

export default {
  name: "MovieDetail",
  components: {
    Sidebar,
    Main,
    Header,
    Bottom
  },
  data() {
    return {
      movieId: this.$route.params.Mid,
      errMsg: false,
      isLoaded: false
    };
  },
  computed: {
    ...mapState("MoviesStore", ["selectedMovie"]),
    hasFestivals() {
      return !!this.selectedMovie.additionalFestivals;
    },
    hasAwards() {
      return !!this.selectedMovie.awards;
    },
    hasInfo() {
      return !!(
        this.selectedMovie.subtitle ||
        this.selectedMovie.originalLang ||
        this.selectedMovie.dubbedLang ||
        this.selectedMovie.audioFormat ||
        this.selectedMovie.screeningFormat ||
        this.selectedMovie.imageFormat ||
        this.selectedMovie.recordingFormat
      );
    },
    hasCredits() {
      return !!(
        this.selectedMovie.mainCredits || this.selectedMovie.movieCredits
      );
    },
    hasLinks() {
      return !!(
        this.selectedMovie.website ||
        (this.selectedMovie.rentalLink && this.selectedMovie.rentalLink.url) ||
        (this.selectedMovie.worldSalesLink &&
          this.selectedMovie.worldSalesLink.url) ||
        this.selectedMovie.vodLink ||
        this.selectedMovie.dvdBlurayLink
      );
    },
    completionStatus() {
      const status = {
        2: this.$i18n.t("Movie.list.completionStatus.inProduction"),
        3: this.$i18n.t("Movie.list.completionStatus.inDevelopment")
      };
      return status[this.selectedMovie.completionStatus];
    },
    movieDirectorsString() {
      return this.selectedMovie.movieCredits
        .filter(credit => credit.occupation.credit === "Regie")
        .map(director => director.user.fullName)
        .join(", ");
    }
  },
  methods: {
    ...mapActions("MoviesStore", ["setSelectedMovie"]),
    translateObj: function(object, key) {
      // this will evaluate to movie[title_code] || movie[title]
      return object[key + "_" + this.$store.state.locale] || object[key];
    },
    languagesString(languages) {
      return languages.map(langObj => langObj.language).join(", ");
    }
  },
  async mounted() {
    await this.setSelectedMovie(this.$route.params.Mid);
    this.isLoaded = true;
  }
};
</script>

<style lang="scss" scoped>
.festivals {
  white-space: pre-line;
}
</style>
