class Company {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.name = payload.name || "";
    this.portrait = payload.portrait || null;
    this.email = payload.email || null;
    this.phone = payload.phone || null;
    this.mandates = payload.mandates || [];
    this.description = payload.description || "";
    this.website = payload.website || null;
    this.address = payload.address || "";
    this.category = payload.category || null;
    this.zip = payload.zip || null;
    this.place = payload.place || "";
    this.employees = payload.employees || null;
    this.services = payload.services || null;
    this.availableServices = payload.availableServices || null;
    this.movies = payload.movies || null;
    this.companyCredits = payload.companyCredits || null;
    this.productionCredits = payload.productionCredits || [];
    this.profileCredits = payload.profileCredits || [];
    this.hiddenMovies = payload.hiddenMovies || [];
    this.moviesWithProduction = payload.moviesWithProduction || [];
    this.moviesWithCoProduction = payload.moviesWithCoProduction || [];
    this.publishedMovieCredits = payload.publishedMovieCredits || [];
    this.isActive = payload.isActive || false;
  }
}

export { Company };
