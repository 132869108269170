import { axiosBaseService } from "./axiosBase.service";
import store from "../store/index.js";
/**
 * Resets the password
 */

const refreshToken = async () => {
  try {
    const url = `/${store.state.locale}/usersapi/token/refresh/`;
    const response = await axiosBaseService.post(url, {
      refresh: store.state.AuthStore.refreshToken
    });
    if (response.status !== 200) {
      return response.statusText;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export { refreshToken };
