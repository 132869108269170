<template>
  <div id="furtherInfo">
    <div class="s-mrgb-06">
      <h2 class="h1 s-mrgr-02">{{ $t("Movie.detail.technical") }}</h2>
      <div
        v-for="[label, format] in formats"
        class="row s-mrgb-04"
        :key="label"
      >
        <div class="s-mrgr-02 col-xs-12 col-t-md-4">
          <p>{{ label }}</p>
        </div>
        <div class="s-mrgr-02 col-xs-12 col-t-md-6">
          <p>
            {{ format }}
          </p>
        </div>
      </div>
    </div>
    <div class="s-mrgb-06">
      <h2 class="h1 s-mrgr-02">{{ $t("Movie.detail.credits.language") }}</h2>
      <div
        v-for="[label, language] in languages"
        class="row s-mrgb-04"
        :key="label"
      >
        <div class="s-mrgr-02 col-xs-12 col-t-md-4">
          <p>{{ label }}</p>
        </div>
        <div class="s-mrgr-02 col-xs-12 col-t-md-6">
          <p>
            {{ language }}
          </p>
        </div>
      </div>
    </div>

    <div class="s-mrgb-06 row" v-if="supporters.length">
      <h2 class="h1 s-mrgr-02 col-xs-12 col-t-md-4">
        {{ $t("Movie.detail.credits.sponsoring") }}
      </h2>
      <div class="s-mrgr-02 col-xs-12 col-t-md-6">
        <p v-for="sponsor in movie.supporters" :key="sponsor.id">
          {{ sponsor.agency }}
        </p>
      </div>
    </div>

    <div class="s-mrgb-06 row" v-if="movie.isan">
      <h2 class="h1 s-mrgr-02 col-xs-12 col-t-md-4">
        {{ $t("Movie.detail.credits.isan") }}
      </h2>
      <div class="s-mrgr-02 col-xs-12 col-t-md-6">
        <p>{{ movie.isan }}</p>
      </div>
    </div>

    <div id="awards" class="s-mrgb-06 row" v-if="awards.length">
      <h2 class="h1 s-mrgr-02  col-xs-12 col-t-md-4">
        {{ $t("Movie.detail.credits.awards") }}
      </h2>
      <div class="s-mrgr-02 col-xs-12 col-t-md-6">
        <p v-for="award in awards" :key="award">{{ award }}</p>
      </div>
    </div>

    <div id="festivals" class="s-mrgb-06 row" v-if="festivals.length">
      <h2 class="h1 s-mrgr-02 col-xs-12 col-t-md-4">
        {{ $t("Movie.detail.credits.festivals") }}
      </h2>
      <div class="s-mrgr-02 col-xs-12 col-t-md-6">
        <p v-for="festival in festivals" :key="festival">{{ festival }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "FurtherInfos",
  props: {
    movie: {
      type: Object
    }
  },
  methods: {
    getLanguageString(list) {
      return list.map(lang => lang.language).join(", ");
    }
  },
  computed: {
    awards() {
      if (!this.movie.awards) return [];
      return this.movie.awards.split("\n");
    },
    festivals() {
      if (!this.movie.additionalFestivals) return [];
      return this.movie.additionalFestivals.split("\n");
    },
    supporters() {
      if (!this.movie.additionalFestivals) return [];
      return this.movie.additionalFestivals.split("\n");
    },
    formats() {
      const formatMap = {
        [this.$t("Movie.detail.credits.recordingFormat")]: this.movie
          .recordingFormat,
        [this.$t("Movie.detail.credits.imageFormat")]: this.movie.imageFormat,
        [this.$t("Movie.detail.credits.screeningFormat")]: this.movie
          .screeningFormat,
        [this.$t("Movie.detail.credits.audioFormat")]: this.movie.audioFormat
      };

      return Object.entries(formatMap).filter(([_label, format]) => format);
    },
    languages() {
      const languageMap = {
        [this.$t("Movie.detail.credits.originalLang")]: this.getLanguageString(
          this.movie.originalLang
        ),
        [this.$t("Movie.detail.credits.subtitle")]: this.getLanguageString(
          this.movie.subtitle
        ),
        [this.$t("Movie.detail.credits.dubbedLang")]: this.getLanguageString(
          this.movie.dubbedLang
        )
      };

      return Object.entries(languageMap).filter(
        ([_label, language]) => language
      );
    }
  }
});
</script>
