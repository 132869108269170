var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-item" }, [
    _c("label", [
      _vm._v(_vm._s(_vm.$t("Movie.form.labels.imageUpload.movieImages")) + " "),
      _c("span", { staticClass: "error" }, [_vm._v("*")])
    ]),
    _c("div", { staticClass: "form-item-input" }, [
      _c(
        "div",
        { staticClass: "s-mrgb-04 row" },
        [
          _vm._l(_vm.movieImages, function(movieImage, key) {
            return _c(
              "div",
              {
                key: key,
                staticClass: "image-thumbnail col-xs-12 col-t-md-4 s-mrgb-04"
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !movieImage.showThumbnail,
                      expression: "!movieImage.showThumbnail"
                    }
                  ],
                  staticClass: "image-thumbnail--thumbnail",
                  attrs: {
                    id: key,
                    src: movieImage.image,
                    alt: _vm.$t("Movie.form.edit.movieImages.alt")
                  }
                }),
                movieImage.showThumbnail
                  ? _c("p", { staticClass: "s-pdg-04" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("Movie.form.edit.movieImages.noImage")
                          ) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "image-thumbnail--meta s-pdg-02" }, [
                  !movieImage.image
                    ? _c("input", {
                        staticClass: "no-border image-thumbnail__upload",
                        attrs: {
                          type: "file",
                          accept: "image/*",
                          name: "multipleImageUpload"
                        },
                        on: {
                          change: function($event) {
                            return _vm.uploadMovieImage($event, movieImage)
                          }
                        }
                      })
                    : _vm._e(),
                  movieImage.image && _vm.movieImages.length > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.removeMovieImage(movieImage.id)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "Movie.form.edit.movieImages.buttons.removeImage"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]
            )
          }),
          _c("div", { staticClass: "col-xs-12 col-t-md-12" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "button" },
                on: { click: _vm.addMovieImage }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("Movie.form.edit.movieImages.buttons.addImage")
                    ) +
                    " "
                )
              ]
            )
          ])
        ],
        2
      ),
      _c("p", { staticClass: "tip text-small" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("Movie.form.labels.imageUpload.filmStills")) + " "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }