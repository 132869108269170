import { Link } from "@/models/Link.model";

export default {
  namespaced: true,
  state: {
    linksList: []
  },
  mutations: {
    updateLinksList(state, data) {
      state.linksList = data;
    }
  },
  actions: {
    setLinksList({ commit }, payload) {
      const linkList = payload.map(link => {
        return new Link(link);
      });
      commit("updateLinksList", linkList);
    }
  }
};
