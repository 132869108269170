<template>
  <div class="logout"></div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Logout",
  computed: {
    ...mapState("AuthStore", ["token", "loginCompleted"])
  },
  methods: {
    ...mapActions("UserStore", ["destroyUserStateAndStorage"]),
    ...mapActions("AuthStore", ["destroyTokenStateAndStorage"]),
    ...mapActions("MemberProfileStore", ["destroyMemberProfile"])
  },

  /**
   * Triggered when clicked on logout,
   * then sends user to homepage.
   */
  created() {
    this.destroyUserStateAndStorage().then(() => {
      this.destroyTokenStateAndStorage();
      this.destroyMemberProfile();
      this.$router.push({ name: "MoviesListView" });
    });
  }
};
</script>
